import React from 'react'

import { useAlergoide } from './hooks/useAlergoide';

import { AlergoideUpdate } from './components/AlergoideUpdate';
import { Error, Loader } from '../../../../components';

export const Alergoide = () => {

    const { alergoideQuery: { isLoading, isError, data: alergoide } } = useAlergoide();

    return (
        <>
            {(isLoading)
            ? <Loader />
            : <>
                {(isError)
                ? <Error />
                : <>
                    <AlergoideUpdate alergoideAux={alergoide} />
                </>
                }
            </>
            }
        </>
    )
}
