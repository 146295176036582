import React from 'react'
import PropTypes from 'prop-types';

import styles from './../DetalleCuestionario.module.css';

export const CuestionarioRinitis = ({ aplicacion }) => {

  let r1 = '';
  let r2 = '';
  let r3 = '';
  let r4 = '';
  let r5 = '';
  let r6 = '';

  switch (aplicacion.pregunta1) {
    case 5:
      r1 = 'Nunca';
      break;
    case 4:
      r1 = 'Rara vez';
      break;
    case 3:
      r1 = 'A veces';
      break;
    case 2:
      r1 = 'A menudo';
      break;
    case 1:
      r1 = 'Muy a menudo';
      break;
  
    default:
      break;
  }

  switch (aplicacion.pregunta2) {
    case 5:
      r2 = 'Nunca';
      break;
    case 4:
      r2 = 'Rara vez';
      break;
    case 3:
      r2 = 'A veces';
      break;
    case 2:
      r2 = 'A menudo';
      break;
    case 1:
      r2 = 'Muy a menudo';
      break;
  
    default:
      break;
  }

  switch (aplicacion.pregunta3) {
    case 5:
      r3 = 'Nunca';
      break;
    case 4:
      r3 = 'Rara vez';
      break;
    case 3:
      r3 = 'A veces';
      break;
    case 2:
      r3 = 'A menudo';
      break;
    case 1:
      r3 = 'Muy a menudo';
      break;
  
    default:
      break;
  }

  switch (aplicacion.pregunta4) {
    case 5:
      r4 = 'En absoluto';
      break;
    case 4:
      r4 = 'Un poco';
      break;
    case 3:
      r4 = 'Algo';
      break;
    case 2:
      r4 = 'Mucho';
      break;
    case 1:
      r4 = 'Constantemente';
      break;
  
    default:
      break;
  }

  switch (aplicacion.pregunta5) {
    case 5:
      r5 = 'Nunca';
      break;
    case 4:
      r5 = 'Rara vez';
      break;
    case 3:
      r5 = 'A veces';
      break;
    case 2:
      r5 = 'A menudo';
      break;
    case 1:
      r5 = 'Muy a menudo';
      break;
  
    default:
      break;
  }

  switch (aplicacion.pregunta6) {
    case 5:
      r6 = 'Completamente';
      break;
    case 4:
      r6 = 'Mucho';
      break;
    case 3:
      r6 = 'Algo';
      break;
    case 2:
      r6 = 'Poco';
      break;
    case 1:
      r6 = 'En absoluto';
      break;
  
    default:
      break;
  }

  return (
    <>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>1. Durante la <b>última semana</b>, ¿con qué frecuencia ha tenido <b>congestión nasal</b>?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r1 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>2. Durante la <b>última semana</b>, ¿con que frecuencia ha <b>estornudado</b>?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r2 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>3. Durante la <b>última semana</b>, ¿con qué frecuencia ha tenido los <b>ojos llorosos</b>?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r3 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>4. Durante la <b>última semana</b>, ¿hasta qué punto sus síntomas nasales o los otros síntomas de alergia han <b>interrumpido su sueño</b>?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r4 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>5. Durante la <b>última semana</b>, ¿con qué frecuencia ha <b>evitado alguna actividad</b> (por ejemplo, ir de visita a una casa donde tienen perros o gatos, cuidar el jardín) a causa de sus síntomas nasales o de los otros síntomas de alergia?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r5 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>6. Durante la <b>última semana</b>, ¿hasta qué punto han estado <b>controlados sus síntomas</b> nasales o los otros síntomas de alergia?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r6 }</b></div>
        </div>
      </div>
    </>
  )
}

CuestionarioRinitis.propTypes = {
  aplicacion: PropTypes.object.isRequired,
}