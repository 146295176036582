import { useState } from 'react';

export const useForm = (initialForm = {}) => {

    const [formState, setFormState] = useState(initialForm);

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        setFormState({
            ...formState,
            [name]: value
        });
    }

    const onMultipleInputChange = (fields) => {
        let newFormState = {...formState };
        fields.forEach(field => {
            let { name, value } = field.target
            newFormState = {...newFormState, [name]: value }
        });

        setFormState(newFormState);
    }

    const onResetForm = () => {
        setFormState(initialForm);
    }

    return {
        ...formState,
        formState,
        onInputChange,
        onMultipleInputChange,
        onResetForm,
    }
}