import React from 'react'
import PropTypes from 'prop-types';

import styles from './../DetalleCuestionario.module.css';

export const CuestionarioUrticaria = ({ aplicacion }) => {

  let roncha = '';
  let comezon = '';

  switch (aplicacion.roncha) {
    case 0:
      roncha = 'Ninguna';
      break;
    case 1:
      roncha = 'Leve (20 ronchas o menos)';
      break;
    case 2:
      roncha = 'Moderado (20 a 50 ronchas)';
      break;
    case 3:
      roncha = 'Grave (50 ronchas o más)';
      break;
  
    default:
      break;
  }

  switch (aplicacion.comezon) {
    case 0:
      comezon = 'Nada';
      break;
    case 1:
      comezon = 'Leve';
      break;
    case 2:
      comezon = 'Moderada';
      break;
    case 3:
      comezon = 'Intensa';
      break;
  
    default:
      break;
  }

  return (
    <>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>1. ¿Cuántas <b>ronchas</b> ha presentado en las últimas 24 horas?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ roncha }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>2. ¿Qué tan molesta ha sido la <b>comezón</b>, en las últimas 24 horas?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ comezon }</b></div>
        </div>
      </div>
    </>
  )
}

CuestionarioUrticaria.propTypes = {
  aplicacion: PropTypes.object.isRequired,
}