import { Outlet } from "react-router-dom";

import styles from './AuthLayout.module.css';

export const SignupLayout = () => {

    const css = `
        .notification-layout-margin{
            top: 10px !important;
        }
    `;

    return (
        <>
            <style>{ css }</style>
            <div className={"container-fluid " + styles.authContainer}>
                <div className="row">
                    <div className="col">
                        <div className={styles.formContainer}>
                            <div>
                                <div className="centrar">
                                    <div className={styles.formLogo}></div>
                                </div>
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
