import { useQuery } from "@tanstack/react-query"

import { getDatosGenerales } from "../../../services/datosgenerales.service";

export const useDatosGenerales = () => {

    const datosGeneralesQuery = useQuery({
        queryKey: ['configuracionExpedienteClinico/datosGenerales'],
        queryFn: getDatosGenerales,
        staleTime: 0, // nulo
    });

    return {
        datosGeneralesQuery,
    }
}