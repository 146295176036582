import { useQuery } from "@tanstack/react-query"

import { getMotivo } from "../services/motivo.service";

export const useMotivo = () => {

    const motivoQuery = useQuery({
        queryKey: ['motivo'],
        queryFn: () => getMotivo(),
        staleTime: 1000 * 60 * 5, // 5 minutos
    });

    return {
        motivoQuery,
    }
}