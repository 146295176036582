import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { PrimaryButton } from '../../../../components';

import styles from './../GraficaCuestionario.module.css';
import { colors } from '../../../../types/types';

export const GraficaPoem = ({ data, prevPage, nextPage }) => {

    const dataAux = [
        {
            name: '',
            Grado: null,
        },
        ...(typeof data.data !== 'undefined') ? Object.keys(data.data).map(key => ({ name: `Semana ${key}`, Grado: data.data[key] })) : [],
        {
            name: '',
            Grado: null,
        },
    ];

    return (
        <>
        <div className="row">
            <div className="col">
            <div className={styles.header}>{data.mes.charAt(0).toUpperCase() + data.mes.slice(1)} {data.año}</div>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <div className='d-flex justify-content-center'>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={dataAux} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Grado" stroke={colors.CHART_COLOR_1} strokeWidth={3} dot={{ stroke: colors.CHART_COLOR_1, strokeWidth: 6 }} animationDuration={500} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-6">
                <div className='d-flex flex-row-reverse'>
                    <PrimaryButton
                        disabled={!data.prev}
                        icon='ArrowLeftShort'
                        inverse={true}
                        onClick={prevPage}
                        type='button'
                    />
                </div>
            </div>
            <div className="col-6">
                <div>
                    <PrimaryButton
                        disabled={!data.next}
                        icon='ArrowRightShort'
                        inverse={true}
                        onClick={nextPage}
                        type='button'
                    />
                </div>
            </div>
        </div>
        </>
    )
}

GraficaPoem.propTypes = {
    data: PropTypes.object.isRequired,
    prevPage: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
}