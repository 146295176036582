import { useState } from 'react';
import PropTypes from 'prop-types';

import { DatosGeneralesPreview } from './DatosGeneralesPreview';
import { DatosGeneralesUpdate } from './DatosGeneralesUpdate';

import styles from '../Perfil.module.css';

export const DatosGenerales = ({ usuario }) => {

    const [datosGeneralesUpdateStatus, setDatosGeneralesUpdateStatus] = useState(false);

    const handleEditarDatosGenerales = () => {
        setDatosGeneralesUpdateStatus(true);
    }

    const handleCancelarEditarDatosGenerales = () => {
        setDatosGeneralesUpdateStatus(false);
    }

    return (
        <>
            <div className={styles.subHeader}>Datos Generales</div>
            {(!datosGeneralesUpdateStatus)
            ? <DatosGeneralesPreview usuario={usuario} handleEditarDatosGenerales={handleEditarDatosGenerales} />
            : <DatosGeneralesUpdate usuario={usuario} handleCancelarEditarDatosGenerales={handleCancelarEditarDatosGenerales} />
            }
        </>
    )
}

DatosGenerales.propTypes = {
    usuario: PropTypes.object.isRequired,
}