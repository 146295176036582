import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';

import { setPacienteSearch } from '../../../../../../redux/slices/ui';
import { usePacientes } from '../../hooks/usePacientes';

import { Error, Loader } from '../../../../../../components';

import { colors } from '../../../../../../types/types';

import { tableCss } from '../../../../../../styles/table';
import styles from './NavbarSearch.module.css';

export const NavbarSearch = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const searchInput = useRef(null);

    const [searchTerm, setSearchTerm] = useState('');

    const [searchValue, setSearchValue] = useState('');

    const [focus, setFocus] = useState(false);

    const [resultsIndex, setResultsIndex] = useState(0);

    const { pacienteSearch } = useSelector(state => state.ui);

    const { pacientesQuery: { isLoading, isFetching , isError, data: { pacientes } } } = usePacientes();

    const handleOnChange = (e) => {
        setSearchValue(e.target.value);
        setSearchTerm(e.target.value);
    }

    const handleOnBlur = () => {
        setTimeout(() => {
            setFocus(false);
            setResultsIndex(0);
        }, 150);
    } 

    const handleViewPaciente = (id) => {
        navigate(`/paciente/view/${id}`);
    }

    const handleKeys = (e) => {
        switch (e.key) {
            case 'Escape':
                setFocus(false);
                searchInput.current.blur();
                break;
            case 'ArrowUp':
                e.preventDefault();
                if(resultsIndex > 0){
                    setResultsIndex(resultsIndex - 1);
                }else{
                    setResultsIndex(pacientes.length - 1);
                }
                break;
            case 'ArrowDown':
                e.preventDefault();
                if(resultsIndex === pacientes.length - 1){
                    setResultsIndex(0);
                }else{
                    setResultsIndex(resultsIndex + 1);
                }
                break;
            case 'Enter':
                if(pacientes.length > 0){
                    searchInput.current.blur();
                    navigate(`/paciente/view/${pacientes[resultsIndex]._id}`);
                }
                break;
        
            default:
                break;
        }
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            dispatch(setPacienteSearch(searchTerm));
            setResultsIndex(0);
        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    return (
        <>
            <style>{ tableCss }</style>
            <div className={styles.FeedbackIconContainer}>
                <input type="text" value={searchValue} className={"form-control " + styles.searchInput} placeholder='Pacientes' onChange={handleOnChange} onFocus={() => setFocus(true)} onBlur={handleOnBlur} onKeyDown={handleKeys} ref={searchInput} />
                <Icon.Search className={styles.FeedbackIcon} color={ colors.INMPUT_FEEDBACK } />
                {(focus) &&
                <div className={styles.resultsContainerPadding}>
                    <div className={styles.resultsContainer}>
                        {(isLoading || isFetching)
                        ? <Loader />
                        : <>
                            {(isError)
                            ? <Error />
                            : <>
                                <div className="table-responsive">
                                    <table className="table align-middle mb-0">
                                        <tbody>
                                            {(pacienteSearch !== '' && pacienteSearch.length >= 3)
                                            ? pacientes.map((paciente, index) => <tr key={paciente._id} onClick={() => handleViewPaciente(paciente._id)} className={(resultsIndex === index) ? styles.activeItem : "" } onMouseOver={() => setResultsIndex(index)}>
                                                <td className='tableText'>{ paciente.nombre }</td>
                                                <td className='tableText'>{ paciente.edad }</td>
                                                <td className='tableText'>-</td>
                                                <td><div className='d-flex justify-content-center'><span className="badge" style={{backgroundColor: paciente.appStatusColor}}>{ paciente.appStatusString }</span></div></td>
                                            </tr>
                                            )
                                            : <tr><td className='text-center'>Escribe al menos 3 caracteres</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            }
                        </>
                        }
                    </div>
                </div>
                }
            </div>
        </>
    )
}
