import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { PruebaAlergiaNuevo } from './PruebaAlergiaNuevo';
import { PruebaAlergiaPrevia } from './PruebaAlergiaPrevia';

import styles from '../PacienteConsulta.module.css';

export const PruebaAlergia = () => {

    let { id } = useParams();

    const [tab, setTab] = useState(1);

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className={styles.tabsSubHeader}>Pruebas de alergia</div>
                    <div  className={styles.tabsContainerInner + " mt-3"}>
                        <div className={"row"}>
                            <div className="col p-0 text-center">
                                <div className={ (tab === 1) ? styles.tabActiveInner : styles.tabInner } onClick={() => setTab(1)}>Nueva</div>
                            </div>
                            <div className="col p-0 text-center">
                                <div className={ (tab === 2) ? styles.tabActiveInner : styles.tabInner } onClick={() => setTab(2)}>Previas</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.tabsContentContainerInner}>
                        { (tab === 1) && <PruebaAlergiaNuevo id={id} /> }
                        { (tab === 2) && <PruebaAlergiaPrevia id={id} /> }
                    </div>
                </div>
            </div>
        </>
    )
}
