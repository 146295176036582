import { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import moment from 'moment'

import { LightButton, SuccessButton } from "../../../../../components";

import styles from './NuevoEditarPacienteSider.module.css';

export const PacienteNuevoEditarSider = () => {

    const location = useLocation();

    const navigate = useNavigate();

    const [submit, setSubmit] = useState(moment().unix());

    const [onSubmit, setOnSubmit] = useState(false);

    const [appActivaContext, setAppActivaContext] = useState(false);

    const handleCanelarNuevoPaciente = () => {

        navigate(-1);

    }

    return (
        <>
            <div className="row flex-grow-1">
                <div className={"col " + styles.contentColumn}><Outlet context={[submit, setOnSubmit, appActivaContext, setAppActivaContext]} /></div>
                <div className={"col-sm-3 col-md-2 " + styles.sideContainer + ""}>
                    <div className={styles.sideContainerDisplay}>
                        <div className="w-100">
                            <div className="form-check form-switch">
                                <input className={"form-check-input " + styles.switch} type="checkbox" role="switch" onChange={() => setAppActivaContext(!appActivaContext)} checked={appActivaContext} />
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Enlazar App</label>
                            </div>
                        </div>
                        <div className="w-100">
                            <LightButton
                                block={true}
                                disabled={onSubmit}
                                icon="X"
                                marginTop={false}
                                onClick={handleCanelarNuevoPaciente}
                                text="Cancelar"
                                type="button"
                            />
                            <SuccessButton
                                block={true}
                                disabled={onSubmit}
                                icon="Check"
                                marginTop={true}
                                onClick={() => setSubmit(moment().unix())}
                                text={(location.pathname.includes('/nuevo') ) ? "Crear Paciente" : "Editar Paciente"}
                                type="button"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
