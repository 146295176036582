import React, { useState } from 'react'
import { useLoaderData } from 'react-router-dom';

import { usePacienteAntecedentes } from './hooks/usePacienteAntecedentes';
import { useDatosGenerales } from './hooks/useDatosGenerales';

import { PacienteAntecedentesPreview } from './components/PacienteAntecedentesPreview';
import { PacienteAntecedentesUpdate } from './components/PacienteAntecedentesUpdate';
import { Error, Loader } from '../../components';

export async function loader({ params }) {
  return params.id;
}

export const PacienteAntecedentes = () => {

  const id = useLoaderData();

  const { pacienteAntecedentesQuery: { isLoading: isLoadingPacienteAntecedentes, isError: isErrorPacienteAntecedentes, data: pacienteAntecedentes } } = usePacienteAntecedentes(id);

  const { datosGeneralesQuery: { isLoading: isLoadingConfiguracionDatosGenerales, isError: isErrorConfiguracionDatosGenerales, data: configuracionDatosGenerales } } = useDatosGenerales();

  const [antecedentesUpdateStatus, setAntecedentesUpdateStatus] = useState(false);

  return (
    <>
      {(isLoadingPacienteAntecedentes || isLoadingConfiguracionDatosGenerales)
        ? <Loader />
        : <>
            {(isErrorPacienteAntecedentes || isErrorConfiguracionDatosGenerales)
            ? <Error />
            : <>
              {(antecedentesUpdateStatus)
              ? <PacienteAntecedentesUpdate configuracionDatosGenerales={configuracionDatosGenerales} paciente={id} pacienteAntecedentes={pacienteAntecedentes} handleCancelarEditarPacienteAntecedentes={() => setAntecedentesUpdateStatus(false)} />
              : <PacienteAntecedentesPreview configuracionDatosGenerales={configuracionDatosGenerales} pacienteAntecedentes={pacienteAntecedentes} handleEditarPacienteAntecedentes={() => setAntecedentesUpdateStatus(true)} />
              }
            </>
          }
        </>
      }
    </>
  )
}