import { useQuery } from "@tanstack/react-query"

import { getPacientePruebaAlergia } from "../services/pruebaalergia.service";

export const usePacientePruebaAlergia = (id) => {

    const pacientePruebaAlergiaQuery = useQuery({
        queryKey: ['paciente/pruebaAlergia', id],
        queryFn: () => getPacientePruebaAlergia(id),
        staleTime: 1000 * 60 * 60, // 1 hora
    });

    return {
        pacientePruebaAlergiaQuery,
    }
}