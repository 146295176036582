import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const CheckboxInput = ({ disabled = false, label, name, onChange, required = false, checked }) => {

  const handleInputChange = (e) => {
    if(!disabled){
      e.target.value = (e.target.value === 'true') ? false : true;
      onChange(e);
    }
  }

  return (
    <>
        <div className="form-check">
            <input className={"form-check-input " + styles.customCheckbox} type="checkbox" name={ name } value={ (checked === true) ? 'true' : 'false' } checked={ (checked === true) ? true : false } onChange={ handleInputChange } required={required} disabled={disabled} />
            <label className={"form-check-label " + styles.customCheckboxText} htmlFor={ name }>{ label }</label>
        </div>
    </>
  )
}

CheckboxInput.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  checked: PropTypes.bool,
}