import React from 'react'
import PropTypes from 'prop-types';

import styles from './LimiteFilter.module.css';

export const LimiteFilter = ({ name, onChange, value }) => {

    const handleOnChange = (e) => {
        onChange(e.target.value);
    }

    return (
        <div className="d-flex justify-content-end align-items-center">
            <span className={styles.label}>{ name } por página:</span>
            <select className={"form-select " + styles.input} onChange={handleOnChange} defaultValue={value}>
                <option value="8">8</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
            </select>
        </div>
    )
}

LimiteFilter.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
}