import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { getConsultorios, createConsultorio, updateConsultorio, updateConfiguracionConsultorioCrearInactivo, updateConfiguracionConsultorioEliminarInactivo } from "../services/consultorios.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const useConsultorios = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const consultoriosQuery = useQuery({
        queryKey: ['consultorios'],
        queryFn: getConsultorios,
        staleTime: 0, // nulo
    });

    const createConsultorioMutation = useMutation({
        mutationFn: createConsultorio,
        onSuccess: (consultorio, variables, context) => {
            queryClient.setQueryData(['consultorios'], oldData => {
                return [
                    ...oldData,
                    consultorio
                ];
            });
            if(consultorio.principal){
                queryClient.setQueryData(['consultorios'], oldData => {

                    let newData = oldData.map(consultorioAux => {
                        if(consultorioAux._id === consultorio._id){
                            return consultorioAux;
                        }else{
                            return {
                                ...consultorioAux,
                                principal: false
                            };
                        }
                    });
    
                    return newData;
                });
            }
            variables.callback();
            dispatch(successNotification('Consultorio creado'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    const updateConsultorioMutation = useMutation({
        mutationFn: updateConsultorio,
        onSuccess: ({ consultorio, principalPrevio, principalNuevo, configuracion }, variables, context) => {
            queryClient.setQueryData(['consultorios'], oldData => {

                let newData = oldData.map(consultorioAux => {
                    if(consultorioAux._id === consultorio._id){
                        return consultorio;
                    }else{
                        return consultorioAux;
                    }
                });

                return newData;
            });
            if(principalPrevio !== null){
                queryClient.setQueryData(['consultorios'], oldData => {

                    let newData = oldData.map(consultorioAux => {
                        if(consultorioAux._id === principalPrevio){
                            return {
                                ...consultorioAux,
                                principal: false
                            };
                        }else{
                            return consultorioAux;
                        }
                    });
    
                    return newData;
                });
            }
            if(principalNuevo !== null){
                queryClient.setQueryData(['consultorios'], oldData => {

                    let newData = oldData.map(consultorioAux => {
                        if(consultorioAux._id === principalNuevo){
                            return {
                                ...consultorioAux,
                                principal: true
                            };
                        }else{
                            return consultorioAux;
                        }
                    });
    
                    return newData;
                });
            }
            queryClient.setQueryData(['configuracionConsultorio', variables.id], configuracion);
            variables.callback();
            dispatch(successNotification('Consultorio actualizado'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    const updateConfiguracionConsultorioCrearInactivoMutation = useMutation({
        mutationFn: updateConfiguracionConsultorioCrearInactivo,
        onSuccess: (configuracion, variables, context) => {
            queryClient.setQueryData(['configuracionConsultorio', variables.id], configuracion);
            variables.callback();
            dispatch(successNotification('Día inactivo creado'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    const updateConfiguracionConsultorioEliminarInactivoMutation = useMutation({
        mutationFn: updateConfiguracionConsultorioEliminarInactivo,
        onSuccess: (configuracion, variables, context) => {
            queryClient.setQueryData(['configuracionConsultorio', variables.id], configuracion);
            dispatch(successNotification('Día inactivo eliminado'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        consultoriosQuery,
        createConsultorioMutation,
        updateConsultorioMutation,
        updateConfiguracionConsultorioCrearInactivoMutation,
        updateConfiguracionConsultorioEliminarInactivoMutation
    }
}