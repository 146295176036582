import React, { useState } from 'react'
import PropTypes from 'prop-types';

import { useSublingual } from '../hooks/useSublingual';
import { useForm, useFormValidation } from '../../../../../hooks';

import { CheckboxInput, LightButton, PrimaryButton } from "../../../../../components";

import styles from './../Sublingual.module.css';

export const SublingualUpdate = ({ sublingualAux }) => {

    const { updateSublingualMutation: { mutate: updateSublingual, isPending } } = useSublingual();

    const [dataStatus, setDataStatus] = useState(false);

    const { sublingual, onInputChange, onResetForm } = useForm({ sublingual: sublingualAux.sublingual });

    const { isFormValid, formValidationStatus, startFormValidation } = useFormValidation([{field: 'sublingual', default: true}]);

    const handleChange = (e) => {
        setDataStatus(true);
        onInputChange(e);
    }

    const handleCancelChange = () => {
        onResetForm();
        setDataStatus(false);
    }

    const onSubmit = async ( event ) => {
        event.preventDefault();
        
        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            updateSublingual({ data: { sublingual } });
            setDataStatus(false);
        }else{
          startFormValidation();
        }
    
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className={styles.panel}>
                    <div className={styles.subHeader}>Inmunoterapias</div>
                    <div className="row">
                        <div className="col">
                            <CheckboxInput
                                label="Sublingual"
                                name="sublingual"
                                onChange={ handleChange }
                                required={false}
                                checked={ (sublingual === 'true' || sublingual === true) ? true : false }
                            />
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12 col-md">
                        <div className='d-flex justify-content-end'>
                            <LightButton
                                disabled={isPending || formValidationStatus === 'checking' || !dataStatus}
                                icon="X"
                                marginTop={false}
                                marginRight={true}
                                onClick={handleCancelChange}
                                text="Cancelar"
                                type="button"
                            />
                            <PrimaryButton
                                disabled={isPending || formValidationStatus === 'checking' || !dataStatus}
                                icon="Check"
                                marginTop={false}
                                text="Guardar"
                                type="submit"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

SublingualUpdate.propTypes = {
    sublingualAux: PropTypes.object.isRequired,
}