import React from 'react'
import PropTypes from 'prop-types';

import styles from '../PacienteAntecedentes.module.css';
import { useForm, useFormValidation } from '../../../hooks';
import { LightButton, MultiFormAntecedentes, PrimaryButton } from '../../../components';
import { usePacienteAntecedentesUpdate } from '../hooks/usePacienteAntecedentesUpdate';

export const PacienteAntecedentesUpdate = ({ configuracionDatosGenerales, handleCancelarEditarPacienteAntecedentes, paciente, pacienteAntecedentes }) => {

    const { updatePacienteAntecedentesMutation: { mutate: updatePacienteAntecedentes, isPending } } = usePacienteAntecedentesUpdate(paciente);

    const { heredofamiliares, personalesPatologicos, personalesNoPatologicos, ginobstetricos, perinatales, onInputChange } = useForm({ heredofamiliares: (typeof pacienteAntecedentes.heredofamiliares !== 'undefined') ? pacienteAntecedentes.heredofamiliares : [], personalesPatologicos: (typeof pacienteAntecedentes.personalesPatologicos !== 'undefined') ? pacienteAntecedentes.personalesPatologicos : [], personalesNoPatologicos: (typeof pacienteAntecedentes.personalesNoPatologicos !== 'undefined') ? pacienteAntecedentes.personalesNoPatologicos : [], ginobstetricos: (typeof pacienteAntecedentes.ginobstetricos !== 'undefined') ? pacienteAntecedentes.ginobstetricos : [], perinatales: (typeof pacienteAntecedentes.perinatales !== 'undefined') ? pacienteAntecedentes.perinatales : [] });

    const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'heredofamiliares', default: true}, {field: 'personalesPatologicos', default: true}, {field: 'personalesNoPatologicos', default: true}, {field: 'ginobstetricos', default: true}, {field: 'perinatales', default: true}]);

    const onSubmit = (e) => {
        e.preventDefault();

        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            updatePacienteAntecedentes({ data: { heredofamiliares: heredofamiliares.map(heredofamiliar => heredofamiliar.data), personalesPatologicos: personalesPatologicos.map(personalPatologico => personalPatologico.data), personalesNoPatologicos: personalesNoPatologicos.map(personalNoPatologico => personalNoPatologico.data), ginobstetricos: ginobstetricos.map(ginobstetrico => ginobstetrico.data), perinatales: perinatales.map(perinatal => perinatal.data) }, id: paciente, onSuccess: handleCancelarEditarPacienteAntecedentes });
        }else{
            startFormValidation();
        }

    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="row mt-3">
                    <div className="col-sm-12 col-md">
                        <div className='d-flex justify-content-end'>
                            <LightButton
                                disabled={isPending || formValidationStatus === 'checking'}
                                icon="X"
                                marginTop={false}
                                marginRight={true}
                                onClick={handleCancelarEditarPacienteAntecedentes}
                                text="Cancelar"
                                type="button"
                            />
                            <PrimaryButton
                                disabled={isPending || formValidationStatus === 'checking'}
                                icon="Check"
                                marginTop={false}
                                text="Guardar"
                                type="submit"
                            />
                        </div>
                    </div>
                </div>
                {(configuracionDatosGenerales.antecedentes.heredofamiliares) &&
                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className={styles.subHeader}>Heredofamiliares</div>
                    </div>
                    <div className="col-md-12">
                        <MultiFormAntecedentes
                            formsStatus={ formFields['heredofamiliares'].status }
                            name="heredofamiliares"
                            onFormsChange={ onInputChange }
                            setFormValidation={ setFormFieldValidation }
                            value={heredofamiliares}
                        />
                    </div>
                </div>
                }
                {(configuracionDatosGenerales.antecedentes.personalesPatologicos) &&
                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className={styles.subHeader}>Personales patológicos</div>
                    </div>
                    <div className="col-md-12">
                        <MultiFormAntecedentes
                            formsStatus={ formFields['personalesPatologicos'].status }
                            name="personalesPatologicos"
                            onFormsChange={ onInputChange }
                            setFormValidation={ setFormFieldValidation }
                            value={personalesPatologicos}
                        />
                    </div>
                </div>
                }
                {(configuracionDatosGenerales.antecedentes.personalesNoPatologicos) &&
                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className={styles.subHeader}>Personales no patológicos</div>
                    </div>
                    <div className="col-md-12">
                        <MultiFormAntecedentes
                            formsStatus={ formFields['personalesNoPatologicos'].status }
                            name="personalesNoPatologicos"
                            onFormsChange={ onInputChange }
                            setFormValidation={ setFormFieldValidation }
                            value={personalesNoPatologicos}
                        />
                    </div>
                </div>
                }
                {(configuracionDatosGenerales.antecedentes.ginobstetricos) &&
                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className={styles.subHeader}>Ginobstétricos</div>
                    </div>
                    <div className="col-md-12">
                        <MultiFormAntecedentes
                            formsStatus={ formFields['ginobstetricos'].status }
                            name="ginobstetricos"
                            onFormsChange={ onInputChange }
                            setFormValidation={ setFormFieldValidation }
                            value={ginobstetricos}
                        />
                    </div>
                </div>
                }
                {(configuracionDatosGenerales.antecedentes.perinatales) &&
                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className={styles.subHeader}>Perinatales</div>
                    </div>
                    <div className="col-md-12">
                        <MultiFormAntecedentes
                            formsStatus={ formFields['perinatales'].status }
                            name="perinatales"
                            onFormsChange={ onInputChange }
                            setFormValidation={ setFormFieldValidation }
                            value={perinatales}
                        />
                    </div>
                </div>
                }
            </form>
        </>
    )
}

PacienteAntecedentesUpdate.propTypes = {
    configuracionDatosGenerales: PropTypes.object.isRequired,
    handleCancelarEditarPacienteAntecedentes: PropTypes.func.isRequired,
    paciente: PropTypes.string.isRequired,
    pacienteAntecedentes: PropTypes.any.isRequired,
}