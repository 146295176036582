import { useQuery } from "@tanstack/react-query"

import { getPacienteCuestionariosPasados } from "../services/pacientecuestionarios.service";

export const usePacienteCuestionariosPasados = (id) => {

    const pacienteCuestionariosPasadosQuery = useQuery({
        queryKey: ['paciente/cuestionariosPasados', id],
        queryFn: () => getPacienteCuestionariosPasados(id),
        staleTime: 1000 * 60 * 5, // 5 min
    });

    return {
        pacienteCuestionariosPasadosQuery,
    }
}