import React from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const BadgePreview = ({ backgroundColor, label, marginBottom = true, name, value}) => {
  
  return (
    <>
      <label htmlFor={ name } className={styles.customPreviewLabel}>{ label }</label>
      <div className={(marginBottom) ? styles.customPreview : styles.customPreviewNoMargin}><span className="badge" style={{backgroundColor: backgroundColor}}>{ value }</span></div>
    </>
  )
}

BadgePreview.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  label: PropTypes.string,
  marginBottom: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}