import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { getSubcutanea, updateSubcutanea } from "../services/subcutanea.service";

import { successNotification, dangerNotification } from '../../../../../redux/slices/ui';

export const useSubcutanea = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const subcutaneaQuery = useQuery({
        queryKey: ['configuracionExpedienteClinico/subcutanea'],
        queryFn: getSubcutanea,
        staleTime: 1000 * 60 * 60, // 1 hora
    });

    const updateSubcutaneaMutation = useMutation({
        mutationFn: updateSubcutanea,
        onSuccess: (subcutanea, variables, context) => {
            queryClient.setQueryData(['configuracionExpedienteClinico/subcutanea'], oldData => {

                return subcutanea;
                
            });
            dispatch(successNotification('Datos de inmunoterapia subcutánea actualizados'));
        },
        onError: (error, variables, context) => {
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        subcutaneaQuery,
        updateSubcutaneaMutation,
    }
}