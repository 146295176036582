import { useQuery } from "@tanstack/react-query"

import { getPacientes } from "../services/pacientes.service";

export const usePacientes = ({ searchString: search, searchLimite: limite, searchPag: pag }) => {

    const pacientesQuery = useQuery({
        queryKey: ['pacientes', { pag, limite, search }],
        queryFn: () => getPacientes(pag, limite, search),
        staleTime: 1000 * 60, // 1 min
        placeholderData: {
            desde: 0,
            hasta: 0,
            limite_por_pagina: 5,
            pacientes: [],
            pagina: 0,
            total: 0,
            total_paginas: 0
        }
    });

    return {
        pacientesQuery,
    }
}