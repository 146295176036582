import { useEffect } from "react";
import PropTypes from 'prop-types';

import { useForm, useFormValidation } from "../../../../hooks";

import { IconButton, TextInput } from "../../.."

import { colors } from "../../../../types/types";

import styles from '../MultiFormAntecedentes.module.css';

export const AntecedenteForm = ({ element, formStatus='checked', onFormChange, removeElement }) => {

  const { data, onInputChange } = useForm({ data: element.data });

  const { isFormValid, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'data', default: (element.data !== '')}]);

  useEffect(() => {
    if(onFormChange) onFormChange({ id: element.id, data, validation: isFormValid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFormValid])

  useEffect(() => {
    if(formStatus === 'checking') startFormValidation();
  }, [formStatus, startFormValidation]);

  useEffect(() => {
    if(data !== ''){
      startFormValidation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  return (
    <div className="row">
      <div className="col-md-12 col-lg-11">
        <TextInput
          formFieldStatus={ formFields['data'].status }
          label="Descripción"
          name="data"
          onChange={ onInputChange }
          required={ true }
          requiredIndicator={ true }
          setFormFieldValidation={ setFormFieldValidation }
          value={ data }
        />
      </div>
      <div className="col-md-12 col-lg-1">
        <div className={styles.closeBtnContainer}>
          <div>
            <label htmlFor="">&nbsp;</label>
            <IconButton
              icon="X"
              iconColor={colors.DANGER}
              marginTop={false}
              onClick={() => removeElement(element.id)}
              type="button"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

AntecedenteForm.propTypes = {
  element: PropTypes.any.isRequired,
  formStatus: PropTypes.oneOf(['checked', 'checking']),
  onFormChange: PropTypes.func,
  removeElement: PropTypes.func.isRequired,
}