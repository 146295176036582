import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useForm, useFormValidation } from '../../../hooks';
import { usePacienteIndependizar } from './hooks/usePacienteIndependizar';

import { dangerNotification } from "../../../redux/slices/ui";

import { EmailInput, PrimaryButton, LightButton, TextInputPreview } from '../../../components';

import styles from '../Modals.module.css';

export const IndependizarPacienteModal = ({ anterior, asociado, hide, principal }) => {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { independizarPacienteMutation: { mutate: independizarPaciente } } = usePacienteIndependizar();

  const { email, onInputChange } = useForm({ email: '' });

  const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'email', default: false}]);

  const handleViewPaciente = () => {
    hide();
    navigate(`/paciente/view/${asociado._id}`);
  }

  const onSubmit = async ( event ) => {
      event.preventDefault();

      if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
          if(typeof asociado._id !== 'undefined' && asociado._id !== ""){
              independizarPaciente({ data: { email, id: asociado._id }, asociadoAnterior: anterior, onSuccess: handleViewPaciente });
          }else{
              dispatch(dangerNotification('Paciente a independizar no identificado, intenta nuevamente'));
          }
      }else{
        startFormValidation();
      }

  }

  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.modalHeader}>Independizar Paciente</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          ¿Estas seguro que quieres independizar al paciente?
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className={styles.modalThirdHeader}>Usuario principal por reemplazar</div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TextInputPreview
            label="Nombre"
            name="nombre"
            value={ principal.nombre }
          />
        </div>
        <div className="col">
          <TextInputPreview
            label="Correo electrónico"
            name="email"
            value={ principal.email }
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className={styles.modalThirdHeader}>Nuevo usuario principal</div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TextInputPreview
            label="Nombre"
            name="nombre"
            value={ (typeof asociado.nombre !== 'undefined') ? asociado.nombre : '' }
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <form onSubmit={ onSubmit }>
            <div className="row">
              <div className="col">
                <EmailInput
                    formFieldStatus={ formFields['email'].status }
                    label="Correo electrónico"
                    name="email"
                    onChange={ onInputChange }
                    required={ true }
                    requiredIndicator={ true }
                    setFormFieldValidation={ setFormFieldValidation }
                    value={ email }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <LightButton
                  block={true}
                  icon="X"
                  marginTop={false}
                  onClick={ hide }
                  text="Cancelar"
                  type="button"
                />
              </div>
              <div className='col-xs-12 col-sm-12 col-md-6'>
                <PrimaryButton
                  block={true}
                  disabled={formValidationStatus === 'checking'}
                  icon="Check"
                  marginTop={false}
                  text="Independizar"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

IndependizarPacienteModal.propTypes = {
  anterior: PropTypes.string.isRequired,
  asociado: PropTypes.object.isRequired,
  hide: PropTypes.func.isRequired,
  principal: PropTypes.object.isRequired
}