import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'

import { TutorForm } from './components/Tutor';
import { PrimaryRoundedButton } from '../..'

export const MultiFormTutor = ({ formsStatus='checked', name, onFormsChange, setFormValidation, value }) => {

    const [elements, setElements] = useState((typeof value !== 'undefined') ? value.map(val => ({ id: val._id, parentesco: val.parentesco, nombre: val.nombre, email: val.email, celular: val.celular, validation: true })) : [])

    const handleAddElement = () => {
        setElements([...elements, { id: moment().unix(), parentesco: '', nombre: '', email: '', celular: '', validation: false }]);
    }

    const handleRemoveElement = (id) => {
        setElements(elements.filter(element => element.id !== id));
    }

    const handleSetElementData = (data) => {
        setElements(elements.map(element => {
            if(element.id === data.id){
                return data;
            }else{
                return element;
            }
        }));
    }

    useEffect(() => {
        onFormsChange({ target: { name: name, value: elements.map(element => ({
            parentesco: element.parentesco,
            nombre: element.nombre,
            email: element.email,
            celular: element.celular,
        })) } });
        let errorElements = elements.filter(element => (!element.validation));
        setFormValidation(name, (errorElements.length === 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements])

    return (
        <>
            {elements.map(element => <TutorForm
                key={element.id}
                element={element}
                removeElement={handleRemoveElement}
                onFormChange={handleSetElementData}
                formStatus={formsStatus}
            />)}
            <div className='centrar'>
                <PrimaryRoundedButton
                    icon="Plus"
                    onClick={handleAddElement}
                    type="button"
                />
            </div>
        </>
    )
}

MultiFormTutor.propTypes = {
    formsStatus: PropTypes.oneOf(['checked', 'checking']),
    name: PropTypes.string.isRequired,
    onFormsChange: PropTypes.func.isRequired,
    setFormValidation: PropTypes.func,
    value: PropTypes.array
}