import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { getConsulta, updateConsulta } from "../services/consulta.service";

import { successNotification, dangerNotification } from '../../../../../redux/slices/ui';

export const useConsulta = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const consultaQuery = useQuery({
        queryKey: ['configuracionExpedienteClinico/consulta'],
        queryFn: getConsulta,
        staleTime: 1000 * 60 * 60, // 1 hora
    });

    const updateConsultaMutation = useMutation({
        mutationFn: updateConsulta,
        onSuccess: (consulta, variables, context) => {
            queryClient.setQueryData(['configuracionExpedienteClinico/consulta'], oldData => {

                return consulta;
                
            });
            dispatch(successNotification('Datos de consulta actualizados'));
        },
        onError: (error, variables, context) => {
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        consultaQuery,
        updateConsultaMutation,
    }
}