import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { updatePacienteAntecedentes } from "../services/pacienteantecedentes.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteAntecedentesUpdate = (id) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const updatePacienteAntecedentesMutation = useMutation({
        mutationFn: updatePacienteAntecedentes,
        onSuccess: (antecedentes, variables, context) => {
            queryClient.setQueryData(['paciente/antecedentes', id], oldData => {

                return antecedentes;
                
            });
            dispatch(successNotification('Antecedentes actualizados'));
            variables.onSuccess();
        },
        onError: (error, variables, context) => {
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        updatePacienteAntecedentesMutation,
    }
}