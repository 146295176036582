import { useState } from "react";

import { ContraseñaPreview } from './ContraseñaPreview';
import { ContraseñaUpdate } from './ContraseñaUpdate';

import styles from '../Perfil.module.css';

export const Contraseña = () => {

    const [contraseñaUpdateStatus, setContraseñaUpdateStatus] = useState(false);

    const handleEditarContraseña = () => {
        setContraseñaUpdateStatus(true);
    }

    const handleCancelarEditarContraseña = () => {
        setContraseñaUpdateStatus(false);
    }

    return (
        <>
            <div className={styles.subHeader}>Contraseña</div>
            {(!contraseñaUpdateStatus)
            ? <ContraseñaPreview handleEditarContraseña={handleEditarContraseña} />
            : <ContraseñaUpdate handleCancelarEditarContraseña={handleCancelarEditarContraseña} />
            }
        </>
    )
}
