import { inmunetApi } from '../../../../api';

export const getPacienteDetalleCuestionario = async(idPaciente, cuestionario) => {

    try {

        const { data } = await inmunetApi.get(`cuestionario/${ cuestionario }/${ idPaciente }`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.cuestionario;

    } catch (error) {

        throw new Error(error);

    }

};