import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { getSublingual, updateSublingual } from "../services/sublingual.service";

import { successNotification, dangerNotification } from '../../../../../redux/slices/ui';

export const useSublingual = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const sublingualQuery = useQuery({
        queryKey: ['configuracionExpedienteClinico/sublingual'],
        queryFn: getSublingual,
        staleTime: 1000 * 60 * 60, // 1 hora
    });

    const updateSublingualMutation = useMutation({
        mutationFn: updateSublingual,
        onSuccess: (sublingual, variables, context) => {
            queryClient.setQueryData(['configuracionExpedienteClinico/sublingual'], oldData => {

                return sublingual;
                
            });
            dispatch(successNotification('Datos de inmunoterapia sublingual actualizados'));
        },
        onError: (error, variables, context) => {
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        sublingualQuery,
        updateSublingualMutation,
    }
}