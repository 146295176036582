import React from 'react';
import PropTypes from 'prop-types';

import { ActivoPreview, ImgCircledPreviewInput, PrimaryButton, TextInputPreview } from "../../../components";

export const DatosGeneralesPreview = ({ asistente, handleEditarDatosGenerales }) => {
  return (
    <>
        <div className="row">
            {(asistente.img !== null) &&
            <div className="col-md-12 col-lg-2">
                <ImgCircledPreviewInput
                    imgText="Img"
                    name='img'
                    value={asistente.img}
                />
            </div>
            }
            <div className="col-md-12 col-lg">
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <TextInputPreview
                            label="Nombre(s)"
                            name="name"
                            value={ asistente.nombre }
                        />
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <TextInputPreview
                            label="Apellido paterno"
                            name="apellidoPaterno"
                            value={ asistente.apellidoPaterno }
                        />
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <TextInputPreview
                            label="Apellido materno"
                            name="apellidoMat"
                            value={ (asistente.apellidoMaterno !== '' && asistente.apellidoMaterno !== undefined && asistente.apellidoMaterno !== null) ? asistente.apellidoMaterno : '-' }
                        />
                    </div>
                    <div className="col-sm-12 col-md-2">
                        <ActivoPreview status={ asistente.activo } text='Asistente activo' />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <TextInputPreview
                            label="Fecha de nacimiento"
                            name="fechaNacimiento"
                            value={ asistente.fechaNacimientoFormato }
                        />
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <TextInputPreview
                            label="Edad"
                            name="edad"
                            value={ asistente.edad }
                        />
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <TextInputPreview
                            label="Género"
                            name="genero"
                            value={ asistente.genero }
                        />
                    </div>
                    <div className="col-sm-12 col-md-2"></div>
                </div>
                <div className="row">
                    <div className="col">
                        <TextInputPreview
                            label="Correo electrónico"
                            name="email"
                            value={ asistente.email }
                        />
                    </div> 
                </div>
                <div className="row">
                    <div className="col">
                        <PrimaryButton
                            inverse={true}
                            onClick={handleEditarDatosGenerales}
                            text="Editar Datos Generales"
                            type="button"
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

DatosGeneralesPreview.propTypes = {
    asistente: PropTypes.object.isRequired,
    handleEditarDatosGenerales: PropTypes.func.isRequired
}