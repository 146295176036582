import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import { inmunetApi } from '../../api';

import styles from './FormFields.module.css';

export const AlergenoInput = ({ defaultValue, formFieldStatus='checked', isClearable = false, label = '', name, onChange, prueba, required = false, requiredIndicator = false, reset, setFormFieldValidation, value}) => {

    const css = `
        .select__control--is-focused{
            border-color: #86b7fe !important;
            box-shadow: none !important;
        }
    `;

    // const [initialFlag, setInitialFlag] = useState(false);

    const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputValidation = useCallback((valueAux) => {
        if(valueAux !== ''){
            setInputValidation({ isValid: true, msg: null });
            if(setFormFieldValidation) setFormFieldValidation(name, true);
            // setInitialFlag(true);
        }else{
            if(required){
            // if(required && initialFlag){
                setInputValidation({ isValid: false, msg: 'Selecciona un elemento de la lista' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
                // setInitialFlag(true);
            }else{
                setInputValidation({ isValid: true, msg: null });
                if(setFormFieldValidation) setFormFieldValidation(name, true);
                // setInitialFlag(true);
            }
        }
    })

    const handleOnChange = (value) => {
        if (value != null) {
            onChange({ target: { name: name, value: value.value } });
        }else{
            onChange({ target: { name: name, value: '' } });
        }
        handleInputValidation(value);
    }

    useEffect(() => {
        setInputValidation({ isValid: null, msg: null });
        if(required){
            if(setFormFieldValidation) setFormFieldValidation(name, false);
        }else{
            if(setFormFieldValidation) setFormFieldValidation(name, true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset])

    useEffect(() => {
        if(formFieldStatus === 'checking') handleInputValidation();
    }, [formFieldStatus, handleInputValidation]);

    function fetchData(inputValue) {
        let formData = new FormData();
        formData.append(`prueba`, prueba);
        return inmunetApi.post(`/pruebaAlergia/alergenos?paginacion=true&pag=1&limite=10&search=${ inputValue }`, formData)
          .then(response => {
            let arr = response.data.alergenos.map(alerg => ({ value: alerg._id, label: alerg.nombre }));
            if (!arr.find((option) => option.value === inputValue)) {
                arr.push({
                    value: `||created||${inputValue}`,
                    label: inputValue,
                });
            }
            // if (defaultValue && !arr.find((option) => option.value === defaultValue)) {
            //     arr.push({
            //         value: defaultValue,
            //         label: defaultValue.replace('||', ''),
            //     });
            // }
            return arr;
        });
    }

    const loadOptions = (inputValue) => new Promise((resolve) => {
        setTimeout(() => {
            resolve(fetchData(inputValue));
        }, 1000);
    });

    // let defaultOptions = [];
    // if(defaultValue.includes('||')){
    //     defaultOptions.push({ value: value, label: value.replace('||', '') });
    // }

    return (
        <>
            <style>{ css }</style>
            {(label !== '') && <label htmlFor={ name } className={styles.customPreviewLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>}
            <AsyncSelect
                className={"basic-multi-select " + styles.basicMultiSelect}
                classNamePrefix="select"
                // defaultOptions={defaultOptions}
                // defaultValue={
                //     defaultValue
                //         ? { value: defaultValue, label: defaultValue.replace('||', '') }
                //         : null
                // }
                isClearable={isClearable}
                loadingMessage={() => 'Cargando...'}
                noOptionsMessage={() => 'Escribe para buscar'}
                isSearchable
                name={name}
                onChange={handleOnChange}
                onKeyUp={handleInputValidation}
                placeholder=""
                loadOptions={loadOptions}
                // cacheOptions={true}
            />
            <div className={styles.helperErrorTextReactSelect + ((inputValidation.isValid != null && !inputValidation.isValid) ? "" : " d-none" )}>{(inputValidation.isValid != null && !inputValidation.isValid) && inputValidation.msg}</div>
        </>
    )
}

AlergenoInput.propTypes = {
    // defaultValue: PropTypes.any,
    formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
    isClearable: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    prueba: PropTypes.string.isRequired,
    required: PropTypes.bool,
    requiredIndicator: PropTypes.bool,
    reset: PropTypes.number,
    setFormFieldValidation: PropTypes.func,
    value: PropTypes.string.isRequired,
}