import { useEffect } from "react";
import PropTypes from 'prop-types';

import { useForm, useFormValidation } from "../../../../hooks";

import { IconButton, ReactAutocompleteInput } from "../../.."

import { colors } from "../../../../types/types";

import styles from '../MultiFormEstudios.module.css';

export const EstudioForm = ({ addElement, element, last, laboratorio, formStatus='checked', onFormChange, removeElement }) => {

  const { estudio, onInputChange } = useForm({ estudio: element.estudio });

  const { isFormValid, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'estudio', default: (element.estudio !== '')}]);

  useEffect(() => {
    if(onFormChange) onFormChange({ id: element.id, estudio, validation: isFormValid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estudio, isFormValid])

  useEffect(() => {
    if(formStatus === 'checking') startFormValidation();
  }, [formStatus, startFormValidation]);

  useEffect(() => {
    if(estudio !== ''){
      startFormValidation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="row">
      <div className="col-11">
        <ReactAutocompleteInput
          formFieldStatus={ formFields['estudio'].status }
          isClearable={true}
          label="Estudio"
          name="estudio"
          onChange={ onInputChange }
          required={true}
          requiredIndicator={true}
          setFormFieldValidation={ setFormFieldValidation }
          value={estudio}
          values={laboratorio.map(radio => ({ value: radio._id, label: radio.nombre }))}
        />
      </div>
      {(last)
      ?<div className={"col-1"}>
        <div className={styles.closeBtnContainer}>
          <div>
            <label htmlFor="">&nbsp;</label>
            <IconButton
              icon="Plus"
              iconColor={colors.PRIMARY}
              marginTop={false}
              onClick={addElement}
              type="button"
            />
          </div>
        </div>
      </div>
      :<div className={"col-1"}>
        <div className={styles.closeBtnContainer}>
          <div>
            <label htmlFor="">&nbsp;</label>
            <IconButton
              icon="X"
              iconColor={colors.DANGER}
              marginTop={false}
              onClick={() => removeElement(element.id)}
              type="button"
            />
          </div>
        </div>
      </div>
      }
    </div>
  )
}

EstudioForm.propTypes = {
  addElement: PropTypes.func.isRequired,
  element: PropTypes.any.isRequired,
  laboratorio: PropTypes.array.isRequired,
  formStatus: PropTypes.oneOf(['checked', 'checking']),
  onFormChange: PropTypes.func,
  removeElement: PropTypes.func.isRequired,
}