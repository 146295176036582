import { Link, Outlet } from "react-router-dom";

import styles from './PublicLayout.module.css';

export const PublicLayout = () => {

    const css = `
        .notification-layout-margin{
            top: 72px !important;
        }
    `;

    return (
        <>
            <style>{ css }</style>
            <div className="container-fluid p-0">
                {/* Navbar */}
                <nav className="navbar fixed-top bg-body-tertiary">
                    <Link className="navbar-brand" to="/">
                        <div className={styles.navbarLogo} />
                    </Link>
                </nav>
                {/* Navbar */}
                <div className={"container " + styles.mainContainer}>
                    <Outlet />
                </div>
                {/* Footer */}
                <footer className="text-center text-lg-start bg-light text-muted">
                    <div className="text-center p-4 allergien-bg-green allergien-footer-text">
                        { new Date().getFullYear() } Derechos reservados&nbsp;<Link to="/" className="text-reset fw-bold">Inmunet</Link>®
                    </div>
                </footer>
                {/* Footer */}
            </div>
        </>
    )
}
