import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'react-bootstrap-icons';

import styles from './FormFields.module.css';

export const ImgCircledInput = ({ formFieldStatus='checked', imgText = 'Img', label = undefined, name, onChange, required = false, requiredIndicator = false, setFormFieldValidation, value }) => {

    const inputButton = useRef(null);

    const [imgPreview, setImgPreview] = useState(`https://placehold.co/400x400?text=${ imgText }`);

    const [inputValidation, setInputValidation] = useState({ isValid: null, msg: null });

    const handleFileLoadTrigger = () => {
        inputButton.current?.click();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputValidation = useCallback((e) => {
        if(e !== undefined){
            var imagen = e.target.files[0];
            if(imagen && imagen["type"] !== "image/jpeg" && imagen["type"] !== "image/png" && imagen["type"] !== "image/jpg"){
                setInputValidation({ isValid: false, msg: 'La imagen debe estar en formato JPG, JPEG o PNG' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
                return false;
            }else if(imagen && imagen["size"] > 2000000){
                setInputValidation({ isValid: false, msg: 'La imagen no debe pesar más de 2MB' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
                return false;
            }else{
                setInputValidation({ isValid: true, msg: null });
                if(setFormFieldValidation) setFormFieldValidation(name, true);
                return true;
            }
        }else{
            if((imgPreview === 'https://placehold.co/400x400?text=Logo') && required){
                setInputValidation({ isValid: false, msg: 'Selecciona una imagen' });
                if(setFormFieldValidation) setFormFieldValidation(name, false);
                return false;
            }else{
                setInputValidation({ isValid: true, msg: null });
                if(setFormFieldValidation) setFormFieldValidation(name, true);
                return true;
            }
        }
    })

    const handleChange = (e) => {
        if(handleInputValidation(e)){
            onChange({ target: { name: name, value: e.target.files[0] } });
            setImgPreview(URL.createObjectURL(e.target.files[0]));
        }
    }

    useEffect(() => {
        if(value !== null){
            setImgPreview(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(formFieldStatus === 'checking') handleInputValidation();
      }, [formFieldStatus, handleInputValidation]);
    
    
    return (
        <>
            <label htmlFor={ name } className={styles.customInputLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>
            <div className={styles.customImgPreviewContainer} onClick={handleFileLoadTrigger}>
                <div className={styles.circledImg + ((inputValidation.isValid != null && !inputValidation.isValid) ? " " + styles.circledImgError : "")} style={{ backgroundImage: `Url('${ imgPreview }')` }}></div>
                <Icon.PencilSquare className={styles.customImgPreviewIcon} />
            </div>
            <div className={styles.circledImgHelperErrorText + ((inputValidation.isValid != null && !inputValidation.isValid) ? "" : " d-none" )}>{(inputValidation.isValid != null && !inputValidation.isValid) && inputValidation.msg}</div>
            <input className="d-none" type="file" onChange={handleChange} ref={inputButton} />
        </>
    )
}

ImgCircledInput.propTypes = {
    formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
    imgText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    requiredIndicator: PropTypes.bool,
    setFormFieldValidation: PropTypes.func,
    value: PropTypes.any,
}