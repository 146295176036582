import { useQuery } from "@tanstack/react-query"

import { getCitas } from "../services/citas.service";

export const useCitas = ({ id, pag, limite }) => {

    const citasQuery = useQuery({
        queryKey: ['citas', id, { pag, limite }],
        queryFn: () => getCitas(id, pag, limite),
        staleTime: 1000 * 60 * 5, // 5 min
        placeholderData: {
            desde: 0,
            hasta: 0,
            limite_por_pagina: 5,
            citas: [],
            pagina: 0,
            total: 0,
            total_paginas: 0
        }
    });

    return {
        citasQuery,
    }
}