import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import styles from './FormFields.module.css';

export const ReactSelectInputPreview = ({ label = '', name, values}) => {

  const css = `
        .select__indicators{
            display: none;
        }
        .select__value-container{
          padding-left: 0px;
          padding-right: 0px;
        }
        .select__multi-value__remove{
          display: none;
        }
        .select__multi-value{
          background-color: var(--primary);
          border-radius: 3px;
        }
        .select__multi-value__label{
          padding: 6px !important;
          color: #FFF;
        }
    `;
  
  return (
    <>
      <style>{ css }</style>
      {(label !== '') && <label htmlFor={ name } className={styles.customPreviewLabel}>{ label }</label>}
      <Select
        defaultValue={values.map(value => ({ value: value, label: value }))}
        isDisabled={true}
        isMulti
        name={name}
        options={values.map(value => ({ value: value, label: value }))}
        className={"basic-multi-select " + styles.basicMultiSelectPreview}
        classNamePrefix="select"
      />
    </>
  )
}

ReactSelectInputPreview.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
}