import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { inmunetApi } from '../../api';

import { successNotification, dangerNotification, setConsultorio } from "../../redux/slices/ui";
import { setToken } from "../../redux/slices/auth";

import styles from './ConsultorioSelector.module.css';

export const ConsultorioSelector = () => {

    const dispatch = useDispatch();

    const [consultoriosDisponibles, setConsultoriosDisponibles] = useState([]);

    const handleOnChange = async(e) => {

      try {

          let resp = await inmunetApi.post('/ui/cambiar-consultorio', { consultorio: e.target.value });
          dispatch(setToken(resp.data.token));
          dispatch(setConsultorio(resp.data.consultorio));
          dispatch(successNotification('Consultorio actualizado'));

      } catch (error) {

          if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.msg !== 'undefined') {
  
              dispatch(dangerNotification(error.response.data.msg));
  
          } else {
  
              dispatch(dangerNotification('Error al actualizar consultrio'));
  
          }

      }

    }

    useEffect(() => {
        async function fetchData(){
          try {
      
            let resp = await inmunetApi.get(`/ui/consultorio`);
      
            setConsultoriosDisponibles(resp.data.consultorios);
      
          } catch (error) {
      
            dispatch(dangerNotification('Error al recuperar consultorios disponibles.'));
      
          }
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <select className={"form-select " + styles.customInputBasic} onChange={ handleOnChange }>
        {/* <select className={"form-select " + styles.customInputBasic} onChange={ handleOnChange } defaultValue={value}> */}
            { consultoriosDisponibles.map(consultorio => {
                return <option key={consultorio._id} value={consultorio._id}>{ consultorio.nombre }</option>
            }) }
        </select>
    )
}
