import { Link, Outlet, useLocation } from "react-router-dom";

import styles from './ConfiguracionSider.module.css';

export const ConfiguracionSider = () => {

  const location = useLocation();

  return (
    <>
        <div className="row flex-grow-1">
            <div className={"col-sm-3 col-md-2 " + styles.sideContainer}>
                <div className={styles.header}>Configuración</div>
                <div className={styles.sideItem + ((location.pathname === '/perfil') ? " " + styles.sideItemActive : "" )}><Link to="/perfil" className={styles.sideItemContainer}><div className={styles.sideIcon + " " + ((location.pathname === '/perfil') ? styles.sideIconPerfilWhite : styles.sideIconPerfilBlack )}></div>Perfil</Link></div>
                <div className={styles.sideItem + ((location.pathname === '/consultorios') ? " " + styles.sideItemActive : "" )}><Link to="/consultorios" className={styles.sideItemContainer}><div className={styles.sideIcon + " " + ((location.pathname === '/consultorio') ? styles.sideIconConsultorioWhite : styles.sideIconConsultorioBlack )}></div>Consultorios</Link></div>
                <div className={styles.sideItem + ((location.pathname === '/configuracion-expediente-clinico') ? " " + styles.sideItemActive : "" )}><Link to="/configuracion-expediente-clinico" className={styles.sideItemContainer}><div className={styles.sideIcon + " " + ((location.pathname === '/configuracion-expediente-clinico') ? styles.sideIconExpedienteClinicoWhite : styles.sideIconExpedienteClinicoBlack )}></div>Expediente Clínico</Link></div>
                <div className={styles.sideItem + ((location.pathname === '/asistentes') ? " " + styles.sideItemActive : "" )}><Link to="/asistentes" className={styles.sideItemContainer}><div className={styles.sideIcon + " " + ((location.pathname === '/asistentes') ? styles.sideIconAsistentesWhite : styles.sideIconAsistentesBlack )}></div>Asistentes</Link></div>
                <div className={styles.sideItem + ((location.pathname === '/honorarios') ? " " + styles.sideItemActive : "" )}><Link to="/honorarios" className={styles.sideItemContainer}><div className={styles.sideIcon + " " + ((location.pathname === '/honorarios') ? styles.sideIconHonorariosWhite : styles.sideIconHonorariosBlack )}></div>Honorarios</Link></div>
                <div className={styles.sideItem + ((location.pathname === '/cfdi') ? " " + styles.sideItemActive : "" )}><Link to="/cfdi" className={styles.sideItemContainer}><div className={styles.sideIcon + " " + ((location.pathname === '/cfdi') ? styles.sideIconCFDIWhite : styles.sideIconCFDIBlack )}></div>CFDI</Link></div>
            </div>
            <div className={"col " + styles.contentColumn}><Outlet /></div>
        </div>
    </>
  )
}
