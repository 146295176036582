import { useEffect } from "react";
import PropTypes from 'prop-types';

import { useForm, useFormValidation } from "../../../../hooks";

import { NumberInput } from "../../.."

export const MotivoForm = ({ element, formStatus='checked', label = '', onFormChange }) => {

  const { duracion, onInputChange } = useForm({ duracion: parseInt(element.duracion) });

  const { isFormValid, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'duracion', default: true}]);

  useEffect(() => {
    if(onFormChange) onFormChange({ _id: element._id, nombre: element.nombre, duracion: parseInt(duracion), validation: isFormValid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duracion, isFormValid])

  useEffect(() => {
    if(formStatus === 'checking') startFormValidation();
  }, [formStatus, startFormValidation]);

  return (
    <div className="col-3">
        <NumberInput
            formFieldStatus={ formFields['duracion'].status }
            isClearable={true}
            label={label}
            name="duracion"
            onChange={ onInputChange }
            required={true}
            requiredIndicator={true}
            setFormFieldValidation={ setFormFieldValidation }
            value={duracion}
        />
    </div>
  )
}

MotivoForm.propTypes = {
  element: PropTypes.any.isRequired,
  formStatus: PropTypes.oneOf(['checked', 'checking']),
  label: PropTypes.string,
  onFormChange: PropTypes.func,
}