import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { getPruebasAlergia, updatePruebasAlergia } from "../services/pruebasalergia.service";

import { successNotification, dangerNotification } from '../../../../../redux/slices/ui';

export const usePruebasAlergia = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const pruebasAlergiaQuery = useQuery({
        queryKey: ['configuracionExpedienteClinico/pruebasAlergia'],
        queryFn: getPruebasAlergia,
        staleTime: 1000 * 60 * 60, // 1 hora
    });

    const updatePruebasAlergiaMutation = useMutation({
        mutationFn: updatePruebasAlergia,
        onSuccess: (pruebasAlergia, variables, context) => {
            queryClient.setQueryData(['configuracionExpedienteClinico/pruebasAlergia'], oldData => {

                return pruebasAlergia;
                
            });
            dispatch(successNotification('Datos de pruebas de alergia actualizados'));
        },
        onError: (error, variables, context) => {
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        pruebasAlergiaQuery,
        updatePruebasAlergiaMutation,
    }
}