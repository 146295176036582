import PropTypes from 'prop-types';

import styles from './ModalContainer.module.css';

export const ModalContainer = ({ background = true, content, hide, state, width = '50%' }) => {

    const handleHide = ({ currentTarget, target }) => {
        if(currentTarget === target){
            hide();
        }
    }

    return (
        <div className={((background) ? styles.customModalBackground : styles.customModalNoBackground) + ((!state) ? " d-none" : "")} onClick={handleHide} id="modalContainerBackground">
            <div className={styles.customModalPanel} style={{ width: width }}>{ content }</div>
        </div>
    )
}

ModalContainer.propTypes = {
    background: PropTypes.bool,
    content: PropTypes.element.isRequired,
    hide: PropTypes.func.isRequired,
    state: PropTypes.bool.isRequired,
    width: PropTypes.string,
}