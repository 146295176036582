import { useState } from 'react';
import PropTypes from 'prop-types';

import { usePacienteDetalleCuestionario } from './hooks/usePacienteDetalleCuestionario';

import { IconButton, LightButton } from '../../../components';
import { CuestionarioRinitis } from './components/CuestionarioRinitis';
import { CuestionarioUrticaria } from './components/CuestionarioUrticaria';
import { CuestionarioAsma } from './components/CuestionarioAsma';
import { CuestionarioPoem } from './components/CuestionarioPoem';

import styles from '../Modals.module.css';

export const DetalleCuestionarioModal = ({ paciente, cuestionario, hide }) => {

    const { pacienteDetalleCuestionarioQuery: { isLoading, isError, data: pacienteDetalleCuestionario } } = usePacienteDetalleCuestionario(paciente, cuestionario.numero);

    const [showCuestionario, setShowCuestionario] = useState(false);
    const [aplicacionData, setAplicacionData] = useState(null);

    const handleShowCuestionario = (id_aplicacion) => {
        let data = pacienteDetalleCuestionario.cuestionarios.find(aplicacion => String(aplicacion._id) === String(id_aplicacion));
        setAplicacionData(data);
        setShowCuestionario(true);
    }

    const handleHideCuestionario = () => {
        setAplicacionData(null);
        setShowCuestionario(false);
    }

    return (
        <div>
            <div className="row mb-3">
                <div className="col">
                    <div className={styles.modalFourthHeader}>{(showCuestionario) ? 'Cuestionario ' + cuestionario.nombre : cuestionario.nombre}</div>
                </div>
            </div>
            {(!showCuestionario || aplicacionData === null || isLoading || isError) && 
            <div className="row">
                <div className="col">
                    <div className="table-responsive">
                        <table className="table align-middle">
                        <thead>
                            <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Indicador</th>
                            <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            { (typeof cuestionario.pasados !== 'undefined' && !isLoading && !isError) ? cuestionario.pasados.cuestionarios.map(aplicacion => <tr key={aplicacion._id}>
                                <td>{aplicacion.fechaRespuestaFormato}</td>
                                <td><span className="badge rounded-pill" style={{backgroundColor: aplicacion.semaforoColor}}>{aplicacion.semaforoMsg}</span></td>
                                <td>
                                    <div className='d-flex flex-row-reverse'>
                                        <IconButton
                                            icon='CardChecklist'
                                            iconColor='#666666'
                                            marginTop={false}
                                            onClick={() => handleShowCuestionario(aplicacion._id)}
                                            type='button'
                                            size={20}
                                        />
                                    </div>
                                </td>
                            </tr>) : <tr><td colSpan={6} className='text-center'>Recuperando información...</td></tr> }
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            }
            {(showCuestionario && aplicacionData !== null && !isLoading && !isError && cuestionario.numero === 1) && <CuestionarioRinitis aplicacion={aplicacionData} />}
            {(showCuestionario && aplicacionData !== null  && !isLoading && !isError && cuestionario.numero === 2) && <CuestionarioUrticaria aplicacion={aplicacionData} />}
            {(showCuestionario && aplicacionData !== null  && !isLoading && !isError && cuestionario.numero === 3) && <CuestionarioAsma aplicacion={aplicacionData} />}
            {(showCuestionario && aplicacionData !== null  && !isLoading && !isError && cuestionario.numero === 4) && <CuestionarioPoem aplicacion={aplicacionData} />}
            <div className="row">
                <div className='col-xs-12 col-sm-12 col-md-6'></div>
                <div className="col-xs-12 col-sm-12 col-md-6">
                    <div className='d-flex flex-row-reverse'>
                        <LightButton
                            marginTop={false}
                            onClick={ () => {
                                if(showCuestionario){
                                    handleHideCuestionario();
                                }else{
                                    hide();
                                }
                            } }
                            text="Cerrar"
                            type="button"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

DetalleCuestionarioModal.propTypes = {
    cuestionario: PropTypes.object.isRequired,
    hide: PropTypes.func.isRequired
}