import React from 'react'
import PropTypes from 'prop-types';

import { PrimaryButton } from '../../../components'

import styles from '../PacienteAntecedentes.module.css';

export const PacienteAntecedentesPreview = ({ configuracionDatosGenerales, handleEditarPacienteAntecedentes, pacienteAntecedentes }) => {

    return (
        <>
            <div className="row mt-3">
                <div className="col-sm-12 col-md">
                    <div className='d-flex justify-content-between'>
                        <div className={styles.header}>Antecedentes</div>
                        <PrimaryButton
                            icon="PencilSquare"
                            iconMargin={5}
                            inverse={true}
                            marginTop={false}
                            onClick={handleEditarPacienteAntecedentes}
                            size={18}
                            text="Editar"
                            type="button"
                        />
                    </div>
                </div>
            </div>
            {(configuracionDatosGenerales.antecedentes.heredofamiliares) &&
            <div className="row">
                <div className="col-md-12">
                    <div className={styles.subHeader}>Heredofamiliares</div>
                </div>
                {(pacienteAntecedentes.heredofamiliares.length > 0) ? pacienteAntecedentes.heredofamiliares.map(heredoFamiliar => <div key={heredoFamiliar._id} className='col-md-12'><div className={styles.data}>{heredoFamiliar.data}</div></div>) : <div className='col-md-12'><div className={styles.data}>-</div></div>}
            </div>
            }
            {(configuracionDatosGenerales.antecedentes.personalesPatologicos) &&
            <div className="row">
                <div className="col-md-12">
                    <div className={styles.subHeader}>Personales patológicos</div>
                </div>
                {(pacienteAntecedentes.personalesPatologicos.length > 0) ? pacienteAntecedentes.personalesPatologicos.map(personalPatologico => <div key={personalPatologico._id} className='col-md-12'><div className={styles.data}>{personalPatologico.data}</div></div>) : <div className='col-md-12'><div className={styles.data}>-</div></div>}
            </div>
            }
            {(configuracionDatosGenerales.antecedentes.personalesNoPatologicos) &&
            <div className="row">
                <div className="col-md-12">
                    <div className={styles.subHeader}>Personales no patológicos</div>
                </div>
                {(pacienteAntecedentes.personalesNoPatologicos.length > 0) ? pacienteAntecedentes.personalesNoPatologicos.map(personalNoPatologico => <div key={personalNoPatologico._id} className='col-md-12'><div className={styles.data}>{personalNoPatologico.data}</div></div>) : <div className='col-md-12'><div className={styles.data}>-</div></div>}
            </div>
            }
            {(configuracionDatosGenerales.antecedentes.ginobstetricos) &&
            <div className="row">
                <div className="col-md-12">
                    <div className={styles.subHeader}>Ginobstétricos</div>
                </div>
                {(pacienteAntecedentes.ginobstetricos.length > 0) ? pacienteAntecedentes.ginobstetricos.map(ginobstetrico => <div key={ginobstetrico._id} className='col-md-12'><div className={styles.data}>{ginobstetrico.data}</div></div>) : <div className='col-md-12'><div className={styles.data}>-</div></div>}
            </div>
            }
            {(configuracionDatosGenerales.antecedentes.perinatales) &&
            <div className="row">
                <div className="col-md-12">
                    <div className={styles.subHeader}>Perinatales</div>
                </div>
                {(pacienteAntecedentes.perinatales.length > 0) ? pacienteAntecedentes.perinatales.map(perinatal => <div key={perinatal._id} className='col-md-12'><div className={styles.data}>{perinatal.data}</div></div>) : <div className='col-md-12'><div className={styles.data}>-</div></div>}
            </div>
            }
        </>
    )
}

PacienteAntecedentesPreview.propTypes = {
    configuracionDatosGenerales: PropTypes.object.isRequired,
    handleEditarPacienteAntecedentes: PropTypes.func.isRequired,
    pacienteAntecedentes: PropTypes.any.isRequired,
}