import React from 'react'

import { useDatosGenerales } from './hooks/useDatosGenerales';

import { DatosGeneralesUpdate } from './components/DatosGeneralesUpdate';
import { Error, Loader } from '../../../../components';

export const DatosGenerales = () => {

    const { datosGeneralesQuery: { isLoading, isError, data: datosGenerales } } = useDatosGenerales();

    return (
        <>
            {(isLoading)
            ? <Loader />
            : <>
                {(isError)
                ? <Error />
                : <>
                    <DatosGeneralesUpdate datosGenerales={datosGenerales} />
                </>
                }
            </>
            }
        </>
    )
}
