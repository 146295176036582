import { useQuery } from "@tanstack/react-query"

import { getPacienteGraficaCuestionario } from "../services/pacientegraficacuestionario";

export const usePacienteGraficaCuestionario = ({ id, cuestionario, pag }) => {

    const pacienteGraficaCuestionarioQuery = useQuery({
        queryKey: ['paciente/graficaCuestionario', { id, cuestionario, pag }],
        queryFn: () => getPacienteGraficaCuestionario(id, cuestionario, pag),
        staleTime: 1000 * 60 * 5, // 5 min
        placeholderData: {
            año: '',
            mes: '',
            semanas: 0,
            data: {},
            prev: false,
            next: false
        }
    });

    return {
        pacienteGraficaCuestionarioQuery,
    }
}