import { useState } from "react";
import PropTypes from 'prop-types';

import { ConsultoriosPreview } from "./ConsultoriosPreview";
import { ConsultoriosUpdate } from "./ConsultoriosUpdate";

import styles from '../Asistentes.module.css';

export const Consultorios = ({ asistente }) => {

    const [consultoriosUpdateStatus, setConsultoriosUpdateStatus] = useState(false);

    const handleEditarConsultorios = () => {
        setConsultoriosUpdateStatus(true);
    }

    const handleCancelarEditarConsultorios = () => {
        setConsultoriosUpdateStatus(false);
    }

    return (
        <>
            <div className={styles.subHeader}>Consultorios</div>
            {(!consultoriosUpdateStatus)
            ? <ConsultoriosPreview asistente={asistente} handleEditarConsultorios={handleEditarConsultorios} />
            : <ConsultoriosUpdate asistente={asistente} handleCancelarEditarConsultorios={handleCancelarEditarConsultorios} />
            }
        </>
    )
}

Consultorios.propTypes = {
    asistente: PropTypes.object.isRequired
}