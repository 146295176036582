import { useState } from 'react';
import PropTypes from 'prop-types';

import { useConfiguracionConsultorio } from '../hooks/useConfiguracionConsultorio';

import { ConsultorioPreview } from './ConsultorioPreview';
import { ConsultorioUpdate } from './ConsultorioUpdate';
import { Error, Loader } from '../../../components';

import styles from '../Consultorios.module.css';

export const Consultorio = ({ consultorio }) => {

    const { configuracionConsultorioQuery: { isLoading, isError, data: configuracion } } = useConfiguracionConsultorio(consultorio._id);

    const [consultorioUpdateStatus, setConsultorioUpdateStatus] = useState(false);

    const handleEditarConsultorio = () => {
        setConsultorioUpdateStatus(true);
    }

    const handleCancelarEditarConsultorio = () => {
        setConsultorioUpdateStatus(false);
    }

    return (
        <>
        {(isLoading)
            ? <Loader />
            : <>
            {(isError)
                ? <Error />
                : <>
                    <div className={styles.panel}>
                        {(!consultorioUpdateStatus) &&
                            <ConsultorioPreview consultorio={consultorio} handleEditarConsultorio={handleEditarConsultorio} />
                        }
                        {(consultorioUpdateStatus) &&
                            <ConsultorioUpdate consultorio={consultorio} configuracion={configuracion} handleCancelarEditarConsultorio={handleCancelarEditarConsultorio} />
                        }
                    </div>
                </>
            }
            </>
        }
        </>
    )
}

Consultorio.propTypes = {
    consultorio: PropTypes.object.isRequired
}