import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';

import styles from './FormFields.module.css';

export const PasswordConfirmationInput = ({ formFieldStatus='checked', label, name, onChange, password, placeholder, required = false, requiredIndicator = false, setFormFieldValidation, value}) => {
  
  const [inputValidation, setInputValidation] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputValidation = useCallback(() => {
    if(validator.isStrongPassword(value)){
        if(value === password){
            setInputValidation(true);
            if(setFormFieldValidation) setFormFieldValidation(name, true);
        }else{
            setInputValidation(false);
            if(setFormFieldValidation) setFormFieldValidation(name, false);
        }
    }else{
        if(value !== ''){
            setInputValidation(false);
            if(setFormFieldValidation) setFormFieldValidation(name, false);
        }else{
            setInputValidation(null);
            if(required){
                if(setFormFieldValidation) setFormFieldValidation(name, false);
            }else{
                if(setFormFieldValidation) setFormFieldValidation(name, true);
            }
        }
    }
  })

  useEffect(() => {
    if(formFieldStatus === 'checking') handleInputValidation();
  }, [formFieldStatus, handleInputValidation]);
  
  return (
    <>
        <label htmlFor={ name } className={styles.customInputLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>
        <input type="password" className={"form-control " + styles.customInputBasic + ((inputValidation != null && !inputValidation) ? " " + styles.customErrorInputBasic : "")} placeholder={ placeholder } name={ name } value={ value } onChange={ onChange } onKeyUp={handleInputValidation} required={required} />
        <div className={styles.helperErrorText + ((inputValidation != null && !inputValidation) ? "" : " d-none" )}>Las contraseñas no coinciden</div>
    </>
  )
}

PasswordConfirmationInput.propTypes = {
  formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.bool,
  setFormFieldValidation: PropTypes.func,
  value: PropTypes.string,
}