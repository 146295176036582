import { useState } from 'react';
import PropTypes from 'prop-types';

import { DatosGeneralesPreview } from './DatosGeneralesPreview';
import { DatosGeneralesUpdate } from './DatosGeneralesUpdate';

import styles from '../Asistentes.module.css';

export const DatosGenerales = ({ asistente }) => {

    const [datosGeneralesUpdateStatus, setDatosGeneralesUpdateStatus] = useState(false);

    const handleEditarDatosGenerales = () => {
        setDatosGeneralesUpdateStatus(true);
    }

    const handleCancelarEditarDatosGenerales = () => {
        setDatosGeneralesUpdateStatus(false);
    }

    return (
        <>
            <div className={styles.subHeader}>Datos Generales</div>
            {(!datosGeneralesUpdateStatus)
            ? <DatosGeneralesPreview asistente={asistente} handleEditarDatosGenerales={handleEditarDatosGenerales} />
            : <DatosGeneralesUpdate asistente={asistente} handleCancelarEditarDatosGenerales={handleCancelarEditarDatosGenerales} />
            }
        </>
    )
}

DatosGenerales.propTypes = {
    asistente: PropTypes.object.isRequired
}