import React from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const MultiFilePreview = ({ label, name, files}) => {
  
  return (
    <>
        <div className={styles.customMultiPreviewContainer}>
            <label htmlFor={ name } className={styles.customMultiFilePreviewLabel}>{ label }</label>
            {files.map((files, index) => <div key={index}><a className={styles.customMultiFilePreview} href={files.archivo}>{ files.archivoName }</a></div>)}
        </div>
    </>
  )
}

MultiFilePreview.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired,
}