import { inmunetApi } from '../../../api';

export const getAsistentes = async() => {

    try {

        const { data } = await inmunetApi.get('/asistente');

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.asistentes;

    } catch (error) {

        throw new Error(error);

    }

};

export const createAsistente = async({ data: asistente, id }) => {

    try {

        let formData = new FormData();
        Object.keys(asistente).forEach(field => {
            formData.append(field, asistente[field]);
        });

        const { data } = await inmunetApi.post(`/asistente`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al crear asistente';

        return data.asistente;

    } catch (error) {

        if (typeof error.response.data.msg !== 'undefined' && error.response.data.msg !== '') {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error(error);
        }

    }

};

export const updateAsistente = async({ data: asistente, id }) => {

    try {

        let formData = new FormData();
        Object.keys(asistente).forEach(field => {
            formData.append(field, asistente[field]);
        });

        const { data } = await inmunetApi.put(`/asistente/${ id }`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al actualizar datos';

        return data.asistente;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error(error);
        }

    }

};