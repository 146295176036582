import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import * as Icon from 'react-bootstrap-icons';

import { colors } from '../../types/types';
import styles from './FormFields.module.css';

export const PasswordInput = ({ formFieldStatus='checked', label, name, onChange, placeholder, required = false, requiredIndicator = false, setFormFieldValidation, value}) => {
  
  const [showPassword, setShowPassword] = useState(false);
  
  const [inputValidation, setInputValidation] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputValidation = useCallback(() => {
    if(validator.isStrongPassword(value)){
      setInputValidation(true);
      if(setFormFieldValidation) setFormFieldValidation(name, true);
    }else{
      if(value !== ''){
        setInputValidation(false);
        if(setFormFieldValidation) setFormFieldValidation(name, false);
      }else{
        setInputValidation(null);
        if(required){
          if(setFormFieldValidation) setFormFieldValidation(name, false);
        }else{
          if(setFormFieldValidation) setFormFieldValidation(name, true);
        }
      }
    }
  })

  useEffect(() => {
    if(formFieldStatus === 'checking') handleInputValidation();
  }, [formFieldStatus, handleInputValidation]);
  
  return (
    <>
        <label htmlFor={ name } className={styles.customInputLabel}>{ label }{ requiredIndicator && <span className={styles.requiredIndicator}> *</span> }</label>
        <div className={styles.FeedbackIconContainer}>
          <input type={showPassword ? "text" : "password"} className={"form-control " + styles.customInputBasic + ((inputValidation != null && !inputValidation) ? " " + styles.customErrorInputBasic : "")} placeholder={ placeholder } name={ name } value={ value } onChange={ onChange } onKeyUp={handleInputValidation} required={required} />
          {showPassword
            ? <Icon.EyeSlashFill className={styles.FeedbackIcon} color={ colors.INMPUT_FEEDBACK } onClick={() => setShowPassword(!showPassword)} />
            : <Icon.EyeFill className={styles.FeedbackIcon} color={ colors.INPUT_FEEDBACK } onClick={() => setShowPassword(!showPassword)} />
          }
        </div>
        <div className={styles.helperErrorText + ((inputValidation != null && !inputValidation) ? "" : " d-none" )}>
            <div>Contraseña no valida, debe contener al menos:</div>
            <div>• 8 caracteres</div>
            <div>• 1 mayúscula</div>
            <div>• 1 minúscula</div>
            <div>• 1 caracter especial</div>
        </div>
    </>
  )
}

PasswordInput.propTypes = {
  formFieldStatus: PropTypes.oneOf(['checked', 'checking']),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  requiredIndicator: PropTypes.bool,
  setFormFieldValidation: PropTypes.func,
  value: PropTypes.string,
}