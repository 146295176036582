import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { getDatosGenerales, updateDatosGenerales } from "../../../../../services/datosgenerales.service";

import { successNotification, dangerNotification } from '../../../../../redux/slices/ui';

export const useDatosGenerales = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const datosGeneralesQuery = useQuery({
        queryKey: ['configuracionExpedienteClinico/datosGenerales'],
        queryFn: getDatosGenerales,
        staleTime: 1000 * 60 * 60, // 1 hora
    });

    const updateDatosGeneralesMutation = useMutation({
        mutationFn: updateDatosGenerales,
        onSuccess: (datosGenerales, variables, context) => {
            queryClient.setQueryData(['configuracionExpedienteClinico/datosGenerales'], oldData => {

                return datosGenerales;
                
            });
            dispatch(successNotification('Datos generales actualizados'));
        },
        onError: (error, variables, context) => {
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        datosGeneralesQuery,
        updateDatosGeneralesMutation,
    }
}