import { useLoaderData, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { inmunetApi } from '../../api';

import { useForm, useFormValidation } from '../../hooks';
import { successNotification, dangerNotification } from "../../redux/slices/ui";

import { PasswordConfirmationInput, PasswordInput, PrimaryButton } from '../../components';

import styles from './PasswordReset.module.css';

export async function loader({ params }) {
    return params.token;
}

export const PasswordReset = () => {

    const token = useLoaderData();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { password, confirmation, onInputChange } = useForm({ password: '', confirmation: '' });

    const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'password', default: false}, {field: 'confirmation', default: false}]);

    const onSubmit = async ( event ) => {
        event.preventDefault();

        startFormValidation();
        
        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            
            try {

                localStorage.setItem('reset-password-token', token);
    
                await inmunetApi.put('/passwordReset/reset-password', { password });

                localStorage.removeItem('reset-password-token');
    
                dispatch(successNotification('Contraseña actualizada.'));

                navigate("/");
    
            } catch (error) {

                localStorage.removeItem('reset-password-token');
    
                if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.msg !== 'undefined') {
        
                  dispatch(dangerNotification(error.response.data.msg));
        
                } else {
        
                  dispatch(dangerNotification('Error al procesar la petición. Intenta nuevamente, si el problema persiste contacta con el quipo de soporte.'));
        
                }
    
            }
    
        }
    }

    return (
        <>
            <div className={styles.logoContainer}>
                <div className={styles.logo}></div>
            </div>
            <div className='row'>
                <div className='offset-md-0 col-md-12 offset-xl-3 col-xl-6'>
                    <form onSubmit={onSubmit}>
                        <div className='row'>
                            <div className='col'>
                                <PasswordInput
                                    formFieldStatus={ formFields['password'].status }
                                    label="Nueva contraseña"
                                    name="password"
                                    onChange={ onInputChange }
                                    required={true}
                                    requiredIndicator={false}
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ password }
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <PasswordConfirmationInput
                                    formFieldStatus={ formFields['confirmation'].status }
                                    label="Confirmar contraseña"
                                    name="confirmation"
                                    password={ password }
                                    onChange={ onInputChange }
                                    required={true}
                                    requiredIndicator={false}
                                    setFormFieldValidation={ setFormFieldValidation }
                                    value={ confirmation }
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <PrimaryButton
                                    block={true}
                                    disabled={formValidationStatus === 'checking'}
                                    marginTop={false}
                                    text="Cambiar contraseña"
                                    type="submit"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
