import PropTypes from 'prop-types';

import styles from './Link.module.css';

export const Link = ({ onClick, text }) => {
  return (
    <div className={styles.customLink} onClick={onClick}>{text}</div>
  )
}

Link.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
}