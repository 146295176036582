import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const SwitchInput = ({ label, labelPosition = 'end', name, onChange, required = false, checked }) => {

  const handleInputChange = (e) => {
    e.target.value = (e.target.value === 'true') ? false : true;
    onChange(e);
  }

  return (
    <>
        <div className="form-check form-switch">
            {(labelPosition === 'start') && <label className="form-check-label mr-3" htmlFor="flexSwitchCheckDefault">{ label }</label>}
            <input className={"form-check-input " + styles.switch} type="checkbox" role="switch" name={ name } value={ (checked === true) ? 'true' : 'false' } checked={ (checked === true) ? true : false } onChange={ handleInputChange } required={required} />
            {(labelPosition === 'end') && <label className="form-check-label ml-3" htmlFor="flexSwitchCheckDefault">{ label }</label>}
        </div>
    </>
  )
}

SwitchInput.propTypes = {
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['start', 'end']),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  checked: PropTypes.bool,
}