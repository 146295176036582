import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { updatePaciente } from "../services/pacienteeditar.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteEditar = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const updatePacienteMutation = useMutation({
        mutationFn: updatePaciente,
        onSuccess: (paciente, variables, context) => {
            queryClient.setQueryData(['paciente', paciente._id], paciente);
            variables.onSuccess();
            dispatch(successNotification('Paciente actualizado'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        updatePacienteMutation,
    }
}