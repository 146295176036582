import { useQuery } from "@tanstack/react-query"

import { getPacienteDetalleCuestionario } from "../services/pacientedetallecuestionario.service";

export const usePacienteDetalleCuestionario = (id, cuestionario) => {

    const pacienteDetalleCuestionarioQuery = useQuery({
        queryKey: ['paciente/detalleCuestionario', id, cuestionario],
        queryFn: () => getPacienteDetalleCuestionario(id, cuestionario),
        staleTime: 1000 * 60 * 5, // 5 min
    });

    return {
        pacienteDetalleCuestionarioQuery,
    }
}