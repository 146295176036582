import { useSelector } from 'react-redux';
import styles from './NotificationsSystem.module.css';

export const NotificationsSystem = () => {

    const { notifications } = useSelector(state => state.ui);

    return (
        <>
            <div className={"notification-layout-margin " + styles.notificationsContainer}>
                {notifications.map(noty => {

                    return <div key={ noty.id } className={styles.notificationContainer}>
                        <div className={"alert alert-" + noty.type + " " + styles.notification} role="alert">{noty.content}</div>
                    </div>

                })}
            </div>
        </>
    )
}
