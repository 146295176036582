import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';

import { usePaciente } from './hooks/usePaciente';

import { BackChevron, Error, Loader } from '../../../../../components';

import styles from './PacienteHeader.module.css';

export const PacienteHeader = () => {

  let { id } = useParams();

  const location = useLocation();

  const navigate = useNavigate();

  const { pacienteQuery: { isLoading, isError, data: paciente } } = usePaciente(id);

  const handleInicio = () => {
    if(!location.pathname.includes('/paciente/view')){
        navigate(`/paciente/view/${id}`);
    }
  }

  const handleEditar = () => {
    if(!location.pathname.includes('/paciente/editar')){
        navigate(`/paciente/editar/${id}`);
    }
  }

  const handleConsulta = () => {
    if(!location.pathname.includes('/paciente/consulta')){
        navigate(`/paciente/consulta/${id}`);
    }
  }

  const handleCitas = () => {
    if(!location.pathname.includes('/paciente/citas')){
        navigate(`/paciente/citas/${id}`);
    }
  }

  const handleInmunuterapias = () => {
    if(!location.pathname.includes('/paciente/inmunoterapias')){
        navigate(`/paciente/inmunoterapias/${id}`);
    }
  }

  const handlePruebasAlergia = () => {
    if(!location.pathname.includes('/paciente/pruebas-alergia')){
        navigate(`/paciente/pruebas-alergia/${id}`);
    }
  }

  const handleAseguradoras = () => {
    if(!location.pathname.includes('/paciente/aseguradoras')){
        navigate(`/paciente/aseguradoras/${id}`);
    }
  }

  const handleAntecedentes = () => {
    if(!location.pathname.includes('/paciente/antecedentes')){
        navigate(`/paciente/antecedentes/${id}`);
    }
  }

  const handleEscalasControl = () => {
    if(!location.pathname.includes('/paciente/escalas-control')){
        navigate(`/paciente/escalas-control/${id}`);
    }
  }

  const handleHistorial = () => {
    if(!location.pathname.includes('/paciente/historial')){
        navigate(`/paciente/historial/${id}`);
    }
  }
  
  return (
    <>
      {(isLoading)
          ? <Loader />
          : <>
          {(isError)
              ? <Error />
              : <>
                <div className="row">
                  <div className="col">
                    <div className={styles.panel + " " + styles.panelNoHeader}>
                      <div className={styles.pacientesHeaderContainer}>
                        <div className="d-flex align-items-center">
                          <BackChevron />
                          <div className={styles.pacientesHeaderUserDataContainer}>
                            {(paciente.img != null) &&
                            <div>
                                <div className={styles.pacientesHeaderUserImg} style={{backgroundImage: 'url(\'' + ((paciente.img != null) ? paciente.img : 'https://placehold.co/200x200?text=Img') + '\')'}}></div>
                            </div>
                            }
                            <div>
                                <div className={styles.pacientesHeaderNoExpediente}># Expediente: { paciente._id }</div>
                                <div className="d-flex">
                                  <div>
                                    <div className={styles.pacientesHeaderNombre}>{ paciente.displayName }</div>
                                    <div className="d-flex justify-content-between">
                                      <div className={styles.pacientesHeaderEdad}>{ paciente.edad }</div>
                                      <span className={"badge ms-3 " + styles.badge} style={{backgroundColor: paciente.appStatusColor}}>{ paciente.appStatusString }</span>
                                    </div>
                                  </div>
                                  <div>
                                    <div className={styles.pacientesHeaderNombre}>
                                      <Icon.PencilSquare className={styles.pacientesHeaderEditBtn} onClick={handleEditar} />
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.pacienteHeaderTabsContainer}>
                          <div className={styles.pacientesHeaderTabContainer} onClick={handleInicio}>
                            <div className='centrar'>
                              <div className={styles.pacientesHeaderTabIcon + " " + ((location.pathname.includes('/paciente/view')) ? styles.pacientesHeaderTabIconInicioActive : styles.pacientesHeaderTabIconInicio )}></div>
                            </div>
                            <div className={((location.pathname.includes('/paciente/view')) ? styles.pacientesHeaderTabTextActive : styles.pacientesHeaderTabText )}>
                              <div>Inicio</div>
                              <div>&nbsp;</div>
                            </div>
                          </div>
                          <div className={styles.pacientesHeaderTabContainer} onClick={handleConsulta}>
                            <div className='centrar'>
                              <div className={styles.pacientesHeaderTabIcon + " " + ((location.pathname.includes('/paciente/consulta')) ? styles.pacientesHeaderTabIconConsultaActive : styles.pacientesHeaderTabIconConsulta )}></div>
                            </div>
                            <div className={((location.pathname.includes('/paciente/consulta')) ? styles.pacientesHeaderTabTextActive : styles.pacientesHeaderTabText )}>
                              <div>Consulta</div>
                              <div>&nbsp;</div>
                            </div>
                          </div>
                          <div className={styles.pacientesHeaderTabContainer} onClick={handleCitas}>
                            <div className='centrar'>
                              <div className={styles.pacientesHeaderTabIcon + " " + ((location.pathname.includes('/paciente/citas')) ? styles.pacientesHeaderTabIconCitasActive : styles.pacientesHeaderTabIconCitas )}></div>
                            </div>
                            <div className={((location.pathname.includes('/paciente/citas')) ? styles.pacientesHeaderTabTextActive : styles.pacientesHeaderTabText )}>
                              <div>Citas</div>
                              <div>&nbsp;</div>
                            </div>
                          </div>
                          <div className={styles.pacientesHeaderTabContainer} onClick={handleInmunuterapias}>
                            <div className='centrar'>
                              <div className={styles.pacientesHeaderTabIcon + " " + ((location.pathname.includes('/paciente/inmunoterapias')) ? styles.pacientesHeaderTabIconInmunoterapiasActive : styles.pacientesHeaderTabIconInmunoterapias )}></div>
                            </div>
                            <div className={((location.pathname.includes('/paciente/inmunoterapias')) ? styles.pacientesHeaderTabTextActive : styles.pacientesHeaderTabText )}>
                              <div>Inmunoterapias</div>
                              <div>&nbsp;</div>
                            </div>
                          </div>
                          <div className={styles.pacientesHeaderTabContainer} onClick={handlePruebasAlergia}>
                            <div className='centrar'>
                              <div className={styles.pacientesHeaderTabIcon + " " + ((location.pathname.includes('/paciente/pruebas-alergia')) ? styles.pacientesHeaderTabIconPruebasAlergiaActive : styles.pacientesHeaderTabIconPruebasAlergia )}></div>
                            </div>
                            <div className={((location.pathname.includes('/paciente/pruebas-alergia')) ? styles.pacientesHeaderTabTextActive : styles.pacientesHeaderTabText )}>
                              <div>Pruebas de</div>
                              <div>Alergias</div>
                            </div>
                          </div>
                          {(!(location.pathname.includes('/paciente/antecedentes') || location.pathname.includes('/paciente/escalas-control') || location.pathname.includes('/paciente/historial'))) &&
                          <div className={styles.pacientesHeaderTabContainer} onClick={handleAseguradoras}>
                            <div className='centrar'>
                              <div className={styles.pacientesHeaderTabIcon + " " + ((location.pathname.includes('/paciente/aseguradoras')) ? styles.pacientesHeaderTabIconAseguradorasActive : styles.pacientesHeaderTabIconAseguradoras )}></div>
                            </div>
                            <div className={((location.pathname.includes('/paciente/aseguradoras')) ? styles.pacientesHeaderTabTextActive : styles.pacientesHeaderTabText )}>
                              <div>Aseguradoras</div>
                              <div>&nbsp;</div>
                            </div>
                          </div>
                          }
                          {(location.pathname.includes('/paciente/antecedentes')) &&
                          <div className={styles.pacientesHeaderTabContainer} onClick={handleAntecedentes}>
                            <div className='centrar'>
                              <div className={styles.pacientesHeaderTabIcon + " " + styles.pacientesHeaderTabIconAntecedentesActive}></div>
                            </div>
                            <div className={styles.pacientesHeaderTabTextActive}>
                              <div>Antecedentes</div>
                              <div>&nbsp;</div>
                            </div>
                          </div>
                          }
                          {(location.pathname.includes('/paciente/escalas-control')) &&
                          <div className={styles.pacientesHeaderTabContainer} onClick={handleEscalasControl}>
                            <div className='centrar'>
                              <div className={styles.pacientesHeaderTabIcon + " " + styles.pacientesHeaderTabIconEscalasControlActive }></div>
                            </div>
                            <div className={styles.pacientesHeaderTabTextActive}>
                              <div>Escalas de</div>
                              <div>control</div>
                            </div>
                          </div>
                          }
                          {(location.pathname.includes('/paciente/historial')) &&
                          <div className={styles.pacientesHeaderTabContainer} onClick={handleHistorial}>
                            <div className='centrar'>
                              <div className={styles.pacientesHeaderTabIcon + " " + styles.pacientesHeaderTabIconHistorialActive}></div>
                            </div>
                            <div className={styles.pacientesHeaderTabTextActive}>
                              <div>Historial</div>
                              <div>&nbsp;</div>
                            </div>
                          </div>
                          }
                          <div className={styles.pacientesHeaderTabContainer}>
                            <div className='centrar'>
                              <div className="dropdown">
                                <div role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  <div className={styles.pacientesHeaderTabIcon + " " + styles.pacientesHeaderTabIconOpciones}></div>
                                </div>
                                <ul className="dropdown-menu">
                                  {(location.pathname.includes('/paciente/antecedentes') || location.pathname.includes('/paciente/escalas-control') || location.pathname.includes('/paciente/historial')) &&
                                  <li className={styles.pacientesHeaderDropdownItem} onClick={handleAseguradoras}>Aseguradoras</li>
                                  }
                                  {(!location.pathname.includes('/paciente/antecedentes')) &&
                                  <li className={styles.pacientesHeaderDropdownItem} onClick={handleAntecedentes}>Antecedentes</li>
                                  }
                                  {(!location.pathname.includes('/paciente/escalas-control')) &&
                                  <li className={styles.pacientesHeaderDropdownItem} onClick={handleEscalasControl}>Escalas de control</li>
                                  }
                                  {(!location.pathname.includes('/paciente/historial')) &&
                                  <li className={styles.pacientesHeaderDropdownItem} onClick={handleHistorial}>Historial</li>
                                  }
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Outlet />
                    </div>
                  </div>
                </div>
              </>
          }
          </>
      }
    </>
  )
}