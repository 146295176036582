import React, { useState } from 'react'
import { useLoaderData } from 'react-router-dom';

import { usePacienteCuestionarios } from './hooks/usePacienteCuestionarios';
import { usePacienteCuestionariosPasados } from './hooks/usePacienteCuestionariosPasados';
import { usePacienteCuestionariosStatusToggle } from './hooks/usePacienteCuestionariosStatusToggle';

import { IconButton, ModalContainer, SwitchInput } from '../../components';
import { DetalleCuestionarioModal } from '../Modals/DetalleCuestionario';
import { GraficaCuestionarioModal } from '../Modals/GraficaCuestionario';

import styles from './PacienteEscalasControl.module.css';

export async function loader({ params }) {
  return params.id;
}

export const PacienteEscalasControl = () => {

  const id = useLoaderData();

  const { pacienteCuestionariosQuery: { isLoading, isFetching, isError, data: pacienteCuestionarios } } = usePacienteCuestionarios(id);

  const { pacienteCuestionariosPasadosQuery: { isLoading: isLoadingPasados, isError: isErrorPasados, data: pacienteCuestionariosPasados } } = usePacienteCuestionariosPasados(id);

  const { toggleCuestionarioStatusMutation: { mutate: toggleCuestionarioStatus } } = usePacienteCuestionariosStatusToggle(id);

  const [statusDetallesCuestionarioModal, setStatusDetallesCuestionarioModal] = useState(false);
  const [statusGraficaCuestionarioModal, setStatusGraficaCuestionarioModal] = useState(false);

  const [cuestionarioActivo, setCuestionarioActivo] = useState({
    // numero: 0,
    // nombre: '',
  });

  const handleViewCuestionario = (numero, nombre) => {
    let pasados = {};
    switch (numero) {
      case 1:
        //Rinitis
        pasados = (typeof pacienteCuestionariosPasados.rinitis !== 'undefined') ? pacienteCuestionariosPasados.rinitis : {};
        break;
      case 2:
        //Urticaria
        pasados = (typeof pacienteCuestionariosPasados.urticaria !== 'undefined') ? pacienteCuestionariosPasados.urticaria : {};
        break;
      case 3:
        //Asma
        pasados = (typeof pacienteCuestionariosPasados.asma !== 'undefined') ? pacienteCuestionariosPasados.asma : {};
        break;
      case 4:
        //Poem
        pasados = (typeof pacienteCuestionariosPasados.poem !== 'undefined') ? pacienteCuestionariosPasados.poem : {};
        break;
    
      default:
        break;
    }
    setCuestionarioActivo({ numero, nombre, pasados });
    setStatusDetallesCuestionarioModal(true);
  }

  const hideDetallesCuestionarioModal = () => {
    setCuestionarioActivo({ });
    setStatusDetallesCuestionarioModal(false);
  }

  const handleGraficaCuestionario = (numero, nombre) => {
    let pasados = {};
    switch (numero) {
      case 1:
        //Rinitis
        pasados = (typeof pacienteCuestionariosPasados.rinitis !== 'undefined') ? pacienteCuestionariosPasados.rinitis : {};
        break;
      case 2:
        //Urticaria
        pasados = (typeof pacienteCuestionariosPasados.urticaria !== 'undefined') ? pacienteCuestionariosPasados.urticaria : {};
        break;
      case 3:
        //Asma
        pasados = (typeof pacienteCuestionariosPasados.asma !== 'undefined') ? pacienteCuestionariosPasados.asma : {};
        break;
      case 4:
        //Poem
        pasados = (typeof pacienteCuestionariosPasados.poem !== 'undefined') ? pacienteCuestionariosPasados.poem : {};
        break;
    
      default:
        break;
    }
    setCuestionarioActivo({ numero, nombre, pasados });
    setStatusGraficaCuestionarioModal(true);
  }

  const hideGraficaCuestionarioModal = () => {
    setCuestionarioActivo({ });
    setStatusGraficaCuestionarioModal(false);
  }

  const handleAsmaStatusChange = (e) => {
    toggleCuestionarioStatus({id, noCuestionario: 3, status: e.target.value});
  }

  const handleRinitisStatusChange = (e) => {
    toggleCuestionarioStatus({id, noCuestionario: 1, status: e.target.value});
  }

  const handlePoemStatusChange = (e) => {
    toggleCuestionarioStatus({id, noCuestionario: 4, status: e.target.value});
  }

  const handleUrticariaStatusChange = (e) => {
    toggleCuestionarioStatus({id, noCuestionario: 2, status: e.target.value});
  }

  return (
    <>
      <div className="row mt-3">
        <div className="col">
          <div className={styles.header}>Escalas de control</div>
        </div>
      </div>
      {(isLoading)
      ? <>Cargando...</>
      : <>
        {(isError)
        ? <>Error</>
        : <>
          <div className="row">
            <div className="col">
              <div className="table-responsive">
                <table className="table align-middle">
                  <thead>
                    <tr>
                      <th scope="col">Escala</th>
                      <th scope="col">Próximo registro</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                  { (isFetching)
                  ? <tr><td colSpan={6} className='text-center'>Recuperando información...</td></tr>
                  : <>
                    <tr>
                      <td>
                        <div>
                          <SwitchInput
                            checked={pacienteCuestionarios.asma.activo}
                            label='Asma'
                            labelPosition='end'
                            name='asmaSwitchInput'
                            onChange={handleAsmaStatusChange}
                          />
                        </div>
                      </td>
                      <td>{ (pacienteCuestionarios.asma.activo) ? pacienteCuestionarios.asma.siguienteCuestionarioFormato : '' }</td>
                      <td>
                        <div className='d-flex flex-row-reverse'>
                          <IconButton
                            icon='EyeFill'
                            iconColor='#666666'
                            marginTop={false}
                            onClick={() => handleViewCuestionario(3, 'Asma')}
                            type='button'
                            size={20}
                          />
                          <IconButton
                            icon='BarChartLineFill'
                            iconColor='#666666'
                            marginTop={false}
                            onClick={() => handleGraficaCuestionario(3, 'Asma')}
                            type='button'
                            size={20}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <SwitchInput
                            checked={pacienteCuestionarios.rinitis.activo}
                            label='Rinitis Alérgica'
                            labelPosition='end'
                            name='rinitisSwitchInput'
                            onChange={handleRinitisStatusChange}
                          />
                        </div>
                      </td>
                      <td>{ (pacienteCuestionarios.rinitis.activo) ? pacienteCuestionarios.rinitis.siguienteCuestionarioFormato : '' }</td>
                      <td>
                        <div className='d-flex flex-row-reverse'>
                          <IconButton
                            icon='EyeFill'
                            iconColor='#666666'
                            marginTop={false}
                            onClick={() => handleViewCuestionario(1, 'Rinitis Alérgica')}
                            type='button'
                            size={20}
                          />
                          <IconButton
                            icon='BarChartLineFill'
                            iconColor='#666666'
                            marginTop={false}
                            onClick={() => handleGraficaCuestionario(1, 'Rinitis Alérgica')}
                            type='button'
                            size={20}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <SwitchInput
                            checked={pacienteCuestionarios.poem.activo}
                            label='Dermatitis Atópica'
                            labelPosition='end'
                            name='poemSwitchInput'
                            onChange={handlePoemStatusChange}
                          />
                        </div>
                      </td>
                      <td>{ (pacienteCuestionarios.poem.activo) ? pacienteCuestionarios.poem.siguienteCuestionarioFormato : '' }</td>
                      <td>
                        <div className='d-flex flex-row-reverse'>
                          <IconButton
                            icon='EyeFill'
                            iconColor='#666666'
                            marginTop={false}
                            onClick={() => handleViewCuestionario(4, 'Dermatitis Atópica')}
                            type='button'
                            size={20}
                          />
                          <IconButton
                            icon='BarChartLineFill'
                            iconColor='#666666'
                            marginTop={false}
                            onClick={() => handleGraficaCuestionario(4, 'Dermatitis Atópica')}
                            type='button'
                            size={20}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <SwitchInput
                            checked={pacienteCuestionarios.urticaria.activo}
                            label='Urticaria Crónica'
                            labelPosition='end'
                            name='urticariaSwitchInput'
                            onChange={handleUrticariaStatusChange}
                          />
                        </div>
                      </td>
                      <td>{ (pacienteCuestionarios.urticaria.activo) ? pacienteCuestionarios.urticaria.siguienteCuestionarioFormato : '' }</td>
                      <td>
                        <div className='d-flex flex-row-reverse'>
                          <IconButton
                            icon='EyeFill'
                            iconColor='#666666'
                            marginTop={false}
                            onClick={() => handleViewCuestionario(2, 'Urticaria Crónica')}
                            type='button'
                            size={20}
                          />
                          <IconButton
                            icon='BarChartLineFill'
                            iconColor='#666666'
                            marginTop={false}
                            onClick={() => handleGraficaCuestionario(2, 'Urticaria Crónica')}
                            type='button'
                            size={20}
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {(isLoadingPasados)
          ? <>Cargando...</>
          : <>
            {(isErrorPasados)
            ? <>Error</>
            : <>
              {(typeof cuestionarioActivo.numero !== 'undefined') &&
              <>
                {(statusDetallesCuestionarioModal) &&
                <ModalContainer
                  state={ statusDetallesCuestionarioModal }
                  hide={ hideDetallesCuestionarioModal }
                  content={ <DetalleCuestionarioModal paciente={id} cuestionario={cuestionarioActivo} hide={ hideDetallesCuestionarioModal } /> }
                />
                }
                {(statusGraficaCuestionarioModal) &&
                <ModalContainer
                  state={ statusGraficaCuestionarioModal }
                  hide={ hideGraficaCuestionarioModal }
                  content={ <GraficaCuestionarioModal paciente={id} cuestionario={cuestionarioActivo} hide={ hideGraficaCuestionarioModal } /> }
                />
                }
              </>
              }
            </>
            }
          </>
          }
        </>
        }
      </>
      }
    </>
  )
}
