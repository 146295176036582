import PropTypes from 'prop-types';

import { ActivoPreview, ColorInputPreview, ImgCircledPreviewInput, PrimaryButton, PrincipalPreview, TextInputPreview } from '../../../components';

export const ConsultorioPreview = ({ consultorio, handleEditarConsultorio }) => {
  return (
    <>
        <div className="row">
            {(consultorio.img !== null) &&
            <div className="col-md-12 col-lg-2">
                <ImgCircledPreviewInput
                    imgText="Logo"
                    label="Logotipo"
                    name='img'
                    value={consultorio.img}
                />
            </div>
            }
            <div className="col-md-12 col-lg">
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <TextInputPreview
                            label="Nombre del consultorio"
                            name="nombre"
                            value={ consultorio.nombre }
                        />
                    </div>
                    <div className="col-sm-12 col-md">
                        <PrincipalPreview status={ consultorio.principal } />
                    </div>
                    <div className="col-sm-12 col-md">
                        <ActivoPreview status={ consultorio.activo } text='Consultorio activo' />
                    </div>
                    <div className="col-sm-12 col-md">
                        <ColorInputPreview
                            label="Color de tema"
                            name="color"
                            value={ consultorio.color }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Dirección"
                            name="direccion"
                            value={ consultorio.direccion }
                        />
                    </div>
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Información adicional"
                            name="info"
                            value={ (consultorio.info !== '' && consultorio.info !== undefined && consultorio.info !== null) ? consultorio.info : '-' }
                        />
                    </div>
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Colonia"
                            name="colonia"
                            value={ consultorio.colonia }
                        />
                    </div>
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Municipio"
                            name="municipio"
                            value={ consultorio.municipio }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Entidad federativa"
                            name="entidadFederativa"
                            value={ consultorio.entidadFederativa }
                        />
                    </div>
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="País"
                            name="pais"
                            value={ consultorio.pais }
                        />
                    </div>
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Código Postal"
                            name="codigoPostal"
                            value={ consultorio.codigoPostal }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Correo electrónico"
                            name="email"
                            value={ consultorio.email }
                        />
                    </div>
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Teléfono Fijo"
                            name="telefono"
                            value={ (consultorio.telefono !== '' && consultorio.telefono !== undefined && consultorio.telefono !== null) ? consultorio.telefono : '-' }
                        />
                    </div>
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Teléfono Móvil"
                            name="celular"
                            value={ consultorio.celular }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md">
                        <div className="d-flex justify-content-start">
                            <PrimaryButton
                                inverse={true}
                                marginRight={true}
                                onClick={handleEditarConsultorio}
                                text="Editar Consultorio"
                                type="button"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

ConsultorioPreview.propTypes = {
    consultorio: PropTypes.object.isRequired,
    handleEditarConsultorio: PropTypes.func.isRequired,
}