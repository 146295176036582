import { DatosGenerales } from './components/DatosGenerales';
import { Contraseña } from './components/Contraseña';
import { Cedulas } from './components/Cedulas';
import { Error, Loader } from '../../components';

import { useDatosGenerales } from './hooks/useDatosGenerales';

import styles from './Perfil.module.css';

export const Perfil = () => {

  const { datosGeneralesQuery: { isLoading, isError, data: usuario } } = useDatosGenerales();

  return (
    <>
      <div className={styles.header}>Perfil</div>
      {(isLoading)
        ? <Loader />
        : <>
          {(isError)
            ? <Error />
            : <>
              <div className={styles.panel}>
                <div className='row'>
                  <div className="col">
                    <div className={styles.panelSection}>
                      <DatosGenerales usuario={usuario} />
                    </div>
                    <div className={styles.panelSection}>
                      <div className="row">
                        {(usuario.img !== null)
                        ? <div className="offset-md-0 col-md-12 offset-lg-2 col-lg-10">
                          <Contraseña />
                        </div>
                        : <div className="col">
                          <Contraseña />
                        </div>
                        }
                      </div>
                    </div>
                    <div className={styles.panelSection}>
                      <div className="row">
                        {(usuario.img !== null)
                        ? <div className="offset-md-0 col-md-12 offset-lg-2 col-lg-10">
                          <Cedulas />
                        </div>
                        : <div className="col">
                          <Cedulas />
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </>
      }
    </>
  )
}
