import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'

import { ArchivoForm } from './components/Archivo';

export const MultiFormArchivos = ({ formsStatus='checked', name, onFormsChange, reset, setFormValidation, value }) => {

    const [elements, setElements] = useState((typeof value !== 'undefined') ? value.map(val => ({ id: val.id, archivo: val.archivo, validation: true })) : [])

    const handleAddElement = () => {
        setElements([...elements, { id: moment().unix(), archivo: null, validation: true }]);
    }

    const handleRemoveElement = (id) => {
        setElements(elements.filter(element => element.id !== id));
    }

    const handleSetElementData = (data) => {
        setElements(elements.map(element => {
            if(element.id === data.id){
                return data;
            }else{
                return element;
            }
        }));
    }

    useEffect(() => {
        onFormsChange({ target: { name: name, value: elements.map(element => ({
            archivo: element.archivo,
        })) } });
        let errorElements = elements.filter(element => (!element.validation));
        if(setFormValidation) setFormValidation(name, (errorElements.length === 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements])

    useEffect(() => {
        setElements([{ id: moment().unix(), archivo: null, validation: true }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset])

    return (
        <>
            {elements.map((element, index) => <ArchivoForm
                key={element.id}
                addElement={handleAddElement}
                element={element}
                last={(index === elements.length - 1)}
                removeElement={handleRemoveElement}
                onFormChange={handleSetElementData}
                formStatus={formsStatus}
            />)}
        </>
    )
}

MultiFormArchivos.propTypes = {
    formsStatus: PropTypes.oneOf(['checked', 'checking']),
    name: PropTypes.string.isRequired,
    onFormsChange: PropTypes.func.isRequired,
    reset: PropTypes.number,
    setFormValidation: PropTypes.func,
    value: PropTypes.array
}