import React from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'react-bootstrap-icons';

import styles from './PaginationButton.module.css';

export const PaginationButton = ({ disabled = false, onClick, type }) => {

  return (
    <button type="button" className={"btn " + styles.button + ((type === "prev") ? " " + styles.mr : " " + styles.ml) + ((disabled) ? " " + styles.btnDisabled : "")} onClick={onClick} disabled={disabled}>
      {(type === "prev")
      ? <Icon.ChevronLeft />
      : <Icon.ChevronRight />
      }
    </button>
  )
}

PaginationButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['prev', 'next']).isRequired,
}