import React from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const MultiTextInputPreview = ({ borders = true, label, name, values}) => {
  
  return (
    <>
        <div className={styles.customMultiPreviewContainer}>
            <label htmlFor={ name } className={(borders) ? styles.customMultiPreviewLabel : styles.customMultiPreviewLabelNoBorder}>{ label }</label>
            {values.map(value => <div key={value._id} className={(borders) ? styles.customMultiPreview : styles.customMultiPreviewNoBorder}>{ value.nombre }</div>)}
        </div>
    </>
  )
}

MultiTextInputPreview.propTypes = {
  borders: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
}