import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from "react-router-dom";

import { LoginLayout, PublicLayout, SignupLayout } from "../pages/Layout";
import {
  Condiciones,
  EmailConfirmation,
  EmailConfirmationTokenLoader,
  EmailInactivation,
  EmailInactivationTokenLoader,
  Error,
  Login,
  PasswordReset,
  PasswordResetTokenLoader,
  Politicas,
  Signup,
} from "../pages";

const router = createBrowserRouter([
  {
    path: "",
  //   element: <Root/>,
    errorElement: <Error />,
    children: [
      {
        path: "",
        element: <LoginLayout />,
        children: [
          {
            path: "",
            element: <Login />,
          }
        ]
      },
      {
        path: "",
        element: <SignupLayout />,
        children: [
          {
            path: "/signup",
            element: <Signup />,
          }
        ]
      },
      {
        path: "",
        element: <PublicLayout />,
        children: [
          {
            path: "/politicas",
            element: <Politicas />,
          },
          {
            path: "/condiciones",
            element: <Condiciones />,
          },
          {
            path: "/email-confirmation/:token",
            element: <EmailConfirmation />,
            loader: EmailConfirmationTokenLoader,
          },
          {
            path: "/password-reset/:token",
            element: <PasswordReset />,
            loader: PasswordResetTokenLoader,
          },
          {
            path: "/email-inactivation/:token",
            element: <EmailInactivation />,
            loader: EmailInactivationTokenLoader,
          }
        ]
      },
      {
        path: "/*",
        element: <Navigate to="" />,
      }
    ],
  },
]);

export const PublicRouter = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}
