import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query"

import { getPacientes } from "../services/pacientes.service";

export const usePacientes = () => {

    const { pacienteSearch } = useSelector(state => state.ui);

    const pacientesQuery = useQuery({
        queryKey: ['pacientesSearchNavbar', { search: pacienteSearch }],
        queryFn: () => getPacientes(pacienteSearch),
        staleTime: 1000 * 60, // 1 min
        placeholderData: {
            pacientes: [],
        }
    });

    return {
        pacientesQuery,
    }
}