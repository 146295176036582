import PropTypes from 'prop-types';
import { PrimaryButton } from '../../../components';

export const ContraseñaPreview = ({ handleEditarContraseña }) => {
  return (
    <PrimaryButton
        inverse={true}
        onClick={handleEditarContraseña}
        text="Cambiar Contraseña"
        type="button"
    />
  )
}

ContraseñaPreview.propTypes = {
    handleEditarContraseña: PropTypes.func.isRequired
}