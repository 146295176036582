import React, { useState } from 'react'
import PropTypes from 'prop-types';

import { useAlergoide } from '../hooks/useAlergoide';
import { useForm, useFormValidation } from '../../../../../hooks';

import { CheckboxInput, LightButton, PrimaryButton } from "../../../../../components";

import styles from './../Alergoide.module.css';

export const AlergoideUpdate = ({ alergoideAux }) => {

    const { updateAlergoideMutation: { mutate: updateAlergoide, isPending } } = useAlergoide();

    const [dataStatus, setDataStatus] = useState(false);

    const { alergoide, sitioAplicacion, ladoAplicacion, reaccion, sitioAplicacionBrazo, sitioAplicacionAbdomen, sitioAplicacionOtro, ladoAplicacionDerecho, ladoAplicacionIzquierdo, onInputChange, onResetForm } = useForm({ alergoide: alergoideAux.inmunoterapias.alergoide, sitioAplicacion: alergoideAux.inmunoterapias.sitioAplicacion, ladoAplicacion: alergoideAux.inmunoterapias.ladoAplicacion, reaccion: alergoideAux.inmunoterapias.reaccion, sitioAplicacionBrazo: alergoideAux.inmunoterapias.sitioAplicacionBrazo, sitioAplicacionAbdomen: alergoideAux.inmunoterapias.sitioAplicacionAbdomen, sitioAplicacionOtro: alergoideAux.inmunoterapias.sitioAplicacionOtro, ladoAplicacionDerecho: alergoideAux.inmunoterapias.ladoAplicacionDerecho, ladoAplicacionIzquierdo: alergoideAux.inmunoterapias.ladoAplicacionIzquierdo });

    const { isFormValid, formValidationStatus, startFormValidation } = useFormValidation([{field: 'alergoide', default: true}, {field: 'sitioAplicacion', default: true}, {field: 'ladoAplicacion', default: true}, {field: 'reaccion', default: true}, {field: 'sitioAplicacionBrazo', default: true}, {field: 'sitioAplicacionAbdomen', default: true}, {field: 'sitioAplicacionOtro', default: true}, {field: 'ladoAplicacionDerecho', default: true}, {field: 'ladoAplicacionIzquierdo', default: true}]);

    const handleChange = (e) => {
        setDataStatus(true);
        onInputChange(e);
    }

    const handleCancelChange = () => {
        onResetForm();
        setDataStatus(false);
    }

    const onSubmit = async ( event ) => {
        event.preventDefault();
        
        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            updateAlergoide({ data: { alergoide, sitioAplicacion, ladoAplicacion, reaccion, sitioAplicacionBrazo, sitioAplicacionAbdomen, sitioAplicacionOtro, ladoAplicacionDerecho, ladoAplicacionIzquierdo } });
            setDataStatus(false);
        }else{
          startFormValidation();
        }
    
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className={styles.panel}>
                    <div className={styles.subHeader}>Inmunoterapias</div>
                    <div className="row mb-3">
                        <div className="col">
                            <CheckboxInput
                                label="Alergoide"
                                name="alergoide"
                                onChange={ handleChange }
                                required={false}
                                checked={ (alergoide === 'true' || alergoide === true) ? true : false }
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col">
                            <CheckboxInput
                                label="Sitio de aplicación"
                                name="sitioAplicacion"
                                onChange={ handleChange }
                                required={false}
                                checked={ (sitioAplicacion === 'true' || sitioAplicacion === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            
                        </div>
                        <div className="col">

                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="Lado de aplicación"
                                name="ladoAplicacion"
                                onChange={ handleChange }
                                required={false}
                                checked={ (ladoAplicacion === 'true' || ladoAplicacion === true) ? true : false }
                            />
                        </div>
                        <div className="col">
                            
                        </div>
                        <div className="col">
                            <CheckboxInput
                                label="Reacción"
                                name="reaccion"
                                onChange={ handleChange }
                                required={false}
                                checked={ (reaccion === 'true' || reaccion === true) ? true : false }
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <div className="px-3">
                                <CheckboxInput
                                    label="Brazo"
                                    name="sitioAplicacionBrazo"
                                    onChange={ handleChange }
                                    required={false}
                                    checked={ (sitioAplicacionBrazo === 'true' || sitioAplicacionBrazo === true) ? true : false }
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="px-3">
                                <CheckboxInput
                                    label="Abdomen"
                                    name="sitioAplicacionAbdomen"
                                    onChange={ handleChange }
                                    required={false}
                                    checked={ (sitioAplicacionAbdomen === 'true' || sitioAplicacionAbdomen === true) ? true : false }
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="px-3">
                                <CheckboxInput
                                    label="Otro"
                                    name="sitioAplicacionOtro"
                                    onChange={ handleChange }
                                    required={false}
                                    checked={ (sitioAplicacionOtro === 'true' || sitioAplicacionOtro === true) ? true : false }
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="px-3">
                                <CheckboxInput
                                    label="Derecho"
                                    name="ladoAplicacionDerecho"
                                    onChange={ handleChange }
                                    required={false}
                                    checked={ (ladoAplicacionDerecho === 'true' || ladoAplicacionDerecho === true) ? true : false }
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="px-3">
                                <CheckboxInput
                                    label="Izquierdo"
                                    name="ladoAplicacionIzquierdo"
                                    onChange={ handleChange }
                                    required={false}
                                    checked={ (ladoAplicacionIzquierdo === 'true' || ladoAplicacionIzquierdo === true) ? true : false }
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="px-3">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12 col-md">
                        <div className='d-flex justify-content-end'>
                            <LightButton
                                disabled={isPending || formValidationStatus === 'checking' || !dataStatus}
                                icon="X"
                                marginTop={false}
                                marginRight={true}
                                onClick={handleCancelChange}
                                text="Cancelar"
                                type="button"
                            />
                            <PrimaryButton
                                disabled={isPending || formValidationStatus === 'checking' || !dataStatus}
                                icon="Check"
                                marginTop={false}
                                text="Guardar"
                                type="submit"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

AlergoideUpdate.propTypes = {
    alergoideAux: PropTypes.object.isRequired,
}