import { inmunetApi } from '../../../../api';

export const createCita = async({ data: cita }) => {

    try {

        let formData = new FormData();
        Object.keys(cita).forEach(field => {
            formData.append(field, cita[field]);
        });

        const { data } = await inmunetApi.post(`/cita`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al crear cita';

        return {
            inactivoFlag: false,
            horarioFlag: false,
            empalmadaFlag: false,
            cita: data.cita
        };

    } catch (error) {

        if (error.response.data.inactivoFlag) {
            return {
                inactivoFlag: true,
                horarioFlag: false,
                empalmadaFlag: false,
                cita: error.response.data.cita
            };
        } else if (error.response.data.horarioFlag) {
            return {
                inactivoFlag: false,
                horarioFlag: true,
                empalmadaFlag: false,
                cita: error.response.data.cita
            };
        } else if (error.response.data.empalmadaFlag) {
            return {
                inactivoFlag: false,
                horarioFlag: false,
                empalmadaFlag: true,
                cita: error.response.data.cita
            };
        } else {
            if (error.response.data.msg) {
                throw new Error(error.response.data.msg);
            } else {
                throw new Error(error);
            }
        }

    }

};

export const updateCita = async({ id, data: cita }) => {

    try {

        let formData = new FormData();
        Object.keys(cita).forEach(field => {
            formData.append(field, cita[field]);
        });

        const { data } = await inmunetApi.put(`/cita/${ id }`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al editar cita';

        return {
            inactivoFlag: false,
            horarioFlag: false,
            empalmadaFlag: false,
            cita: data.cita
        };

    } catch (error) {

        if (error.response.data.inactivoFlag) {
            return {
                inactivoFlag: true,
                horarioFlag: false,
                empalmadaFlag: false,
                cita: error.response.data.cita
            };
        } else if (error.response.data.horarioFlag) {
            return {
                inactivoFlag: false,
                horarioFlag: true,
                empalmadaFlag: false,
                cita: error.response.data.cita
            };
        } else if (error.response.data.empalmadaFlag) {
            return {
                inactivoFlag: false,
                horarioFlag: false,
                empalmadaFlag: true,
                cita: error.response.data.cita
            };
        } else {
            if (error.response.data.msg) {
                throw new Error(error.response.data.msg);
            } else {
                throw new Error(error);
            }
        }

    }

};

export const posponerCita = async({ id, data: cita }) => {

    try {

        let formData = new FormData();
        Object.keys(cita).forEach(field => {
            formData.append(field, cita[field]);
        });

        const { data } = await inmunetApi.put(`/cita/posponer/${ id }`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al posponer cita';

        return {
            inactivoFlag: false,
            horarioFlag: false,
            empalmadaFlag: false,
            cita: data.cita
        };

    } catch (error) {

        if (error.response.data.inactivoFlag) {
            return {
                inactivoFlag: true,
                horarioFlag: false,
                empalmadaFlag: false,
                cita: error.response.data.cita
            };
        } else if (error.response.data.horarioFlag) {
            return {
                inactivoFlag: false,
                horarioFlag: true,
                empalmadaFlag: false,
                cita: error.response.data.cita
            };
        } else if (error.response.data.empalmadaFlag) {
            return {
                inactivoFlag: false,
                horarioFlag: false,
                empalmadaFlag: true,
                cita: error.response.data.cita
            };
        } else {
            if (error.response.data.msg) {
                throw new Error(error.response.data.msg);
            } else {
                throw new Error(error);
            }
        }

    }

};