import PropTypes from 'prop-types';

import { PrimaryButton, ReactSelectInputPreview } from '../../../components';

export const ConsultoriosPreview = ({ asistente, handleEditarConsultorios }) => {
  return (
    <>
        <div>
            {(asistente.consultorios.length > 0)
            ? <>
                <ReactSelectInputPreview name="consultorios" values={asistente.consultorios.map(consultorio => consultorio.nombre)} />
            </>
            : <>Sin consultorios asignados</>
            }
        </div>
        <PrimaryButton
            inverse={true}
            onClick={handleEditarConsultorios}
            text="Cambiar Consultorios"
            type="button"
        />
    </>
  )
}

ConsultoriosPreview.propTypes = {
    asistente: PropTypes.object.isRequired,
    handleEditarConsultorios: PropTypes.func.isRequired
}