import { useEffect, useState } from 'react';
import { Link, useLoaderData } from 'react-router-dom';

import { inmunetApi } from '../../api';

import styles from './EmailInactivation.module.css';

export async function loader({ params }) {
    return params.token;
}

export const EmailInactivation = () => {

    const token = useLoaderData();

    const [state, setState] = useState({ loading: true, status: null, errorMsg: '' });

    let { loading, status } = state;
    
    useEffect(() => {
        async function fetchData() {
            localStorage.setItem('token', token);
            try {
                let res = await inmunetApi.post('/usuario/email-inactivation');
                localStorage.removeItem('token');
                setState({ loading: false, status: res.data.status, errorMsg: '' });
            } catch (error) {
                localStorage.removeItem('token');
                if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.msg !== 'undefined') {
                    
                    setState({ loading: false, status: error.response.data.status, errorMsg: error.response.data.msg });
                    
                } else {
                    
                    setState({ loading: false, status: error.response.data.status, errorMsg: 'Error al procesar la petición, intenta nuevamente.' });
    
                }
            }
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={styles.logoContainer}>
                <div className={styles.logo}></div>
            </div>
            {loading 
            ? (
            <div className='alert alert-info text-center'>Cancelando servicio...</div>
            )
            : (
            !status
            ? (
                <>
                    <div className='alert alert-danger text-center'>Servicio no cancelado! Intenta nuevamente, si el problema persiste contacta con el equipo de soporte.</div>
                </>
            )
            : (
                <>
                    <div className='alert alert-success text-center'>¡El servicio ha sido cancelado, no recibirás más notificaciones por correo. <Link to="/">Ingresar a la aplicación</Link></div>
                </>
            )
            )
        }
        </>
    )
}
