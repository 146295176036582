import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { getAsistentes, createAsistente, updateAsistente } from "../services/asistentes.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const useAsistentes = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const asistentesQuery = useQuery({
        queryKey: ['asistentes'],
        queryFn: getAsistentes,
        staleTime: 0, // nulo
    });

    const createAsistenteMutation = useMutation({
        mutationFn: createAsistente,
        onSuccess: (asistente, variables, context) => {
            queryClient.setQueryData(['asistentes'], oldData => {
                return [
                    ...oldData,
                    asistente
                ];
            });
            variables.callback();
            dispatch(successNotification('Asistente creado'));
        },
        onError: (error, variables, context) => {
            dispatch(dangerNotification(error.message));
        },
    });

    const updateAsistenteMutation = useMutation({
        mutationFn: updateAsistente,
        onSuccess: (asistente, variables, context) => {
            queryClient.setQueryData(['asistentes'], oldData => {

                let newData = oldData.map(asistenteAux => {
                    if(asistenteAux._id === asistente._id){
                        return asistente;
                    }else{
                        return asistenteAux;
                    }
                });

                return newData;
            });
            variables.callback();
            dispatch(successNotification('Asistente actualizado'));
        },
        onError: (error, variables, context) => {
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        asistentesQuery,
        createAsistenteMutation,
        updateAsistenteMutation,
    }
}