import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';

import styles from './BackChevron.module.css';

export const BackChevron = () => {

    const navigate = useNavigate();

    const handleBack = () => {

        navigate(-1);

    }

    return (
        <div className={styles.backContainer} onClick={handleBack}>
            <Icon.ChevronLeft className={styles.backIcon} />
        </div>
    )
}
