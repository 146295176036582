import React from 'react'
import * as Icon from 'react-bootstrap-icons';

import styles from './Error.module.css';

export const Error = () => {
  return (
    <div className={styles.container}>
        <div className="centrar mb-3"><Icon.DatabaseX size={50} /></div>
        <div className={"text-center " + styles.header}>¡Error!</div>
        <div className={"text-center " + styles.subheader}>No fue posible obtener la información</div>
    </div>
  )
}
