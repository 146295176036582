import { useEffect } from "react";
import PropTypes from 'prop-types';

import { useForm, useFormValidation } from "../../../../hooks";

import { IconButton, TimeInput } from "../../.."

import { colors } from "../../../../types/types";

import styles from '../MultiFormHorario.module.css';

export const HorarioForm = ({ element, formStatus='checked', onFormChange, removeElement }) => {

  const { inicio, fin, onInputChange } = useForm({ inicio: `${(element.horaInicio !== '') ? element.horaInicio : '00'}:${(element.minutoInicio !== '') ? element.minutoInicio : '00'}`, fin: `${(element.horaFin !== '') ? element.horaFin : '00'}:${(element.minutoFin !== '') ? element.minutoFin : '00'}` });

  const { isFormValid, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'inicio', default: (element.horaInicio !== '' && element.minutoInicio !== '')}, {field: 'fin', default: (element.horaFin !== '' && element.minutoFin !== '')}]);

  useEffect(() => {
    if(onFormChange) onFormChange({ id: element.id, horaInicio: inicio.split(':')[0], minutoInicio: inicio.split(':')[1], horaFin: fin.split(':')[0], minutoFin: fin.split(':')[1], validation: isFormValid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inicio, fin, isFormValid])

  useEffect(() => {
    if(formStatus === 'checking') startFormValidation();
  }, [formStatus, startFormValidation]);

  useEffect(() => {
    if(inicio !== '' && fin !== ''){
      startFormValidation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="row">
      <div className="col-10">
        <div className={styles.timeInputsContainerDisplay}>
          <div>
            <TimeInput
              formFieldStatus={ formFields['inicio'].status }
              isClearable={true}
              label="Desde"
              name="inicio"
              onChange={ onInputChange }
              required={true}
              requiredIndicator={true}
              setFormFieldValidation={ setFormFieldValidation }
              value={`${ inicio.split(':')[0] }:${inicio.split(':')[1]}`}
            />
          </div>
          <div className="ms-md-0 ms-lg-3">
            <TimeInput
              formFieldStatus={ formFields['fin'].status }
              isClearable={true}
              label="Hasta"
              name="fin"
              onChange={ onInputChange }
              required={true}
              requiredIndicator={true}
              setFormFieldValidation={ setFormFieldValidation }
              value={`${ fin.split(':')[0] }:${fin.split(':')[1]}`}
            />
          </div>
        </div>
      </div>
      <div className={"col-2"}>
        <div className={styles.closeBtnContainer}>
          <div>
            <label htmlFor="">&nbsp;</label>
            <IconButton
              icon="X"
              iconColor={colors.DANGER}
              marginTop={false}
              onClick={() => removeElement(element.id)}
              type="button"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

HorarioForm.propTypes = {
  element: PropTypes.any.isRequired,
  formStatus: PropTypes.oneOf(['checked', 'checking']),
  onFormChange: PropTypes.func,
  removeElement: PropTypes.func.isRequired,
}