import React from 'react'
import PropTypes from 'prop-types';

import styles from './../DetalleCuestionario.module.css';

export const CuestionarioPoem = ({ aplicacion }) => {

  let r1 = '';
  let r2 = '';
  let r3 = '';
  let r4 = '';
  let r5 = '';
  let r6 = '';
  let r7 = '';

  switch (aplicacion.pregunta1) {
    case 0:
      r1 = 'Ningún dia';
      break;
    case 1:
      r1 = '1 a 2 días';
      break;
    case 2:
      r1 = '3 a 4 días';
      break;
    case 3:
      r1 = '5 a 6 días';
      break;
    case 4:
      r1 = 'Todos los días';
      break;
  
    default:
      break;
  }

  switch (aplicacion.pregunta2) {
    case 0:
      r2 = 'Ningún dia';
      break;
    case 1:
      r2 = '1 a 2 días';
      break;
    case 2:
      r2 = '3 a 4 días';
      break;
    case 3:
      r2 = '5 a 6 días';
      break;
    case 4:
      r2 = 'Todos los días';
      break;
  
    default:
      break;
  }

  switch (aplicacion.pregunta3) {
    case 0:
      r3 = 'Ningún dia';
      break;
    case 1:
      r3 = '1 a 2 días';
      break;
    case 2:
      r3 = '3 a 4 días';
      break;
    case 3:
      r3 = '5 a 6 días';
      break;
    case 4:
      r3 = 'Todos los días';
      break;
  
    default:
      break;
  }

  switch (aplicacion.pregunta4) {
    case 0:
      r4 = 'Ningún dia';
      break;
    case 1:
      r4 = '1 a 2 días';
      break;
    case 2:
      r4 = '3 a 4 días';
      break;
    case 3:
      r4 = '5 a 6 días';
      break;
    case 4:
      r4 = 'Todos los días';
      break;
  
    default:
      break;
  }

  switch (aplicacion.pregunta5) {
    case 0:
      r5 = 'Ningún dia';
      break;
    case 1:
      r5 = '1 a 2 días';
      break;
    case 2:
      r5 = '3 a 4 días';
      break;
    case 3:
      r5 = '5 a 6 días';
      break;
    case 4:
      r5 = 'Todos los días';
      break;
  
    default:
      break;
  }

  switch (aplicacion.pregunta6) {
    case 0:
      r6 = 'Ningún dia';
      break;
    case 1:
      r6 = '1 a 2 días';
      break;
    case 2:
      r6 = '3 a 4 días';
      break;
    case 3:
      r6 = '5 a 6 días';
      break;
    case 4:
      r6 = 'Todos los días';
      break;
  
    default:
      break;
  }

  switch (aplicacion.pregunta7) {
    case 0:
      r7 = 'Ningún dia';
      break;
    case 1:
      r7 = '1 a 2 días';
      break;
    case 2:
      r7 = '3 a 4 días';
      break;
    case 3:
      r7 = '5 a 6 días';
      break;
    case 4:
      r7 = 'Todos los días';
      break;
  
    default:
      break;
  }

  return (
    <>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>1. <b>Durante la última semana</b>, ¿cuántos días ha tenido comezón (picazón) en la piel a causa de su dermatitis atópica?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r1 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>2. <b>Durante la última semana</b>, ¿cuántas noches se ha interrumpido su sueño a causa de su dermatitis atópica?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r2 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>3. <b>Durante la última semana</b>, ¿cuántos días le ha sangrado la piel a causa de su dermatitis atópica?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r3 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>4. <b>Durante la última semana</b>, ¿cuántos días le supurado o exudado líquido transparente de la piel a causa de su dermatitis atópica?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r4 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>5. <b>Durante la última semana</b>, ¿cuántos días ha tenido la piel agrietada a causa de su dermatitis atópica?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r5 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>6. <b>Durante la última semana</b>, ¿cuántos días ha tenido descamación de la piel a causa de su dermatitis atópica?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r6 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>7. <b>Durante la última semana</b>, ¿cuántos días ha sentido la piel reseca o áspera a causa de su dermatitis atópica?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r7 }</b></div>
        </div>
      </div>
    </>
  )
}

CuestionarioPoem.propTypes = {
  aplicacion: PropTypes.object.isRequired,
}