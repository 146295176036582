import PropTypes from 'prop-types';

import { inmunetApi } from '../../../api';

import { useForm, useFormValidation } from '../../../hooks';
import { successNotification, dangerNotification } from "../../../redux/slices/ui";

import { EmailInput, PrimaryButton, LightButton } from '../../../components';

import styles from '../Modals.module.css';
import { useDispatch } from 'react-redux';

export const EmailVerificationModal = ({ hide }) => {

  const dispatch = useDispatch();

  const { email, onInputChange } = useForm({ email: '', password: '', rememberme: 'true' });

  const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'email', default: false}]);

  const onSubmit = async ( event ) => {
    event.preventDefault();
    
    if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){

      try {

        await inmunetApi.post('/usuario/resend-email-verification', { email });

        hide();
        dispatch(successNotification('Ingresa a tu correo electrónico y sigue las instrucciones para verificar tu correo.'));

      } catch (error) {

        hide();
        if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.msg !== 'undefined') {

          dispatch(dangerNotification(error.response.data.msg));

        } else {

          dispatch(dangerNotification('Error al procesar la petición, intenta nuevamente.'));

        }

      }

    }else{

      startFormValidation();

    }
  }

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className={styles.modalHeader}>Reenviar correo de verificación</div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className={styles.modalSubHeader}>No has verificado tu dirección de correo electrónico, si no encuentras el correo de verificación en tu bandeja puedes reenviarlo.</div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <form onSubmit={ onSubmit }>
            <div className="row">
              <div className="col">
                <EmailInput
                  formFieldStatus={ formFields['email'].status }
                  label="Email"
                  name="email"
                  onChange={ onInputChange }
                  placeholder="example@email.com"
                  required={ true }
                  requiredIndicator={ true }
                  setFormFieldValidation={ setFormFieldValidation }
                  value={ email }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <LightButton
                  block={true}
                  icon="X"
                  marginTop={false}
                  onClick={ hide }
                  text="Ya he recibido el correo"
                  type="button"
                />
              </div>
              <div className='col-xs-12 col-sm-12 col-md-6'>
                <PrimaryButton
                  block={true}
                  disabled={formValidationStatus === 'checking'}
                  icon="Check"
                  marginTop={false}
                  text="Reenviar correo"
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

EmailVerificationModal.propTypes = {
  hide: PropTypes.func.isRequired,
}