import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query"
import * as Icon from 'react-bootstrap-icons';

import { startLogout } from '../../../../redux/slices/auth';

import { NavbarSearch } from "./components/NavbarSearch/NavbarSearch";

import styles from './Navbar.module.css';

export const Navbar = () => {

    const location = useLocation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const carpetButton = useRef(null);

    const queryClient = useQueryClient();

    const { user } = useSelector(state => state.auth);

    const handleAgenda = () => {
        if(!(location.pathname === '/agenda' || location.pathname === '/')){
            navigate("/");
        }
    }

    const handleInmunoterapias = () => {
        if(!(location.pathname === '/inmunoterapias')){
            navigate("/inmunoterapias");
        }
    }

    const handlePacientes = () => {
        if(!(location.pathname === '/pacientes')){
            navigate("/pacientes");
        }
    }

    const handleMovilMenuContract = () => {
        carpetButton.current?.click();
    }

    const handleLogout = () => {
        dispatch(startLogout());
        queryClient.clear();
        navigate("/");
    }

    return (
        <>
            <nav className={"navbar fixed-top bg-body-tertiary p-3 " + styles.navbar}>
                <div className={"container-fluid " + styles.navbarContainerFluid}>
                    <Link to="/" className={styles.navbarMovil}>
                        <div className={styles.navbarLogo} />
                    </Link>
                    <button className={"navbar-toggler " + styles.navbarMovil} type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" ref={carpetButton}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={"collapse navbar-collapse " + styles.navbarMovil} id="navbarTogglerDemo02">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="mt-3">
                                <div className={styles.navbarDropdownTop}>
                                    {(user.img !== null) &&
                                    <div>
                                        <div className={styles.navbarUserImg} style={{backgroundImage: 'url(\'' + ((user.img != null) ? user.img : 'https://placehold.co/200x200?text=Img') + '\')'}}></div>
                                    </div>
                                    }
                                    <div>
                                        <div className={styles.navbarDropdownHeader}>{user.displayName}</div>
                                        <div className={styles.navbarDropdownSubHeader}>{user.especialidad}</div>
                                    </div>
                                </div>
                            </li>
                            <li className={styles.navbarDropdownMenuItem} onClick={handleMovilMenuContract}><Link to="/estadisticas"><div className="dropdown-item" />Estadísticas</Link></li>
                            <li className={styles.navbarDropdownMenuItem} onClick={handleMovilMenuContract}><Link to="/pagos"><div className="dropdown-item" />Pagos</Link></li>
                            <li className={styles.navbarDropdownMenuItem}>
                                <div className={"dropdown " + styles.navbarDropdown}>
                                    <button className={"btn dropdown-toggle " + styles.navbarDropdownMovilButton} type="button" data-bs-toggle="dropdown" aria-expanded="false">Configuración</button>
                                    <ul className={"dropdown-menu " + styles.navbarDropdownSubMenu}>
                                        <li className={styles.navbarDropdownMenuSubItem} onClick={handleMovilMenuContract}><Link to="/perfil"><div className="dropdown-item" />Perfil</Link></li>
                                        <li className={styles.navbarDropdownMenuSubItem} onClick={handleMovilMenuContract}><Link to="/consultorios"><div className="dropdown-item" />Consultorios</Link></li>
                                        <li className={styles.navbarDropdownMenuSubItem} onClick={handleMovilMenuContract}><Link to="/expediente_clinico"><div className="dropdown-item" />Expediente Clínico</Link></li>
                                        <li className={styles.navbarDropdownMenuSubItem} onClick={handleMovilMenuContract}><Link to="/asistentes"><div className="dropdown-item" />Asistentes</Link></li>
                                        <li className={styles.navbarDropdownMenuSubItem} onClick={handleMovilMenuContract}><Link to="/honorarios"><div className="dropdown-item" />Honorarios</Link></li>
                                        <li className={styles.navbarDropdownMenuSubItem} onClick={handleMovilMenuContract}><Link to="/cfdi"><div className="dropdown-item" />CFDI</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li className={styles.navbarDropdownMenuItem} onClick={handleMovilMenuContract}><Link to="/soporte"><div className="dropdown-item" />Soporte</Link></li>
                            <li className={styles.navbarDropdownMenuItem} onClick={handleMovilMenuContract}><Link to="/legal"><div className="dropdown-item" />Legal</Link></li>
                            <li className={styles.navbarDropdownMenuItem} onClick={handleMovilMenuContract}><div className={styles.navbarLogoutMovil} onClick={handleLogout}>Logout</div></li>
                        </ul>
                    </div>
                    <div className={"row justify-content-around " + styles.navbarWeb}>
                        <div className="col">
                            <div className="d-flex">
                                <Link to="/">
                                    <div className={styles.navbarLogo} />
                                </Link>
                            </div>
                        </div>
                        <div className="col d-flex justify-content-center">
                            <NavbarSearch />
                        </div>
                        <div className="col d-flex justify-content-end">
                            <div className={styles.navbarDropdownContainer}>
                                {(user.img !== null) &&
                                <div className={styles.navbarUserImg} style={{backgroundImage: 'url(\'' + ((user.img != null) ? user.img : 'https://placehold.co/200x200?text=Img') + '\')'}}></div>
                                }
                                <div className={"dropdown " + styles.navbarDropdown}>
                                    <button className={"btn dropdown-toggle " + styles.navbarDropdownButton} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {user.displayName}
                                    </button>
                                    <ul className={"dropdown-menu " + styles.navbarDropdownMenu}>
                                        <li>
                                            <div className={styles.navbarDropdownTop}>
                                                <div>
                                                    <div className={styles.navbarUserImg} style={{backgroundImage: 'url(\'' + user.img + '\')'}}></div>
                                                </div>
                                                <div>
                                                    <div className={styles.navbarDropdownHeader}>{user.displayName}</div>
                                                    <div className={styles.navbarDropdownSubHeader}>{user.especialidad}</div>
                                                </div>
                                                <div><Icon.X className={styles.navbarDropdownClose} /></div>
                                            </div>
                                        </li>
                                        <li className={styles.navbarDropdownMenuItem}><Link to="/estadisticas"><div className="dropdown-item" />Estadísticas</Link></li>
                                        <li className={styles.navbarDropdownMenuItem}><Link to="/pagos"><div className="dropdown-item" />Pagos</Link></li>
                                        <li className={styles.navbarDropdownMenuItem}><Link to="/perfil"><div className="dropdown-item" />Configuración</Link></li>
                                        <li className={styles.navbarDropdownMenuItem}><Link to="/soporte"><div className="dropdown-item" />Soporte</Link></li>
                                        <li className={styles.navbarDropdownMenuItem}><Link to="/legal"><div className="dropdown-item" />Legal</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className={styles.logoutIconContainer}>
                                <Icon.BoxArrowRight className={styles.logoutIcon} onClick={handleLogout} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid d-block">
                    <div className="row">
                        <div className="col">
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="container-fluid d-block">
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <div className={styles.navbarTab + ((location.pathname === '/agenda' || location.pathname === '/') ? " " + styles.navbarTabActive : "")} onClick={handleAgenda}><div className={styles.navbarTabIcon + ((location.pathname === '/agenda' || location.pathname === '/') ? " " + styles.navbarTabIcon11 : " " + styles.navbarTabIcon1)}></div> Agenda</div>
                            <div className={styles.navbarTab + ((location.pathname === '/inmunoterapias') ? " " + styles.navbarTabActive : "")} onClick={handleInmunoterapias}><div className={styles.navbarTabIcon + ((location.pathname === '/inmunoterapias') ? " " + styles.navbarTabIcon21 : " " + styles.navbarTabIcon2)}></div> Inmunoterapias</div>
                            <div className={styles.navbarTab + ((['/pacientes', '/nuevo-paciente'].includes(location.pathname) || location.pathname.includes('/paciente/')) ? " " + styles.navbarTabActive : "")} onClick={handlePacientes}><div className={styles.navbarTabIcon + ((['/pacientes', '/nuevo-paciente'].includes(location.pathname) || location.pathname.includes('/paciente/')) ? " " + styles.navbarTabIcon31 : " " + styles.navbarTabIcon3)}></div> Pacientes</div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}
