import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";

import { inmunetApi } from '../../../api';

import { useForm, useFormValidation } from "../../../hooks";
import { successNotification, dangerNotification } from "../../../redux/slices/ui";

import { LightButton, PasswordConfirmationInput, PasswordInput, PrimaryButton } from "../../../components"

export const ContraseñaUpdate = ({ handleCancelarEditarContraseña }) => {

    const dispatch = useDispatch();

    const { oldPassword, password, confirmacion, onInputChange, onResetForm } = useForm({ oldPassword: '', password: '', confirmacion: '' });

    const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation, onResetValidation } = useFormValidation([{field: 'oldPassword', default: false}, {field: 'password', default: false}, {field: 'confirmacion', default: false}]);

    const onSubmit = async ( event ) => {
        event.preventDefault();

        startFormValidation();
        
        if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
            
            try {

                await inmunetApi.put('/usuario/password', { oldPassword, password, confirmacion });

                dispatch(successNotification('Contraseña actualizada'));

                handleCancelarEditarContraseña();
    
            } catch (error) {

                if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.msg !== 'undefined') {
        
                    onResetForm();
                    onResetValidation();
                    dispatch(dangerNotification(error.response.data.msg));
        
                } else {
        
                    onResetForm();
                    onResetValidation();
                    dispatch(dangerNotification('Error al actualizar contraseña'));
        
                }
    
            }
    
        }
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-4 col-lg">
                        <PasswordInput
                            formFieldStatus={ formFields['password'].status }
                            label="Contraseña anterior"
                            name="oldPassword"
                            onChange={ onInputChange }
                            required={true}
                            requiredIndicator={true}
                            setFormFieldValidation={ setFormFieldValidation }
                            value={ oldPassword }
                        />
                    </div>
                    <div className="col-md-4 col-lg">
                        <PasswordInput
                            formFieldStatus={ formFields['password'].status }
                            label="Nueva contraseña"
                            name="password"
                            onChange={ onInputChange }
                            required={true}
                            requiredIndicator={true}
                            setFormFieldValidation={ setFormFieldValidation }
                            value={ password }
                        />
                    </div>
                    <div className="col-md-4 col-lg">
                        <PasswordConfirmationInput
                            formFieldStatus={ formFields['confirmacion'].status }
                            label="Confirmar contraseña"
                            name="confirmacion"
                            password={ password }
                            onChange={ onInputChange }
                            required={true}
                            requiredIndicator={true}
                            setFormFieldValidation={ setFormFieldValidation }
                            value={ confirmacion }
                        />
                    </div>
                    <div className="col-md-6 col-lg">
                        <LightButton
                            block={true}
                            disabled={formValidationStatus === 'checking'}
                            icon="X"
                            marginRight={true}
                            onClick={handleCancelarEditarContraseña}
                            text="Cancelar"
                            type="button"
                        />
                    </div>
                    <div className="col-md-6 col-lg">
                        <PrimaryButton
                            block={true}
                            disabled={formValidationStatus === 'checking'}
                            icon="Check"
                            text="Guardar"
                            type="submit"
                        />
                    </div>
                </div>
            </form>
        </>
    )
}

ContraseñaUpdate.propTypes = {
    handleCancelarEditarContraseña: PropTypes.func.isRequired
}