import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'auto-checking', // ['auto-checking', 'not-authenticated', 'checking', 'authenticated]
        token: null,
        user: null,
        errorMessage: undefined
    },
    reducers: {
        login: (state, { payload }) => {
            state.status = 'authenticated';
            state.token = payload.token;
            state.user = payload.usuario;
            state.errorMessage = undefined;
        },
        logout: (state, { payload }) => {
            state.status = 'not-authenticated';
            state.token = null;
            state.user = null;
            state.errorMessage = (typeof payload !== 'undefined') ? payload.msg : undefined;
        },
        checking: (state) => {
            state.status = 'checking';
        },
        setToken: (state, { payload }) => {
            state.token = payload;
        },
    }
});


// Action creators are generated for each case reducer function
export const { login, logout, checking, setToken } = authSlice.actions;