import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from "react-router-dom";

import { PrivateLayout } from "../pages/Layout";
import {
  Agenda,
  Asistentes,
  Consultorios,
  Error,
  Inmunoterapia,
  Pacientes,
  Perfil,
  PacienteConsulta,
  PacienteCitas,
  PacienteCitasIdLoader,
  PacienteView,
  PacienteViewIdLoader,
  PacienteNuevo,
  PacienteInmunoterapias,
  PacientePruebasAlergia,
  PacienteAseguradoras,
  PacienteAntecedentes,
  PacienteAntecedentesIdLoader,
  PacienteEscalasControl,
  PacienteEscalasControlLoader,
  PacienteHistorial,
  PacienteEditar,
  PacienteEditarIdLoader,
  ConfiguracionExpedienteClinico,
} from "../pages";
import {
  CalendarioSider,
  ConfiguracionSider,
  PacienteNuevoEditarSider,
} from "../pages/Layout/PrivateLayout/Siders";
import { PacienteHeader } from "../pages/Layout/PrivateLayout/Header";

const router = createBrowserRouter([
  {
    path: "",
    element: <PrivateLayout />,
    errorElement: <Error />,
    children: [
      {
        path: "",
        element: <CalendarioSider />,
        children: [
          {
            path: "",
            element: <Agenda />,
          },
          {
            path: "/agenda",
            element: <Agenda />,
          },
        ]
      },
      {
        path: "/inmunoterapias",
        element: <Inmunoterapia />,
      },
      {
        path: "/pacientes",
        element: <Pacientes />,
      },
      {
        path: "",
        element: <PacienteNuevoEditarSider />,
        children: [
          {
            path: "/paciente/nuevo",
            element: <PacienteNuevo />,
          },
          {
            path: "/paciente/nuevo/:id",
            element: <PacienteNuevo />,
          },
          {
            path: "/paciente/editar/:id",
            element: <PacienteEditar />,
            loader: PacienteEditarIdLoader,
          }
        ]
      },
      {
        path: "",
        element: <PacienteHeader />,
        children: [
          {
            path: "/paciente/view/:id",
            element: <PacienteView />,
            loader: PacienteViewIdLoader,
          },
          {
            path: "",
            // element: <ConsultaLayout />,
            children: [
              {
                path: '/paciente/consulta/:id',
                element: <PacienteConsulta />,
                // loader: PacienteIdLoader,
              }
            ]
          },
          {
            path: "/paciente/citas/:id",
            element: <PacienteCitas />,
            loader: PacienteCitasIdLoader,
          },
          {
            path: "/paciente/inmunoterapias/:id",
            element: <PacienteInmunoterapias />,
            // loader: PacienteIdLoader,
          },
          {
            path: "/paciente/pruebas-alergia/:id",
            element: <PacientePruebasAlergia />,
            // loader: PacienteIdLoader,
          },
          {
            path: "/paciente/aseguradoras/:id",
            element: <PacienteAseguradoras />,
            // loader: PacienteIdLoader,
          },
          {
            path: "/paciente/antecedentes/:id",
            element: <PacienteAntecedentes />,
            loader: PacienteAntecedentesIdLoader,
          },
          {
            path: "/paciente/escalas-control/:id",
            element: <PacienteEscalasControl />,
            loader: PacienteEscalasControlLoader,
          },
          {
            path: "/paciente/historial/:id",
            element: <PacienteHistorial />,
            // loader: PacienteIdLoader,
          },
        ]
      },
      {
        path: "",
        element: <ConfiguracionSider />,
        children: [
          {
            path: "/perfil",
            element: <Perfil />,
          },
          {
            path: "/consultorios",
            element: <Consultorios />,
          },
          {
            path: "/asistentes",
            element: <Asistentes />,
          },
          {
            path: "/configuracion-expediente-clinico",
            element: <ConfiguracionExpedienteClinico />,
          },
        ]
      },
      {
        path: "/*",
        element: <Navigate to="" />,
      }
    ],
  },
]);

export const PrivateRouter = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}