import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { createPacientePruebaAlergia } from "../services/pruebaalergia.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacientePruebaAlergiaCrear = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const createPruebaAlergiaMutation = useMutation({
        mutationFn: createPacientePruebaAlergia,
        onSuccess: (prueba, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['paciente/pruebaAlergia'] });
            variables.success();
            dispatch(successNotification('Prueba de alergia creada'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        createPruebaAlergiaMutation,
    }
}