import { useQuery } from "@tanstack/react-query"

import { getDispositivo } from "../services/pruebaalergia.service";

export const useDispositivo = (tipoPrueba) => {

    const dispositivoQuery = useQuery({
        queryKey: ['pruebaAlergiaDispositivo'],
        queryFn: () => getDispositivo(tipoPrueba),
        staleTime: 1000 * 60 * 60 * 24, // 1 día
    });

    return {
        dispositivoQuery,
    }
}