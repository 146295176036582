import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Icon from 'react-bootstrap-icons';

import { colors } from '../../../../types/types';

import styles from './PacientesSearch.module.css';

export const PacientesSearch = ({ setSearchString }) => {

  const searchInput = useRef(null);

  const [searchTerm, setSearchTerm] = useState('');

  const [searchValue, setSearchValue] = useState('');

  const handleOnChange = (e) => {
    setSearchValue(e.target.value);
    setSearchTerm(e.target.value);
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchString(searchTerm);
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  return (
    <div className={styles.FeedbackIconContainer}>
      <input type="text" className={"form-control " + styles.searchInput} value={searchValue} placeholder="Buscar" onChange={handleOnChange} ref={searchInput} />
      <Icon.Search className={styles.FeedbackIcon} color={ colors.INMPUT_FEEDBACK } />
    </div>
  )
}

PacientesSearch.propTypes = {
  setSearchString: PropTypes.func.isRequired,
}