import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query"

import { createPaciente } from "../services/pacientenuevo.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteNuevo = () => {

    const dispatch = useDispatch();

    const createPacienteMutation = useMutation({
        mutationFn: createPaciente,
        onSuccess: ({ modal, paciente }, variables, context) => {
            if(modal){
                variables.onComplete();
                variables.setIdPacienteAsociadoModal(paciente);
                variables.onModal();
            }else{
                variables.onComplete();
                variables.onSuccess(paciente._id);
                dispatch(successNotification('Paciente creado'));
            }
        },
        onError: (error, variables, context) => {
            variables.onComplete();
            dispatch(dangerNotification(error.msg.message));
        }
    });

    return {
        createPacienteMutation,
    }
}