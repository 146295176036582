import PropTypes from 'prop-types';

import { PrimaryButton, TextInputPreview } from "../../../components"

export const AsociadoPreview = ({ asociado, independizar }) => {
    return (
        <>
            <div className="row d-flex justify-content-between">
                <div className="col-md-11 col-lg-3">
                    <TextInputPreview
                        label="Nombre"
                        name="nombre"
                        value={ asociado.nombre }
                    />
                </div>
                <div className="col-md-1 col-lg-3 d-flex justify-content-end">
                    <div className='d-flex align-items-center'>
                        <PrimaryButton
                            inverse={true}
                            marginTop={false}
                            onClick={independizar}
                            text='Independizar'
                            type='button'
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

AsociadoPreview.propTypes = {
    asociado: PropTypes.any.isRequired,
    independizar: PropTypes.func.isRequired
}