import React from 'react'
import PropTypes from 'prop-types';

import styles from './../DetalleCuestionario.module.css';

export const CuestionarioAsma = ({ aplicacion }) => {

  let r1 = (aplicacion.pregunta1) ? 'Si' : 'No';
  let r2 = (aplicacion.pregunta2) ? 'Si' : 'No';
  let r3 = (aplicacion.pregunta3) ? 'Si' : 'No';
  let r4 = (aplicacion.pregunta4) ? 'Si' : 'No';

  return (
    <>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>1. <b>En las últimas 4 semanas</b>, ¿Ha presentado síntomas de asma durante el día más de dos veces por semana?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r1 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>2. <b>En las últimas 4 semanas</b>, ¿Se ha despertado en la noche a causa del asma?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r2 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>3. <b>En las últimas 4 semanas</b>, ¿Ha necesitado su medicamento de rescate más de dos veces por semana?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r3 }</b></div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">
          <div className={styles.cuestionarioText}>4. <b>En las últimas 4 semanas</b>, ¿Se ha limitado alguna actividad a causa del asma?</div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className={styles.cuestionarioText}>R. <b>{ r4 }</b></div>
        </div>
      </div>
    </>
  )
}

CuestionarioAsma.propTypes = {
  aplicacion: PropTypes.object.isRequired,
}