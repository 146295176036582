import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query"

import { startAutoLogin } from "../redux/slices/auth";

import { PrivateRouter } from "./";
import { PublicRouter } from "./";

import { NotificationsSystem } from "../components";

export const AppRouter = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    useEffect(() => {
        dispatch(startAutoLogin())
        queryClient.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    const { status } = useSelector(state => state.auth);

    return (
        <>
            <NotificationsSystem />
            {status === 'auto-checking' ? <div>Cargando...</div> : (status === 'authenticated' ? <PrivateRouter /> : <PublicRouter />) }
        </>
    )
}
