import PropTypes from 'prop-types';

import { PrimaryButton, LightButton } from '../../../components';

import styles from '../Modals.module.css';

export const PacienteAsociadoModal = ({ hide, submit }) => {

  const onSubmit = async ( event ) => {
    event.preventDefault();

    submit();
    hide();

  }

  return (
    <div>
      <div className="row">
        <div className="col">
          <div className={styles.modalHeader}>Asociación de usuario</div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <form onSubmit={ onSubmit }>
            <div className="row">
              <div className="col mt-3 mb-3">
                <p>El correo seleccionado pertenece al usuario de otro paciente, es posible que exista algún parentesco entre ellos.</p>
                <p>¿Deseas asociar el nuevo paciente?</p>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6">
                <LightButton
                  block={true}
                  icon="X"
                  marginTop={false}
                  onClick={ hide }
                  text="Cancelar"
                  type="button"
                />
              </div>
              <div className='col-xs-12 col-sm-12 col-md-6'>
                <PrimaryButton
                  block={true}
                  icon="Check"
                  marginTop={false}
                  text="Asociar paciente"
                  type="submit"
                />
              </div>
            </div>
            <div className="row">
              <div className="col mt-4">
                <p>Si no es el caso y prefieres crear un paciente totalmente independiente selecciona cancelar y proporciona otro correo electrónico.</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

PacienteAsociadoModal.propTypes = {
  hide: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
}