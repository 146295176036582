import React from 'react'

import { useSubcutanea } from './hooks/useSubcutanea';

import { SubcutaneaUpdate } from './components/SubcutaneaUpdate';
import { Error, Loader } from '../../../../components';

export const Subcutanea = () => {

    const { subcutaneaQuery: { isLoading, isError, data: subcutanea } } = useSubcutanea();

    return (
        <>
            {(isLoading)
            ? <Loader />
            : <>
                {(isError)
                ? <Error />
                : <>
                    <SubcutaneaUpdate subcutaneaAux={subcutanea} />
                </>
                }
            </>
            }
        </>
    )
}
