import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

const inmunetApi = axios.create({
    baseURL: baseUrl
});

//Headers de requests
inmunetApi.interceptors.request.use(config => {

    config.headers = {
        ...config.headers,
        'x-token': localStorage.getItem('token'),
        'x-reset-password-token': localStorage.getItem('reset-password-token')
    };

    return config;

});

export default inmunetApi;