import React from 'react'

import PropTypes from 'prop-types';

export const Acordion = ({ body, header, id, open = false }) => {

    const css = `
        .accordion-button:not(.collapsed) {
            background-color: transparent !important;
        }
        .accordion-button:focus {
            box-shadow: none !important;
        }
    `;

    return (
        <>
            <style>{ css }</style>
            <div className="accordion" id={"accordion" + id}>
                <div className="accordion-item">
                <h2 className="accordion-header">
                    <button className={"accordion-button" + ((open) ? "" : " collapsed")} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + id} aria-expanded={open} aria-controls={"collapse" + id}>
                        { header }
                    </button>
                </h2>
                <div id={"collapse" + id} className={"accordion-collapse collapse" + ((open) ? " show" : "")} data-bs-parent={"#accordion" + id}>
                    <div className="accordion-body">
                        { body }
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}

Acordion.propTypes = {
    body: PropTypes.element.isRequired,
    header: PropTypes.element.isRequired,
    id: PropTypes.string.isRequired,
    open: PropTypes.bool,
  }