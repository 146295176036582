import { useState } from 'react';

export const useFormValidation = (formFields = []) => {

    let formFiledsAux = {};
    let formFieldsToValidate = {};
    let isFormValid = true;
    formFields.forEach(data => {
        formFiledsAux[data.field] = {
            status: 'checked', // 'checked', 'checking'
            isValid: data.default
        };
        formFieldsToValidate[data.field] = {
            status: 'checking', // 'checked', 'checking'
            isValid: null
        };
        if (!data.default) {
            isFormValid = false;
        }
    });

    const [formValidation, setFormValidation] = useState({
        isFormValid: isFormValid,
        formValidationStatus: 'checked', // 'checked', 'checking'
        formFields: formFiledsAux
    });

    const startFormValidation = () => {

        setFormValidation({
            ...formValidation,
            isFormValid: true,
            formValidationStatus: 'checking',
            formFields: {
                ...formFieldsToValidate,
            },
        });

    }

    const setFormFieldValidation = (name, value) => {

        let isFormValid = value;
        let formValidationStatus = 'checked';
        Object.keys(formValidation.formFields).forEach(field => {
            if (field !== name) {
                if (formValidation.formFields[field].isValid !== true) {
                    isFormValid = false;
                }
                if (formValidation.formFields[field].status === 'checking') {
                    formValidationStatus = 'checking';
                }
            }
        });

        setFormValidation({
            ...formValidation,
            isFormValid: isFormValid,
            formValidationStatus: formValidationStatus,
            formFields: {
                ...formValidation.formFields,
                [name]: {
                    ...formValidation.formFields[name],
                    status: 'checked',
                    isValid: (value === true),
                },
            },
        });

    }

    const setMultipleFormFieldValidation = (fields) => {

        let isFormValid = null;
        let formValidationStatus = 'checked';
        let newFormFields = {};

        Object.keys(formValidation.formFields).forEach(field => {
            //Busqueda de field en valores a actualizar
            let fieldAux = fields.find(fieldData => fieldData.name === field);
            if (fieldAux === undefined) {
                //Valores de field original

                if (isFormValid == null) {
                    isFormValid = formValidation.formFields[field].isValid;
                } else if (isFormValid === true && (formValidation.formFields[field].isValid === false || formValidation.formFields[field].isValid === 'false')) {
                    isFormValid = false;
                }

                newFormFields = {
                    ...newFormFields,
                    [field]: {
                        ...formValidation.formFields[field],
                        status: formValidation.formFields[field].status,
                        isValid: formValidation.formFields[field].isValid,
                    },
                };
            } else {
                //Valores de nuevo field

                if (isFormValid == null) {
                    isFormValid = fieldAux.value;
                } else if (isFormValid === true && (fieldAux.value === false || fieldAux.value === 'false')) {
                    isFormValid = false;
                }

                newFormFields = {
                    ...newFormFields,
                    [field]: {
                        ...formValidation.formFields[field],
                        status: 'checked',
                        isValid: fieldAux.value,
                    }
                };
            }

        });

        setFormValidation({
            ...formValidation,
            isFormValid: isFormValid,
            formValidationStatus: formValidationStatus,
            formFields: newFormFields
        });

    }

    const onResetValidation = () => {
        setFormValidation({
            isFormValid: isFormValid,
            formValidationStatus: 'checked', // 'checked', 'checking'
            formFields: formFiledsAux
        });
    }

    return {
        ...formValidation,
        formValidation,
        startFormValidation,
        setFormFieldValidation,
        setMultipleFormFieldValidation,
        onResetValidation,
    }
}