import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { dangerNotification } from '../../redux/slices/ui';

import styles from './FormFields.module.css';

export const FileInputUploader = ({ defaultValue, disabled = false, label, name, uploadFn }) => {

    const dispatch = useDispatch();

    const inputButton = useRef(null);
    const previewInput = useRef(null);

    const handleFileLoadTrigger = () => {
        inputButton.current?.click();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleInputValidation = useCallback((archivo) => {
        if(archivo && archivo["type"] !== "image/jpeg" && archivo["type"] !== "image/png" && archivo["type"] !== "image/jpg" && archivo["type"] !== "application/pdf"){
            dispatch(dangerNotification('El archivo debe estar en formato PDF, JPG, JPEG o PNG'));
            return false;
        }else{
            return true;
        }
    })

    const handleChange = (e) => {
        var archivo = e.target.files[0];
        if(handleInputValidation(archivo)){
            previewInput.current.value = inputButton.current?.value.split('\\')[inputButton.current?.value.split('\\').length - 1];
            uploadFn(archivo);
        }
    }

    return (
        <>
            <label htmlFor={ name } className={styles.customInputLabel}>{ label }</label>
            <div className='d-flex justify-content-start'>
                <input type="text" className={"form-control " + styles.customInputBasicWithButton} ref={previewInput} value={(defaultValue !== '-') ? defaultValue : ''} readOnly />
                <button className={"btn " + styles.customInputButton} onClick={handleFileLoadTrigger} disabled={disabled}>Subir archivo</button>
            </div>
            <input className='d-none' type="file" onChange={handleChange} ref={inputButton} />
        </>
    )
}

FileInputUploader.propTypes = {
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    uploadFn: PropTypes.func.isRequired,
  }