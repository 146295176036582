import { useQuery } from "@tanstack/react-query"

import { getPacienteCuestionarios } from "../services/pacientecuestionarios.service";

export const usePacienteCuestionarios = (id) => {

    const pacienteCuestionariosQuery = useQuery({
        queryKey: ['paciente/cuestionarios', id],
        queryFn: () => getPacienteCuestionarios(id),
        staleTime: 1000 * 60 * 5, // 5 min
    });

    return {
        pacienteCuestionariosQuery,
    }
}