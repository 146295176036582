import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        consultorio: {
            // _id: '',
            // nombre: ''
        },
        notifications: [
            // {
            //     id: '', // moment().unix()
            //     content: '',
            //     type: '', // 'success', 'info', 'warning', 'danger'
            // }
        ],
        pacienteSearch: ''
    },
    reducers: {
        setConsultorio: (state, { payload }) => {
            state.consultorio = payload;
        },
        addNotification: (state, { payload }) => {
            state.notifications = [
                ...state.notifications,
                {
                    id: payload.id,
                    content: payload.content,
                    type: payload.type,
                }
            ];
        },
        removeNotification: (state, { payload }) => {
            state.notifications = state.notifications.filter(noty => noty.id !== payload);
        },
        clearNotifications: (state) => {
            state.notifications = [];
        },
        setPacienteSearch: (state, { payload }) => {
            state.pacienteSearch = payload;
        },
        clearPacienteSearch: (state) => {
            state.pacienteSearch = '';
        },
    }
});


// Action creators are generated for each case reducer function
export const { setConsultorio, addNotification, removeNotification, clearNotifications, setPacienteSearch, clearPacienteSearch } = uiSlice.actions;