import { inmunetApi } from '../../../../../api';

export const getPacientes = async(search) => {

    if (search.length >= 3) {

        const params = new URLSearchParams();
        params.append('pag', `1`);
        params.append('limite', `10`);
        params.append('search', `${search}`);

        try {

            const { data } = await inmunetApi.get('/paciente?paginacion=true', {
                params,
            });

            if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

            return data;

        } catch (error) {

            throw new Error(error);

        }

    } else {
        return {
            pacientes: []
        }
    }

};