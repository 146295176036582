import { useQuery } from "@tanstack/react-query"

import { getPrueba } from "../services/pruebaalergia.service";

export const usePrueba = (tipoPrueba) => {

    const pruebaQuery = useQuery({
        queryKey: ['pruebaAlergiaPrueba'],
        queryFn: () => getPrueba(tipoPrueba),
        staleTime: 1000 * 60 * 60 * 24, // 1 día
    });

    return {
        pruebaQuery,
    }
}