import { useQuery } from "@tanstack/react-query"

import { getPacienteLaboratorio } from "../services/laboratorio.service";

export const usePacienteLaboratorio = (id) => {

    const pacienteLaboratorioQuery = useQuery({
        queryKey: ['paciente/laboratorio', id],
        queryFn: () => getPacienteLaboratorio(id),
        staleTime: 1000 * 60 * 60, // 1 hora
    });

    return {
        pacienteLaboratorioQuery,
    }
}