import { inmunetApi } from '../../../../../../api';

export const getPaciente = async(id) => {

    try {

        const { data } = await inmunetApi.get(`/paciente/${ id }`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.paciente;

    } catch (error) {

        throw new Error(error);

    }

};