import { Outlet, ScrollRestoration } from "react-router-dom";

import { Navbar } from "./Navbar";

import styles from './PrivateLayout.module.css';

export const PrivateLayout = () => {

    return (
        <>
            <ScrollRestoration
                getKey={(location, matches) => {
                    // default behavior
                    return location.key;
                }}
            />
            {/* Navbar */}
            <Navbar />
            {/* Navbar */}
            <div className={"container-fluid d-flex flex-column " + styles.mainContainer}>
                <Outlet />
            </div>
        </>
    )
}
