import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const PrincipalPreview = ({ status }) => {
  return (
    <div>
        <div className={styles.principalPreviewContainer}>
            <div className={styles.principalPreviewIcon + " " + ((status) ? styles.principalPreviewIconActive : styles.principalPreviewIconInActive)}></div>
        </div>
        <div className='text-center'>
            <label className={"form-check-label " + styles.customPrincipalText} htmlFor="principal">Consultorio principal</label>
        </div>
    </div>
  )
}

PrincipalPreview.propTypes = {
    status: PropTypes.bool.isRequired,
}