import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { inmunetApi } from '../../../api';

import { useAsistentes } from '../hooks/useAsistentes';
import { useForm, useFormValidation } from '../../../hooks';
import { dangerNotification } from "../../../redux/slices/ui";

import { LightButton, PrimaryButton, ReactSelectInput } from '../../../components';

export const ConsultoriosUpdate = ({ asistente, handleCancelarEditarConsultorios }) => {

  const dispatch = useDispatch();

  const { updateAsistenteMutation: { mutate: updateUsuario, isPending } } = useAsistentes();

  const { consultorios, onInputChange } = useForm({ consultorios: asistente.consultorios.map(consultorio => consultorio._id) });

  const { isFormValid, formValidationStatus, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'consultorios', default: true}]);

  const [consultoriosDisponibles, setConsultoriosDisponibles] = useState([]);

  const onSubmit = async ( event ) => {
    event.preventDefault();
    
    if(formValidationStatus === 'checked' && isFormValid != null && isFormValid){
        updateUsuario({ data: { consultorios: JSON.stringify(consultorios) }, id: asistente._id, callback: handleCancelarEditarConsultorios });
    }else{
      startFormValidation();
    }
  
  }

  useEffect(() => {
    async function fetchData(){
      try {
  
        let resp = await inmunetApi.get(`/consultorio?paginacion=false`);
  
        setConsultoriosDisponibles(resp.data.consultorios);
  
      } catch (error) {
  
        dispatch(dangerNotification('Error al recuperar consultorios disponibles.'));
  
      }
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-12 col-lg">
          <ReactSelectInput
            defaultValues={asistente.consultorios.map(cons => ({ value: cons._id, label: cons.nombre }))}
            formFieldStatus={ formFields['consultorios'].status }
            name="consultorios"
            onChange={ onInputChange }
            required={true}
            requiredIndicator={true}
            setFormFieldValidation={ setFormFieldValidation }
            value={consultorios}
            values={consultoriosDisponibles.map(cons => ({ value: cons._id, label: cons.nombre }))}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg">
            <LightButton
                block={true}
                disabled={isPending || formValidationStatus === 'checking'}
                icon="X"
                marginRight={true}
                onClick={handleCancelarEditarConsultorios}
                text="Cancelar"
                type="button"
            />
        </div>
        <div className="col-md-6 col-lg">
            <PrimaryButton
                block={true}
                disabled={isPending || formValidationStatus === 'checking'}
                icon="Check"
                text="Guardar"
                type="submit"
            />
        </div>
    </div>
  </form>
  )
}

ConsultoriosUpdate.propTypes = {
    asistente: PropTypes.object.isRequired,
    handleCancelarEditarConsultorios: PropTypes.func.isRequired
}