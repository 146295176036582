const tableCss = `
th{
    font-weight: 400 !important;
}
tr{
    border-color: var(--panel-border);
    cursor: pointer;
}
tr:hover{
    background-color: var(--panel-border);
}
tr.noBorder{
    border-color: transparent;
}
tr.noPointer{
    cursor: unset;
}
tr.noHover:hover{
    background-color: unset;
}   
td{
    background-color: transparent !important;
}
.tableText{
    color: var(--seondary-text) !important;
}
.dropdown-toggle-no-indicator::after {
    content: unset;
}
.table-responsive .dropdown,
.table-responsive .btn-group,
.table-responsive .btn-group-vertical {
    position: static;
}
.table-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}
.table-tooltip .table-tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    margin-left: 5px;
}
.table-tooltip:hover .table-tooltiptext {
    visibility: visible;
}
`;

module.exports = {
    tableCss
}