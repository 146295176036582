import React from 'react'

import { usePruebasAlergia } from './hooks/usePruebasAlergia';

import { PruebasAlergiaUpdate } from './components/PruebasAlergiaUpdate';
import { Error, Loader } from '../../../../components';

export const PruebasAlergia = () => {

    const { pruebasAlergiaQuery: { isLoading, isError, data: pruebasAlergia } } = usePruebasAlergia();

    return (
        <>
            {(isLoading)
            ? <Loader />
            : <>
                {(isError)
                ? <Error />
                : <>
                    <PruebasAlergiaUpdate pruebasAlergia={pruebasAlergia} />
                </>
                }
            </>
            }
        </>
    )
}
