import { useState } from 'react';
import PropTypes from 'prop-types';

import { usePacienteGraficaCuestionario } from './hooks/usePacienteGraficaCuestionario';

import { LightButton } from '../../../components';
import { GraficaRinitis } from './components/GraficaRinitis';
import { GraficaUrticaria } from './components/GraficaUrticaria';
import { GraficaAsma } from './components/GraficaAsma';
import { GraficaPoem } from './components/GraficaPoem';

import styles from '../Modals.module.css';

export const GraficaCuestionarioModal = ({ paciente, cuestionario, hide }) => {

    const [pag, setPag] = useState(1);

    const { pacienteGraficaCuestionarioQuery: { isLoading, isError, data } } = usePacienteGraficaCuestionario({ id: paciente, cuestionario: cuestionario.numero, pag });

    const prevPage = () => {
        if (typeof data.prev !== 'undefined' && !data.prev) {
          return;
        }
    
        setPag(pag + 1);
    };
    
    const nextPage = () => {
        if (typeof data.next !== 'undefined' && !data.next) {
            return;
        }
    
        setPag(pag - 1);
    };

    return (
        <div>
            <div className="row mb-3">
                <div className="col">
                    <div className={styles.modalFourthHeader}>Gráfica {cuestionario.nombre}</div>
                </div>
            </div>
            
            {(!isLoading && !isError && cuestionario.numero === 1) && <GraficaRinitis data={data} prevPage={prevPage} nextPage={nextPage} />}
            {(!isLoading && !isError && cuestionario.numero === 2) && <GraficaUrticaria data={data} prevPage={prevPage} nextPage={nextPage} />}
            {(!isLoading && !isError && cuestionario.numero === 3) && <GraficaAsma data={data} prevPage={prevPage} nextPage={nextPage} />}
            {(!isLoading && !isError && cuestionario.numero === 4) && <GraficaPoem data={data} prevPage={prevPage} nextPage={nextPage} />}
            <div className="row">
                <div className='col-xs-12 col-sm-12 col-md-6'></div>
                <div className="col-xs-12 col-sm-12 col-md-6">
                    <div className='d-flex flex-row-reverse'>
                        <LightButton
                            marginTop={false}
                            onClick={ hide }
                            text="Cerrar"
                            type="button"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

GraficaCuestionarioModal.propTypes = {
    cuestionario: PropTypes.object.isRequired,
    hide: PropTypes.func.isRequired
}