import React from 'react';

import styles from './Loader.module.css';

export const Loader = () => {
  return (
    <div className='centrar'>
        <div className={"spinner-border " + styles.spinner} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
  )
}
