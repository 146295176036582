import PropTypes from 'prop-types';

import * as Icon from 'react-bootstrap-icons';

import styles from './FormFields.module.css';

export const ActivoPreview = ({ status, text }) => {
  return (
    <div>
        <div className={styles.activoPreviewContainer}>
            <Icon.CheckCircle className={styles.activoPreviewIcon + " " + ((status) ? styles.activoPreviewIconActive : styles.activoPreviewIconInActive)} />
        </div>
        <div className='text-center'>
            <label className={"form-check-label " + styles.customActivoText} htmlFor="principal">{text}</label>
        </div>
    </div>
  )
}

ActivoPreview.propTypes = {
    status: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
}