import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useForm, useFormValidation } from '../../../hooks';
import { useTipo } from '../hooks/useTipo';
import { usePrueba } from '../hooks/usePrueba';
import { useDispositivo } from '../hooks/useDispositivo';
import { useSitio } from '../hooks/useSitio';
import { usePacientePruebaAlergiaCrear } from '../hooks/usePacientePruebaAlergiaCrear';

import { DateInput, Error, LightButton, Loader, MultiFormAlergenos, MultiFormArchivos, PrimaryButton, SelectInput, SelectKeyInput, TextAreaInput } from '../../../components';

import styles from '../PacienteConsulta.module.css';

export const PruebaAlergiaNuevo = ({ id }) => {

    const { createPruebaAlergiaMutation: { mutate: createPruebaAlergia, isPending } } = usePacientePruebaAlergiaCrear(id);

    const [resetFields, setResetFields] = useState(moment().unix());

    const [isClearable, setIsClearable] = useState(false);

    const [tipoPruebaReset, setTipoPruebaReset] = useState(true);

    const [pruebaReset, setPruebaReset] = useState(true);

    const [positivaReset, setPositivaReset] = useState(true);

    const [dispositivoReset, setDispositivoReset] = useState(true);

    const [sitioReset, setSitioReset] = useState(true);

    const [alergenosReset, setAlergenosReset] = useState(true);

    const { fecha, tipoPrueba, prueba, positiva, dispositivo, sitio, alergenos, comentarios, archivos, onInputChange, onMultipleInputChange, onResetForm } = useForm({ fecha: '', tipoPrueba: '', prueba: '', positiva: 'false', dispositivo: '', sitio: '', alergenos: [{id: moment().unix(), alergeno: '', resultados: ''}], comentarios: '', archivos: [{id: moment().unix(), archivo: null}] });

    const { isFormValid: isFormStaticValid, formValidationStatus: formStaticValidationStatus, formFields: formStaticFields, startFormValidation: startFormStaticValidation, setFormFieldValidation: setFormStaticFieldValidation, onResetValidation: onStaticResetValidation } = useFormValidation([{field: 'fecha', default: false}, {field: 'positiva', default: true}, {field: 'comentarios', default: true}]);

    const { isFormValid: isFormTipoPruebaValid, formValidationStatus: formTipoPruebaValidationStatus, formFields: formTipoPruebaFields, startFormValidation: startFormTipoPruebaValidation, setFormFieldValidation: setFormTipoPruebaFieldValidation, onResetValidation: onTipoPruebaResetValidation } = useFormValidation([{field: 'tipoPrueba', default: false}]);

    const { isFormValid: isFormPruebaValid, formValidationStatus: formPruebaValidationStatus, formFields: formPruebaFields, startFormValidation: startFormPruebaValidation, setFormFieldValidation: setFormPruebaFieldValidation, onResetValidation: onPruebaResetValidation } = useFormValidation([{field: 'prueba', default: false}]);

    const { isFormValid: isFormNoStaticValid, formValidationStatus: formNoStaticValidationStatus, formFields: formNoStaticFields, startFormValidation: startFormNoStaticValidation, setFormFieldValidation: setFormNoStaticFieldValidation, setMultipleFormFieldValidation: setMultipleFormNoStaticFieldValidation, onResetValidation: onNoStaticResetValidation } = useFormValidation([{field: 'dispositivo', default: false}, {field: 'alergenos', default: true}]);

    const { tipoQuery: { isLoading, isError, data: tipos } } = useTipo();

    const { pruebaQuery: { data: pruebas, refetch: refetchPrueba } } = usePrueba(tipoPrueba);
    
    const { dispositivoQuery: { data: dispositivos, refetch: refetchDispositivo } } = useDispositivo(tipoPrueba);

    const { sitioQuery: { data: sitios, refetch: refetchSitio } } = useSitio(tipoPrueba);

    const handleResetTipoPrueba = () => {
        setTipoPruebaReset(false);
        setTimeout(() => {
            setTipoPruebaReset(true);
        }, 1);
    }

    const handleResetPrueba = () => {
        setPruebaReset(false);
        setTimeout(() => {
            setPruebaReset(true);
        }, 1);
    }

    const handleResetPositiva = () => {
        setPositivaReset(false);
        setTimeout(() => {
            setPositivaReset(true);
        }, 1);
    }

    const handleResetDispositivo = () => {
        setDispositivoReset(false);
        setTimeout(() => {
            setDispositivoReset(true);
        }, 1);
    }

    const handleResetSitio = () => {
        setSitioReset(false);
        setTimeout(() => {
            setSitioReset(true);
        }, 1);
    }

    const handleResetAlergenos = () => {
        setAlergenosReset(false);
        setTimeout(() => {
            setAlergenosReset(true);
        }, 1);
    }

    const handleClearForm = () => {
        setResetFields(moment().unix());
        onResetForm();
        onStaticResetValidation();
        onTipoPruebaResetValidation();
        onPruebaResetValidation();
        onNoStaticResetValidation();
        handleResetTipoPrueba();
        handleResetPrueba();
        handleResetPositiva();
        handleResetDispositivo();
        handleResetSitio();
        handleResetAlergenos();
        setTimeout(() => {
            setIsClearable(false);
        }, 1000);
    }

    const handleOnFieldChange = (e) => {
        setIsClearable(true);
        onInputChange(e);
    }

    useEffect(() => {
        refetchPrueba();
        refetchDispositivo();
        refetchSitio();
        let tipoStatus = false;
        if(typeof tipos !== 'undefined' && tipos.includes(tipoPrueba)){
            tipoStatus = true;
        }else if(tipoPrueba === ''){
            tipoStatus = null;
        }
        onMultipleInputChange([{ target: { name: 'prueba', value: '' } }, { target: { name: 'dispositivo', value: '' } }, { target: { name: 'sitio', value: '' } }]);
        setFormTipoPruebaFieldValidation('tipoPrueba', tipoStatus);
        setFormPruebaFieldValidation('prueba', null);
        setMultipleFormNoStaticFieldValidation([{ name: 'dispositivo', value: null }]);
        handleResetPrueba();
        handleResetDispositivo();
        handleResetSitio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipoPrueba]);

    useEffect(() => {
        let pruebaStatus = false;
        if(typeof pruebas !== 'undefined' && pruebas.includes(prueba)){
            pruebaStatus = true;
        }else if(tipoPrueba === ''){
            pruebaStatus = null;
        }
        onMultipleInputChange([{ target: { name: 'alergenos', value: [{id: moment().unix(), alergeno: '', resultados: ''}] } }]);
        setFormPruebaFieldValidation('prueba', pruebaStatus);
        setFormNoStaticFieldValidation('alergenos', true);
        handleResetAlergenos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prueba]);

    useEffect(() => {
        setTimeout(() => {
            setIsClearable(false);
        }, 1000);
    }, []);
    
    const onSubmit = (e) => {
        e.preventDefault();

        let sitioStatus = true;
        if(typeof sitios !== 'undefined' && sitios.length > 0 && !sitios.includes(sitio)){
            sitioStatus = false;
        }

        if(formStaticValidationStatus === 'checked' && formTipoPruebaValidationStatus === 'checked' && formPruebaValidationStatus === 'checked' && formNoStaticValidationStatus === 'checked' && isFormStaticValid != null && isFormTipoPruebaValid != null && isFormPruebaValid != null && isFormNoStaticValid != null && isFormStaticValid && isFormTipoPruebaValid && isFormPruebaValid && isFormNoStaticValid && sitioStatus){
            let alergenosAux = alergenos.filter(alerg => alerg.alergeno !== '').map(alerg => {
                if(alerg.alergeno.includes('||created||')){
                    return {
                        nombre: alerg.alergeno.replace('||created||', ''),
                        resultados: alerg.resultados
                    };
                }else{
                    return {
                        _id: alerg.alergeno,
                        resultados: alerg.resultados
                    };
                }
            });
            createPruebaAlergia({ data: { fecha, tipoPrueba, prueba, positiva, dispositivo, sitio, alergenos: JSON.stringify(alergenosAux), comentarios, archivos: archivos.filter(archivo => archivo.archivo !== null).map(archivo => archivo.archivo) }, id, success:  handleClearForm});
        }else{
            startFormStaticValidation();
            startFormTipoPruebaValidation();
            startFormPruebaValidation();
            startFormNoStaticValidation();
        }
    }

    return (
        <>
            {(isLoading)
                ? <Loader />
                : <>
                    {(isError)
                        ? <Error />
                        : <>
                            <div className="row">
                                <div className="col">
                                    <div className={styles.estudioContainer}>
                                        <form onSubmit={onSubmit}>
                                            <div className="row">
                                                <div className="col">
                                                    <DateInput
                                                        formFieldStatus={ formStaticFields['fecha'].status }
                                                        label="Fecha"
                                                        name="fecha"
                                                        onChange={ handleOnFieldChange }
                                                        required={ true }
                                                        requiredIndicator={ true }
                                                        reset={resetFields}
                                                        setFormFieldValidation={ setFormStaticFieldValidation }
                                                        value={ fecha }
                                                    />
                                                </div>
                                            </div>
                                            {(tipoPruebaReset) && <div className="row">
                                                <div className="col">
                                                    <SelectInput
                                                        formFieldStatus={ formTipoPruebaFields['tipoPrueba'].status }
                                                        label="Tipo de prueba"
                                                        name="tipoPrueba"
                                                        onChange={ handleOnFieldChange }
                                                        required={ true }
                                                        requiredIndicator={ true }
                                                        setFormFieldValidation={ setFormTipoPruebaFieldValidation }
                                                        value={tipoPrueba}
                                                        values={tipos}
                                                    />
                                                </div>
                                            </div> }
                                            <div className="row">
                                                {(pruebaReset && pruebas.length > 0) && <div className="col">
                                                    <SelectInput
                                                        formFieldStatus={ formPruebaFields['prueba'].status }
                                                        label="Prueba"
                                                        name="prueba"
                                                        onChange={ handleOnFieldChange }
                                                        required={ true }
                                                        requiredIndicator={ true }
                                                        setFormFieldValidation={ setFormPruebaFieldValidation }
                                                        value={prueba}
                                                        values={pruebas}
                                                    />
                                                </div> }
                                                {(positivaReset && pruebas.length > 0) && <div className="col">
                                                    <SelectKeyInput
                                                        formFieldStatus={ formStaticFields['positiva'].status }
                                                        label="Positiva"
                                                        name="positiva"
                                                        onChange={ handleOnFieldChange }
                                                        required={ true }
                                                        requiredIndicator={ true }
                                                        setFormFieldValidation={ setFormStaticFieldValidation }
                                                        value={positiva}
                                                        values={[{ value: 'false', label: 'Negativas' }, { value: 'true', label: 'Positivas' }]}
                                                    />
                                                </div> }
                                            </div>
                                            <div className="row">
                                                {(dispositivoReset && dispositivos.length > 0) &&
                                                <div className="col">
                                                    <SelectInput
                                                        formFieldStatus={ formNoStaticFields['dispositivo'].status }
                                                        label="Dispositivo"
                                                        name="dispositivo"
                                                        onChange={ handleOnFieldChange }
                                                        required={ true }
                                                        requiredIndicator={ true }
                                                        setFormFieldValidation={ setFormNoStaticFieldValidation }
                                                        value={dispositivo}
                                                        values={dispositivos}
                                                    />
                                                </div>
                                                }
                                                {(sitioReset && sitios.length > 0) &&
                                                <div className="col">
                                                    <SelectInput
                                                        formFieldStatus="checked"
                                                        label="Sitio"
                                                        name="sitio"
                                                        onChange={ handleOnFieldChange }
                                                        required={ true }
                                                        requiredIndicator={ true }
                                                        value={sitio}
                                                        values={sitios}
                                                    />
                                                </div>
                                                }
                                            </div>
                                            {(prueba !== '' && positiva === 'true' && alergenosReset) && <div className="row">
                                                <div className="col">
                                                    <MultiFormAlergenos
                                                        formsStatus="checked"
                                                        // formsStatus={ formNoStaticFields['alergenos'].status }
                                                        name="alergenos"
                                                        onFormsChange={ handleOnFieldChange }
                                                        prueba={prueba}
                                                        reset={resetFields}
                                                        setFormValidation={ () => {} }
                                                        // setFormValidation={ setFormNoStaticFieldValidation }
                                                        value={alergenos}
                                                    />
                                                </div>
                                            </div> }
                                            <div className="row">
                                                <div className="col">
                                                    <MultiFormArchivos
                                                        formsStatus="checked"
                                                        name="archivos"
                                                        onFormsChange={ handleOnFieldChange }
                                                        reset={resetFields}
                                                        value={archivos}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <TextAreaInput
                                                        formFieldStatus={ formStaticFields['comentarios'].status }
                                                        label="Comentarios"
                                                        name="comentarios"
                                                        onChange={ handleOnFieldChange }
                                                        required={ false }
                                                        requiredIndicator={ false }
                                                        setFormFieldValidation={ setFormStaticFieldValidation }
                                                        value={ comentarios }
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-lg-6">
                                                    <LightButton
                                                        block={true}
                                                        disabled={formStaticValidationStatus === 'checking' || formTipoPruebaValidationStatus === 'checking' || formPruebaValidationStatus === 'checking' || formNoStaticValidationStatus === 'checking' || !isClearable || isPending}
                                                        icon="X"
                                                        marginRight={true}
                                                        marginTop={false}
                                                        onClick={handleClearForm}
                                                        text="Cancelar"
                                                        type="button"
                                                    />
                                                </div>
                                                <div className="col-md-12 col-lg-6 mt-md-3 mt-lg-0">
                                                    <PrimaryButton
                                                        block={true}
                                                        disabled={formStaticValidationStatus === 'checking' || formTipoPruebaValidationStatus === 'checking' || formPruebaValidationStatus === 'checking' || formNoStaticValidationStatus === 'checking' || isPending}
                                                        icon="Check"
                                                        marginTop={false}
                                                        text="Guardar"
                                                        // type="button"
                                                        // onClick={() => startFormNoStaticValidation()}
                                                        type="submit"
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}

PruebaAlergiaNuevo.propTypes = {
    id: PropTypes.string.isRequired,
}