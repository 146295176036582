import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const ImgCircledPreviewInput = ({ imgText = 'Img', label = undefined, name, value }) => {
    return (
        <>
            <label htmlFor={ name } className={styles.customInputLabel}>{ label }</label>
            <div className={styles.circledImg} style={{ backgroundImage: `Url('${ (value !== null) ? value : `https://placehold.co/400x400?text=${ imgText }` }')` }}></div>
        </>
    )
}

ImgCircledPreviewInput.propTypes = {
    imgText: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
}