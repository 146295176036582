import React from 'react';
import * as icons from 'react-bootstrap-icons';

import PropTypes from 'prop-types';

import styles from './Buttons.module.css';

export const LightButton = ({ block = false, disabled = false, icon = null, inverse = false, marginLeft = false, marginRight = false, marginTop = true, onClick, text = "", type }) => {

  let Icon = null;
  if(icon !== null){
    Icon = icons[icon];
  }

  return (
    <button type={ type } className={"btn " + styles.customButton + " " + ((inverse) ? styles.customLightButtonInverse : styles.customLightButton) + ((marginTop) ? " " + styles.customButtonMT : "") + ((marginLeft) ? " " + styles.customButtonML : "") + ((marginRight) ? " " + styles.customButtonMR : "") + ((block) ? " " + styles.customButtonBlock : "")} onClick={onClick} disabled={disabled}>{ (icon !== null) && <Icon className={styles.icon} /> }{ text }</button>
  )
}

LightButton.propTypes = {
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  inverse: PropTypes.bool,
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool,
  marginTop: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']).isRequired,
}