import { useQuery } from "@tanstack/react-query"

import { getSitio } from "../services/pruebaalergia.service";

export const useSitio = (tipoPrueba) => {

    const sitioQuery = useQuery({
        queryKey: ['pruebaAlergiaSitio'],
        queryFn: () => getSitio(tipoPrueba),
        staleTime: 1000 * 60 * 60 * 24, // 1 día
    });

    return {
        sitioQuery,
    }
}