import React from 'react';
import PropTypes from 'prop-types';

import { ImgCircledPreviewInput, PrimaryButton, TextInputPreview } from "../../../components";

export const DatosGeneralesPreview = ({ usuario, handleEditarDatosGenerales }) => {
  return (
    <>
        <div className="row">
            {(usuario.img !== null) &&
            <div className="col-md-12 col-lg-2">
                <ImgCircledPreviewInput
                    imgText="Img"
                    name='img'
                    value={usuario.img}
                />
            </div>
            }
            <div className="col-md-12 col-lg">
                <div className="row">
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Nombre(s)"
                            name="name"
                            value={ usuario.nombre }
                        />
                    </div>
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Apellido paterno"
                            name="apellidoPaterno"
                            value={ usuario.apellidoPaterno }
                        />
                    </div>
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Apellido materno"
                            name="apellidoMat"
                            value={ (usuario.apellidoMaterno !== '' && usuario.apellidoMaterno !== undefined && usuario.apellidoMaterno !== null) ? usuario.apellidoMaterno : '-' }
                        />
                    </div>
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Género"
                            name="genero"
                            value={ usuario.genero }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <TextInputPreview
                            label="Fecha de nacimiento"
                            name="fechaNacimiento"
                            value={ usuario.fechaNacimientoFormato }
                        />
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <TextInputPreview
                            label="Edad"
                            name="edad"
                            value={ usuario.edad }
                        />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <TextInputPreview
                            label="Especialidad"
                            name="especialidad"
                            value={ usuario.especialidad }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <TextInputPreview
                            label="Celular"
                            name="celular"
                            value={ usuario.celular }
                        />
                    </div>
                    <div className="col-sm-12 col-md">
                        <TextInputPreview
                            label="Correo electrónico"
                            name="email"
                            value={ usuario.email }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <PrimaryButton
                            inverse={true}
                            onClick={handleEditarDatosGenerales}
                            text="Editar Datos Generales"
                            type="button"
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

DatosGeneralesPreview.propTypes = {
    usuario: PropTypes.object.isRequired,
    handleEditarDatosGenerales: PropTypes.func.isRequired
}