import { useQuery } from "@tanstack/react-query"

import { getLaboratorio } from "../services/laboratorio.service";

export const useLaboratorio = () => {

    const laboratorioQuery = useQuery({
        queryKey: ['laboratorio'],
        queryFn: () => getLaboratorio(),
        staleTime: 1000 * 60 * 60 * 24, // 1 día
    });

    return {
        laboratorioQuery,
    }
}