import { useQuery } from "@tanstack/react-query"

import { getPaciente } from "../services/pacienteeditar.service";

export const usePaciente = (id) => {

    const pacienteQuery = useQuery({
        queryKey: ['paciente', id],
        queryFn: () => getPaciente(id),
        staleTime: 1000 * 60 * 5, // 5 min
    });

    return {
        pacienteQuery
    }
}