import React from 'react'

import { useConsulta } from './hooks/useConsulta';

import { ConsultaUpdate } from './components/ConsultaUpdate';
import { Error, Loader } from '../../../../components';

export const Consulta = () => {

    const { consultaQuery: { isLoading, isError, data: consulta } } = useConsulta();

    return (
        <>
            {(isLoading)
            ? <Loader />
            : <>
                {(isError)
                ? <Error />
                : <>
                    <ConsultaUpdate consulta={consulta} />
                </>
                }
            </>
            }
        </>
    )
}
