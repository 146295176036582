import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { createPacienteRadiologia } from "../services/radiologia.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteRadiologiaCrear = (id) => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const createRadiologiaMutation = useMutation({
        mutationFn: createPacienteRadiologia,
        onSuccess: (radiologia, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['paciente/radiologia'] });
            variables.success();
            dispatch(successNotification('Radiología creada'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        createRadiologiaMutation,
    }
}