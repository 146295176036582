import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { getAlergoide, updateAlergoide } from "../services/alergoide.service";

import { successNotification, dangerNotification } from '../../../../../redux/slices/ui';

export const useAlergoide = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const alergoideQuery = useQuery({
        queryKey: ['configuracionExpedienteClinico/alergoide'],
        queryFn: getAlergoide,
        staleTime: 1000 * 60 * 60, // 1 hora
    });

    const updateAlergoideMutation = useMutation({
        mutationFn: updateAlergoide,
        onSuccess: (alergoide, variables, context) => {
            queryClient.setQueryData(['configuracionExpedienteClinico/alergoide'], oldData => {

                return alergoide;
                
            });
            dispatch(successNotification('Datos de inmunoterapia alergoide actualizados'));
        },
        onError: (error, variables, context) => {
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        alergoideQuery,
        updateAlergoideMutation,
    }
}