import { useQuery } from "@tanstack/react-query"

import { getHistorial } from "../services/citas.service";

export const useHistorial = ({ id }) => {

    const historialQuery = useQuery({
        queryKey: ['historialCitas', id],
        queryFn: () => getHistorial(id),
        staleTime: 1000 * 60 * 5, // 5 min
        placeholderData: {
            citas: [],
        }
    });

    return {
        historialQuery,
    }
}