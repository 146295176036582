import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { usePacientePruebaAlergia } from '../hooks/usePacientePruebaAlergia';

import { BadgePreview, Error, Loader, ModalContainer, PrimaryButton, TextInputPreview } from '../../../components';
import { DetallePruebaAlergiaModal } from '../../Modals/DetallePruebaAlergia';

import { colors } from '../../../types/types';
import styles from '../PacienteConsulta.module.css';

export const PruebaAlergiaPrevia = ({ id }) => {

  const { pacientePruebaAlergiaQuery: { isLoading, isError, data: pruebasAlergia } } = usePacientePruebaAlergia(id);

  const [pruebaAlergiaActiva, setPruebaAlergiaActiva] = useState(null);

  const [statusDetallesModal, setStatusDetallesModal] = useState(false);

  const showDetallesModal = () => {
    setStatusDetallesModal(true);
  }

  const hideDetallesModal = () => {
    setStatusDetallesModal(false);
  }

  const handleDetalles = (prueba) => {
    setPruebaAlergiaActiva(prueba);
    showDetallesModal();
  }
  
  return (
    <>
    {(isLoading)
      ? <Loader />
      : <>
          {(isError)
            ? <Error />
            : <>
                {pruebasAlergia.map((prueba, index) => <div key={prueba._id}>
                    <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <TextInputPreview
                            label="Fecha"
                            name="fecha"
                            value={prueba.fechaFormato}
                        />
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className="d-flex justify-content-end">
                        <PrimaryButton
                            inverse={true}
                            marginTop={false}
                            onClick={() => handleDetalles(prueba)}
                            text='Ver Detalles'
                            type='button'
                        />
                        </div>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-6">
                            <TextInputPreview
                                label="Tipo de pruebas"
                                name="tipoPrueba"
                                value={prueba.tipoPrueba}
                            />
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <BadgePreview
                                backgroundColor={(prueba.positiva ? colors.SUCCESS : colors.DANGER)}
                                label="Resultados"
                                name="positiva"
                                value={(prueba.positiva ? 'Postivas' : 'Negativas')}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <TextInputPreview
                                label="Pruebas de alergia"
                                name="prueba"
                                value={prueba.prueba}
                            />
                        </div>
                    </div>
                    <div className="row">
                    <div className="col">
                        <TextInputPreview
                            label="Comentarios"
                            marginBottom={false}
                            name="cometarios"
                            value={(prueba.comentarios !== '') ? prueba.comentarios : '-'}
                        />
                    </div>
                    </div>
                    {(index !== pruebasAlergia.length - 1) &&
                    <div className={styles.hrLabRad}></div>
                    }
                </div>)}
                {(statusDetallesModal) &&
                    <ModalContainer
                        state={ statusDetallesModal }
                        hide={ hideDetallesModal }
                        content={ <DetallePruebaAlergiaModal hide={ hideDetallesModal } data={(pruebaAlergiaActiva !== null) ? pruebaAlergiaActiva : {}} /> }
                    />
                }
            </>
          }
      </>
    }
    </>
  )
}

PruebaAlergiaPrevia.propTypes = {
  id: PropTypes.string.isRequired,
}