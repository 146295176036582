import React from 'react';
import PropTypes from 'prop-types';

import styles from './FormFields.module.css';

export const ColorInputPreview = ({ label, name, value }) => {

  return (
    <>
        <div>
            <div className='text-center'>
              <label htmlFor={ name } className={styles.customInputLabel}>{ label }</label>
            </div>
            <input type="color" className={"form-control " + styles.customColorPicker} name={ name } value={ value } readOnly={true} disabled={true} />
        </div>
    </>
  )
}

ColorInputPreview.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
}