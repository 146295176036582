import { inmunetApi } from '../../../../../api';

export const getConsulta = async() => {

    try {

        const { data } = await inmunetApi.get('/configuracionExpedienteClinico/consulta');

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.consulta;

    } catch (error) {

        throw new Error(error);

    }

};

export const updateConsulta = async({ data: consulta }) => {

    try {

        let formData = new FormData();
        Object.keys(consulta).forEach(field => {
            formData.append(field, consulta[field]);
        });

        const { data } = await inmunetApi.put(`/configuracionExpedienteClinico/consulta`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al actualizar datos';

        return data.consulta;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error(error);
        }

    }

};