import { useState } from "react";
import PropTypes from 'prop-types';

import { ContraseñaPreview } from './ContraseñaPreview';
import { ContraseñaUpdate } from './ContraseñaUpdate';

import styles from '../Asistentes.module.css';

export const Contraseña = ({ asistente }) => {

    const [contraseñaUpdateStatus, setContraseñaUpdateStatus] = useState(false);

    const handleEditarContraseña = () => {
        setContraseñaUpdateStatus(true);
    }

    const handleCancelarEditarContraseña = () => {
        setContraseñaUpdateStatus(false);
    }

    return (
        <>
            <div className={styles.subHeader}>Contraseña</div>
            {(!contraseñaUpdateStatus)
            ? <ContraseñaPreview handleEditarContraseña={handleEditarContraseña} />
            : <ContraseñaUpdate asistente={asistente} handleCancelarEditarContraseña={handleCancelarEditarContraseña} />
            }
        </>
    )
}

Contraseña.propTypes = {
    asistente: PropTypes.object.isRequired
}