import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'

import { AntecedenteForm } from './components/AntecedenteForm';
import { PrimaryRoundedButton } from '../..'

export const MultiFormAntecedentes = ({ formsStatus='checked', name, onFormsChange, setFormValidation, value }) => {

    const [elements, setElements] = useState((typeof value !== 'undefined') ? value.map(val => ({ id: val._id, data: val.data, validation: true })) : [])

    const handleAddElement = () => {
        setElements([...elements, { id: moment().unix(), data: '', validation: false }]);
    }

    const handleRemoveElement = (id) => {
        setElements(elements.filter(element => element.id !== id));
    }

    const handleSetElementData = (data) => {
        setElements(elements.map(element => {
            if(String(element.id) === String(data.id)){
                return data;
            }else{
                return element;
            }
        }));
    }

    useEffect(() => {
        onFormsChange({ target: { name: name, value: elements.map(element => ({
            data: element.data,
        })) } });
        let errorElements = elements.filter(element => (!element.validation));
        setFormValidation(name, (errorElements.length === 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements])

    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    {elements.map(element => <AntecedenteForm
                        key={element.id}
                        element={element}
                        removeElement={handleRemoveElement}
                        onFormChange={handleSetElementData}
                        formStatus={formsStatus}
                    />)}
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className='centrar'>
                        <PrimaryRoundedButton
                            icon="Plus"
                            onClick={handleAddElement}
                            type="button"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

MultiFormAntecedentes.propTypes = {
    formsStatus: PropTypes.oneOf(['checked', 'checking']),
    name: PropTypes.string.isRequired,
    onFormsChange: PropTypes.func.isRequired,
    setFormValidation: PropTypes.func,
    value: PropTypes.array
}