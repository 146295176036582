import React from 'react'

import { useSublingual } from './hooks/useSublingual';

import { SublingualUpdate } from './components/SublingualUpdate';
import { Error, Loader } from '../../../../components';

export const Sublingual = () => {

    const { sublingualQuery: { isLoading, isError, data: sublingual } } = useSublingual();

    return (
        <>
            {(isLoading)
            ? <Loader />
            : <>
                {(isError)
                ? <Error />
                : <>
                    <SublingualUpdate sublingualAux={sublingual} />
                </>
                }
            </>
            }
        </>
    )
}
