import { inmunetApi } from '../../../api';
import { login, logout, checking } from './authSlice';
import { successNotification, dangerNotification, setConsultorio } from '../ui';
import { clearNotifications } from '../ui/uiSlice';

export const startLogin = (email, password) => {
    return async(dispatch) => {

        dispatch(checking());

        try {

            const { data } = await inmunetApi.post('/auth/login', { email, password });

            localStorage.setItem('token', data.token);

            dispatch(login(data));
            dispatch(setConsultorio(data.consultorio));
            dispatch(clearNotifications());
            dispatch(successNotification('Sesión iniciada'));

        } catch (error) {

            localStorage.removeItem('token');
            if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.msg !== 'undefined') {

                if (typeof error.response.data.verification !== 'undefined' && error.response.data.verification) {

                    dispatch(logout(error.response.data));
                    return 'verification';

                } else {

                    dispatch(logout(error.response.data));
                    dispatch(dangerNotification(error.response.data.msg));

                }

            } else {

                dispatch(logout({ msg: 'Error al procesar la petición, intenta nuevamente.' }));
                dispatch(dangerNotification('Error al procesar la petición, intenta nuevamente.'));

            }

        }

    }
}

export const startAutoLogin = () => {
    return async(dispatch) => {

        const token = localStorage.getItem('token');
        if (!token) return dispatch(logout());

        try {

            const { data } = await inmunetApi.get('/auth/auto-login');

            localStorage.setItem('token', data.token);

            dispatch(login(data));
            dispatch(setConsultorio(data.consultorio));

        } catch (error) {

            localStorage.removeItem('token');
            dispatch(logout());

        }

    }
}

export const startLogout = () => {
    return async(dispatch) => {

        localStorage.removeItem('token');
        console.log('entro');
        dispatch(logout());
        dispatch(setConsultorio({}));
        dispatch(clearNotifications());
        dispatch(successNotification('Sesión cerrada'));

    }
}

export const startSignup = (formValues) => {
    return async(dispatch) => {

        dispatch(checking());

        try {

            const { data } = await inmunetApi.post('/usuario/signup', formValues);

            dispatch(logout());
            dispatch(successNotification(data.msg));
            return true;

        } catch (error) {

            if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined' && typeof error.response.data.msg !== 'undefined') {

                dispatch(dangerNotification(error.response.data.msg));
                return false;

            } else {

                dispatch(dangerNotification('Error al procesar la petición, intenta nuevamente.'));
                return false;

            }

        }

    }
}