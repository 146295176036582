import React, { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';

import { useCitas } from './hooks/useCitas';
import { useHistorial } from './hooks/useHistorial';
import { useCitaConfirmar } from './hooks/useCitaConfirmar';
import { useCitaAtender } from './hooks/useCitaAtender';
import { useCitaCancelar } from './hooks/useCitaCancelar';
import { useCitaNoPresentar } from './hooks/useCitaNoPresentar';
import { useCitaEliminar } from './hooks/useCitaEliminar';

import { Error, IconButton, LimiteFilter, Loader, ModalContainer, PaginationButton, PrimaryButton } from '../../components';
import { CitaPacienteModal } from '../Modals/CitaPaciente';

import styles from './PacienteCitas.module.css';
import { colors } from '../../types/types';

export async function loader({ params }) {
  return params.id;
}

export const PacienteCitas = () => {

  const id = useLoaderData();

  const [limite, setLimite] = useState('8');
  const [pag, setPag] = useState(1);
  const [statusCrearCitaModal, setStatusCrearCitaModal] = useState(false);
  const [editarCitaModal, setEditarCitaModal] = useState(null);
  const [posponerCitaModal, setPosponerCitaModal] = useState(null);

  const { citasQuery: { isLoading, isFetching, isError, data: { desde, hasta, citas, pagina, total, total_paginas } } } = useCitas({ id, limite, pag });

  const { historialQuery: { isLoading: isLoadingHistorial, isFetching: isFetchingHistorial, isError: isErrorHistorial, data: { citas: citasHistorial } } } = useHistorial({ id });

  const { confirmarCitaMutation: { mutate: confirmarCita } } = useCitaConfirmar(id);

  const { atenderCitaMutation: { mutate: atenderCita } } = useCitaAtender(id);
  
  const { cancelarCitaMutation: { mutate: cancelarCita } } = useCitaCancelar(id);

  const { noPresentarCitaMutation: { mutate: noPresentarCita } } = useCitaNoPresentar(id);

  const { eliminarCitaMutation: { mutate: eliminarCita } } = useCitaEliminar(id);

  const prevPage = () => {
    if (pag === 1) {
      return;
    }

    setPag(pag - 1);
  };

  const nextPage = () => {
    if (total_paginas <= pag) {
        return;
    }

    setPag(pag + 1);
  };

  const handleCrearCita = () => {
    setStatusCrearCitaModal(true);
  }

  const hideCancelarCrearCita = () => {
    setStatusCrearCitaModal(false);
  }

  const handleEditarCita = (cita) => {
    setEditarCitaModal(cita);
  }

  const handleCancelarEditarCita = () => {
    setEditarCitaModal(null);
  }

  const handlePosponerCita = (cita) => {
    setPosponerCitaModal(cita);
  }

  const handleCancelarPosponerCita = () => {
    setPosponerCitaModal(null);
  }

  const handleConfirmarCita = (id) => {
    confirmarCita({ id: id});
  }

  const handleAtenderCita = (id) => {
    atenderCita({ id: id});
  }

  const handleCancelarCita = (id) => {
    cancelarCita({ id: id});
  }

  const handleNoPresentarCita = (id) => {
    noPresentarCita({ id: id});
  }

  const handleEliminarCita = (id) => {
    eliminarCita({ id: id});
  }

  return (
      <>
        <div className={styles.panelSection + " " + styles.panelNoHeader}>
          {(citas.length > 0 || isFetching) && 
          <div className="row">
            <div className="col">
              <div className={styles.panel}>
                {(isLoading)
                  ? <Loader />
                  : <>
                    {(isError)
                      ? <Error />
                      : <>
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className={styles.tableHeader}>Próximas citas</div>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <div className={styles.filterContainer}>
                              <LimiteFilter
                                name='Citas'
                                onChange={(lim) => setLimite(lim)}
                                value={limite}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="table-responsive">
                              <table className="table align-middle">                                  
                                <tbody>
                                {(isFetching)
                                ? <tr className='noBorder noHover noPointer'><td colSpan={6}><Loader /></td></tr>
                                : citas.map(cita => <tr className='noBorder noHover noPointer' key={ cita._id }>
                                    <td>{ cita.dia } { cita.hora } <div className="table-tooltip"><Icon.Clock color={cita.etiquetaColor} size={18} /><span className="table-tooltiptext">{ cita.etiqueta }</span></div></td>
                                    <td>{ cita.motivo.nombre }</td>
                                    <td>{ (cita.isPrimeraVez) ? 'Primera vez' : 'Subsecuente' }</td>
                                    <td>{ cita.consultorio }</td>
                                    <td>
                                      <div className='d-flex justify-content-end align-items-center'>
                                        <div className={"dropdown " + styles.tableDropdown}>
                                          <button className={"btn dropdown-toggle dropdown-toggle-no-indicator " + styles.tableDropdownButton} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <Icon.ThreeDotsVertical color='#666666' size={20} />
                                          </button>
                                          <ul className="dropdown-menu">
                                            <li><div className={"dropdown-item " + styles.tableDropdownItem} onClick={() => handleEditarCita(cita)}>Editar</div></li>
                                            <li><div className={"dropdown-item " + styles.tableDropdownItem} onClick={() => handlePosponerCita(cita)}>Posponer</div></li>
                                            {(!cita.confirmada)
                                            ? <li><div className={"dropdown-item " + styles.tableDropdownItem} onClick={() => handleConfirmarCita(cita._id)}>Confirmar</div></li>
                                            : <>
                                              <li><div className={"dropdown-item " + styles.tableDropdownItem} onClick={() => handleAtenderCita(cita._id)}>Atender</div></li>
                                              <li><div className={"dropdown-item " + styles.tableDropdownItem} onClick={() => handleNoPresentarCita(cita._id)}>No presentar</div></li>
                                            </>
                                            }
                                            <li><div className={"dropdown-item " + styles.tableDropdownItem} onClick={() => handleCancelarCita(cita._id)}>Cancelar</div></li>
                                          </ul>
                                        </div>
                                        <IconButton
                                          icon="X"
                                          iconColor={colors.DANGER}
                                          marginTop={false}
                                          onClick={() => handleEliminarCita(cita._id)}
                                          padding={false}
                                          type="button"
                                        />
                                      </div>
                                    </td>
                                  </tr>) }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className='d-flex justify-content-center align-items-center'>
                              <div>
                                <PaginationButton
                                  disabled={!(pagina > 1)}
                                  onClick={prevPage}
                                  type='prev'
                                />
                              </div>
                              <div>{ desde } - { hasta } de { total }</div>
                              <div>
                                <PaginationButton
                                  disabled={!(pagina < total_paginas)}
                                  onClick={nextPage}
                                  type='next'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  </>
                }
              </div>
            </div>
          </div>
          }
        </div>
        <div className="row mb-3">
          <div className="col">
            <div className={styles.filterContainer}>
              <PrimaryButton
                icon="Plus"
                inverse={true}
                marginTop={false}
                onClick={handleCrearCita}
                text="Nueva cita"
                type="button"
              />
            </div>
          </div>
        </div>
        {(isLoadingHistorial)
          ? <Loader />
          : <>
            {(isErrorHistorial)
              ? <Error />
              : <>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className={styles.tableHeader}>Historial de citas</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="table-responsive">
                      <table className="table align-middle">
                        <thead>
                          <tr>
                            <th scope="col">Estatus</th>
                            <th scope="col">Día</th>
                            <th scope="col">Hora</th>
                            <th scope="col">Tipo de consulta</th>
                            <th scope="col">Motivo</th>
                            <th scope="col">Consultorio</th>
                          </tr>
                        </thead>
                        <tbody className="table-group-divider">
                        {(isFetchingHistorial)
                        ? <tr className='noBorder noHover noPointer'><td colSpan={6}><Loader /></td></tr>
                        : (citasHistorial.length > 0) ? citasHistorial.map(cita => <tr key={ cita._id }>
                            <td><span className="badge" style={{backgroundColor: cita.etiquetaColor}}>{ cita.etiqueta }</span></td>
                            <td>{ cita.dia }</td>
                            <td>{ cita.hora }</td>
                            <td>{ (cita.isPrimeraVez) ? 'Primera vez' : 'Subsecuente' }</td>
                            <td>{ cita.motivo.nombre }</td>
                            <td>{ cita.consultorio }</td>
                          </tr>) : <tr><td colSpan={6} className='text-center'>Sin citas previos</td></tr> }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            }
          </>
        }
        {(statusCrearCitaModal) &&
        <ModalContainer
          state={ statusCrearCitaModal }
          hide={ hideCancelarCrearCita }
          content={ <CitaPacienteModal hide={ hideCancelarCrearCita } paciente={id} cita={editarCitaModal} /> }
        />
        }
        {(editarCitaModal != null) &&
        <ModalContainer
          state={ editarCitaModal != null }
          hide={ handleCancelarEditarCita }
          content={ <CitaPacienteModal hide={ handleCancelarEditarCita } paciente={id} cita={editarCitaModal} /> }
        />
        }
        {(posponerCitaModal != null) &&
        <ModalContainer
          state={ posponerCitaModal != null }
          hide={ handleCancelarPosponerCita }
          content={ <CitaPacienteModal hide={ handleCancelarPosponerCita } paciente={id} cita={posponerCitaModal} posponer={true} /> }
        />
        }
      </>
  )
}
