import { useEffect } from "react";
import PropTypes from 'prop-types';
import validator from 'validator';

import { useForm, useFormValidation } from "../../../../hooks";

import { EmailInput, IconButton, SelectInput, TelInput, TextInput } from "../../.."

import { colors } from "../../../../types/types";

import styles from '../MultiFormTutor.module.css';

export const TutorForm = ({ element, formStatus='checked', onFormChange, removeElement }) => {

  const { parentesco, nombre, email, celular, onInputChange } = useForm({ parentesco: element.parentesco, nombre: element.nombre, email: element.email, celular: element.celular });

  const { isFormValid, formFields, startFormValidation, setFormFieldValidation } = useFormValidation([{field: 'parentesco', default: (element.parentesco !== '')}, {field: 'nombre', default: (element.nombre !== '')}, {field: 'email', default: (element.email !== '' && validator.isEmail(element.email))}, {field: 'celular', default: (element.celular !== '' && validator.isMobilePhone(element.celular) && element.celular.length === 10)}]);

  useEffect(() => {
    if(onFormChange) onFormChange({ id: element.id, parentesco, nombre, email, celular, validation: isFormValid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentesco, nombre, email, celular, isFormValid])

  useEffect(() => {
    if(formStatus === 'checking') startFormValidation();
  }, [formStatus, startFormValidation]);

  useEffect(() => {
    if(parentesco !== '' && nombre !== '' && email !== '' && celular !== ''){
      startFormValidation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="row">
      <div className="col-md-12 col-lg-2">
        <SelectInput
          formFieldStatus={ formFields['parentesco'].status }
          label="Parentesco"
          name="parentesco"
          onChange={ onInputChange }
          required={ true }
          requiredIndicator={ true }
          setFormFieldValidation={ setFormFieldValidation }
          value={ parentesco }
          values={ ['Padre', 'Madre', 'Tutor', 'Otro'] }
        />
      </div>
      <div className="col-md-12 col-lg-3">
        <TextInput
          formFieldStatus={ formFields['nombre'].status }
          label="Nombre(s)"
          name="nombre"
          onChange={ onInputChange }
          required={ true }
          requiredIndicator={ true }
          setFormFieldValidation={ setFormFieldValidation }
          value={ nombre }
        />
      </div>
      <div className="col-md-12 col-lg-3">
        <EmailInput
          formFieldStatus={ formFields['email'].status }
          label="Correo electrónico"
          name="email"
          onChange={ onInputChange }
          required={ true }
          requiredIndicator={ true }
          setFormFieldValidation={ setFormFieldValidation }
          value={ email }
        />
      </div>
      <div className="col-md-12 col-lg-3">
        <TelInput
          formFieldStatus={ formFields['celular'].status }
          label="Teléfono móvil"
          name="celular"
          onChange={ onInputChange }
          required={ true }
          requiredIndicator={ true }
          setFormFieldValidation={ setFormFieldValidation }
          value={ celular }
        />
      </div>
      <div className={"col-md-12 col-lg-1"}>
        <div className={styles.closeBtnContainer}>
          <div>
            <label htmlFor="">&nbsp;</label>
            <IconButton
              icon="X"
              iconColor={colors.DANGER}
              marginTop={false}
              onClick={() => removeElement(element.id)}
              type="button"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

TutorForm.propTypes = {
  element: PropTypes.any.isRequired,
  formStatus: PropTypes.oneOf(['checked', 'checking']),
  onFormChange: PropTypes.func,
  removeElement: PropTypes.func.isRequired,
}