import { useDispatch } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import { getDatosGenerales, updateDatosGenerales, updateCedulas } from "../services/datosGenerales.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const useDatosGenerales = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();
  
    const datosGeneralesQuery = useQuery({
        queryKey: ['datosGenerales'],
        queryFn: getDatosGenerales,
        staleTime: 1000 * 60 * 60, // 1 hora
    });

    const updateDatosGeneralesMutation = useMutation({
        mutationFn: updateDatosGenerales,
        onSuccess: (usuario, variables, context) => {
            queryClient.setQueryData(['datosGenerales'], oldData => {
                return {
                  ...oldData,
                  ...usuario
                };
              });
            variables.callback();
            dispatch(successNotification('Usuario actualizado'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    const updateCedulasMutation = useMutation({
        mutationFn: updateCedulas,
        onSuccess: (cedulas, variables, context) => {
            queryClient.setQueryData(['datosGenerales'], oldData => {
                return {
                  ...oldData,
                  ...cedulas
                };
              });
            dispatch(successNotification('Cédula actualizada'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        datosGeneralesQuery,
        updateDatosGeneralesMutation,
        updateCedulasMutation
    }
}