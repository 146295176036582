import { Outlet } from "react-router-dom";

import styles from './AuthLayout.module.css';

export const LoginLayout = () => {

    const css = `
        .notification-layout-margin{
            top: 10px !important;
        }
    `;

    return (
        <>
            <style>{ css }</style>
            <div className={"container-fluid " + styles.authContainer}>
                <div className={styles.ilustracionContainer}>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className={styles.formContainer}>
                                <div>
                                    <div className="row">
                                        <div className="col">
                                            <div className={styles.formLogo}></div>
                                        </div>
                                    </div>
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                        <div className={"col-xl-8"}></div>
                    </div>
                </div>
            </div>
        </>
    )
}
