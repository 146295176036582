import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';

import { MotivoForm } from './components/Motivo';

export const MultiFormMotivosConsulta = ({ formsStatus='checked', name, onFormsChange, setFormValidation, value }) => {

    const [elements, setElements] = useState((typeof value !== 'undefined') ? value.map(val => ({ _id: val._id, nombre: val.nombre, duracion: val.duracion, validation: true })) : []);

    const handleSetElementData = (data) => {
        setElements(elements.map(element => {
            if(element._id === data._id){
                return data;
            }else{
                return element;
            }
        }));
    }

    useEffect(() => {
        onFormsChange({ target: { name: name, value: elements.map(element => ({
            _id: element._id,
            nombre: element.nombre,
            duracion: element.duracion,
        })) } });
        let errorElements = elements.filter(element => (!element.validation));
        setFormValidation(name, (errorElements.length === 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements])

    return (
        <>
            <div className="row">
            {(elements.length > 0) ? elements.map((element, index) => <MotivoForm
                key={element._id}
                element={element}
                label={value.find(valAux => String(valAux._id) === String(element._id)).nombre}
                onFormChange={handleSetElementData}
                formStatus={formsStatus}
            />) : <div className='text-center'>Día inactivo</div>}
            </div>
        </>
    )
}

MultiFormMotivosConsulta.propTypes = {
    formsStatus: PropTypes.oneOf(['checked', 'checking']),
    name: PropTypes.string.isRequired,
    onFormsChange: PropTypes.func.isRequired,
    setFormValidation: PropTypes.func,
    value: PropTypes.array
}