import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { createPacienteLaboratorio } from "../services/laboratorio.service";

import { successNotification, dangerNotification } from '../../../redux/slices/ui';

export const usePacienteLaboratorioCrear = () => {

    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const createLaboratorioMutation = useMutation({
        mutationFn: createPacienteLaboratorio,
        onSuccess: (laboratorio, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['paciente/laboratorio'] });
            variables.success();
            dispatch(successNotification('Laboratorio creado'));
        },
        onError: (error, variables, context) => {
            // console.log(error);
            dispatch(dangerNotification(error.message));
        },
    });

    return {
        createLaboratorioMutation,
    }
}