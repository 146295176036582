import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'

import { AlergenoForm } from './components/Alergeno';
import { PrimaryRoundedButton } from '../..'

export const MultiFormAlergenos = ({ formsStatus='checked', name, onFormsChange, prueba, setFormValidation, value }) => {

    const [elements, setElements] = useState((typeof value !== 'undefined') ? value.map(val => ({ id: val.id, alergeno: val.alergeno, resultados: val.resultados, validation: true })) : [])

    const handleAddElement = () => {
        setElements([...elements, { id: moment().unix(), alergeno: '', resultados: '', validation: false }]);
    }

    const handleRemoveElement = (id) => {
        setElements(elements.filter(element => element.id !== id));
    }

    const handleSetElementData = (data) => {
        setElements(elements.map(element => {
            if(element.id === data.id){
                return data;
            }else{
                return element;
            }
        }));
    }

    useEffect(() => {
        onFormsChange({ target: { name: name, value: elements.map(element => ({
            id: element.id,
            alergeno: element.alergeno,
            resultados: element.resultados,
        })) } });
        let errorElements = elements.filter(element => (!element.validation));
        setFormValidation(name, (errorElements.length === 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements])

    return (
        <>
            {elements.map(element => <AlergenoForm
                key={element.id}
                element={element}
                removeElement={handleRemoveElement}
                onFormChange={handleSetElementData}
                prueba={prueba}
                formStatus={formsStatus}
            />)}
            <div className='centrar'>
                <PrimaryRoundedButton
                    icon="Plus"
                    onClick={handleAddElement}
                    type="button"
                />
            </div>
        </>
    )
}

MultiFormAlergenos.propTypes = {
    formsStatus: PropTypes.oneOf(['checked', 'checking']),
    name: PropTypes.string.isRequired,
    onFormsChange: PropTypes.func.isRequired,
    prueba: PropTypes.string.isRequired,
    setFormValidation: PropTypes.func,
    value: PropTypes.array,
}