import PropTypes from 'prop-types';

import { LightButton, TextInputPreview, MultiTextInputPreview, MultiFilePreview } from '../../../components';

import styles from '../Modals.module.css';

export const DetalleLabRadModal = ({ data, hide }) => {
  return (
    <div>
        <div className="row mb-3">
            <div className="col">
                <div className={styles.modalHeader}>Detalle</div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 col-lg-6">
                <MultiTextInputPreview
                    borders={false}
                    label="Estudios"
                    name="estudios"
                    values={(typeof data.estudios !== 'undefined') ? data.estudios : []}
                />
            </div>
            <div className="col-md-12 col-lg-6">
                <TextInputPreview
                    label="Fecha"
                    name="fecha"
                    value={(typeof data.fechaFormato !== 'undefined') ? data.fechaFormato : ''}
                />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <TextInputPreview
                    label="Comentarios"
                    name="cometarios"
                    value={(typeof data.comentarios !== 'undefined' && data.comentarios !== '') ? data.comentarios : '-'}
                />
            </div>
        </div>
        <div className="row">
            <div className="col">
                <MultiFilePreview
                    label="Archivos"
                    name="archivos"
                    files={(typeof data.archivos !== 'undefined') ? data.archivos : []}
                />
            </div>
        </div>
        <div className="row">
            <div className='col-xs-12 col-sm-12 col-md-6'></div>
            <div className="col-xs-12 col-sm-12 col-md-6">
                <LightButton
                    block={true}
                    icon="X"
                    marginTop={false}
                    onClick={ hide }
                    text="Cerrar"
                    type="button"
                />
            </div>
        </div>
    </div>
  )
}

DetalleLabRadModal.propTypes = {
    data: PropTypes.object.isRequired,
    hide: PropTypes.func.isRequired
}