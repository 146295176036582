import { useQuery } from "@tanstack/react-query"

import { getPacienteRadiologia } from "../services/radiologia.service";

export const usePacienteRadiologia = (id) => {

    const pacienteRadiologiaQuery = useQuery({
        queryKey: ['paciente/radiologia', id],
        queryFn: () => getPacienteRadiologia(id),
        staleTime: 1000 * 60 * 60, // 1 hora
    });

    return {
        pacienteRadiologiaQuery,
    }
}