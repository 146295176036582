import PropTypes from 'prop-types';

import { DatosGenerales } from './DatosGenerales';
import { Contraseña } from './Contraseña';
import { Consultorios } from './Consultorios';

import styles from '../Asistentes.module.css';

export const Asistente = ({ asistente }) => {

    return (
        <div className={styles.panel}>
            <div className='row'>
                <div className="col">
                    <div className={styles.panelSection}>
                        <DatosGenerales asistente={asistente} />
                    </div>
                    <div className={styles.panelSection}>
                    <div className="row">
                        {(asistente.img !== null)
                        ? <div className="offset-md-0 col-md-12 offset-lg-2 col-lg-10">
                            <Contraseña asistente={asistente} />
                        </div>
                        : <div className="col">
                            <Contraseña asistente={asistente} />
                        </div>
                        }
                    </div>
                    </div>
                    <div className={styles.panelSection}>
                    <div className="row">
                        {(asistente.img !== null)
                        ? <div className="offset-md-0 col-md-12 offset-lg-2 col-lg-10">
                            <Consultorios asistente={asistente} />
                        </div>
                        : <div className="col">
                            <Consultorios asistente={asistente} />
                        </div>
                        }
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Asistente.propTypes = {
    asistente: PropTypes.object.isRequired
}