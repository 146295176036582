import { useQuery } from "@tanstack/react-query"

import { getConfiguracionConsultorio } from "../services/configuraionconsultorio.service";

export const useConfiguracionConsultorio = (id) => {

    const configuracionConsultorioQuery = useQuery({
        queryKey: ['configuracionConsultorio', id],
        queryFn: () => getConfiguracionConsultorio(id),
        // staleTime: 0, // nulo
        staleTime: 1000 * 60 * 5, // 5 min
    });

    return {
        configuracionConsultorioQuery,
    }
}