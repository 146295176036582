import { useQuery } from "@tanstack/react-query"

import { getPacienteAntecedentes } from "../services/pacienteantecedentes.service";

export const usePacienteAntecedentes = (id) => {

    const pacienteAntecedentesQuery = useQuery({
        queryKey: ['paciente/antecedentes', id],
        queryFn: () => getPacienteAntecedentes(id),
        staleTime: 1000 * 60 * 5, // 5 min
    });

    return {
        pacienteAntecedentesQuery,
    }
}