import { inmunetApi } from '../api';

export const getDatosGenerales = async() => {

    try {

        const { data } = await inmunetApi.get('/configuracionExpedienteClinico/datos-generales');

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.datosGenerales;

    } catch (error) {

        throw new Error(error);

    }

};

export const updateDatosGenerales = async({ data: datosGenerales }) => {

    try {

        let formData = new FormData();
        Object.keys(datosGenerales).forEach(field => {
            formData.append(field, datosGenerales[field]);
        });

        const { data } = await inmunetApi.put(`/configuracionExpedienteClinico/datos-generales`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al actualizar datos';

        return data.datosGenerales;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error(error);
        }

    }

};