import { inmunetApi } from '../../../api';

export const getConsultorios = async() => {

    try {

        const { data } = await inmunetApi.get('/consultorio?paginacion=true');

        if (!data.status) throw (data.msg) ? data.msg : 'Error al obtener los datos';

        return data.consultorios;

    } catch (error) {

        throw new Error(error);

    }

};

export const createConsultorio = async({ data: consultorio }) => {

    try {

        let formData = new FormData();
        Object.keys(consultorio).forEach(field => {
            formData.append(field, consultorio[field]);
        });

        const { data } = await inmunetApi.post(`/consultorio`, formData);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al crear consultorio';

        return data.consultorio;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error(error);
        }

    }

};

export const updateConsultorio = async({ consultorio, horario, motivos, id }) => {

    try {

        let formData = new FormData();
        Object.keys(consultorio).forEach(field => {
            formData.append(field, consultorio[field]);
        });

        const { data } = await inmunetApi.put(`/consultorio/${ id }`, formData);

        const { data: dataHorario } = await inmunetApi.put(`/consultorio/configuracion-horario/${ id }`, horario);

        const { data: dataMotivos } = await inmunetApi.put(`/consultorio/configuracion-motivos/${ id }`, motivos);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al actualizar datos';
        if (!dataHorario.status) throw (dataHorario.msg) ? dataHorario.msg : 'Error al actualizar datos';
        if (!dataMotivos.status) throw (dataMotivos.msg) ? dataMotivos.msg : 'Error al actualizar datos';

        return {
            ...data,
            configuracion: dataMotivos.configuracion
        };

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error(error);
        }

    }

};

export const updateConfiguracionConsultorioCrearInactivo = async({ data: fecha, id }) => {

    try {

        const { data } = await inmunetApi.post(`/consultorio/crear-inactivo/${ id }`, fecha);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al crear día inactivo';

        return data.configuracion;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error(error);
        }

    }

};

export const updateConfiguracionConsultorioEliminarInactivo = async({ id, inactivo }) => {

    try {

        const { data } = await inmunetApi.delete(`/consultorio/eliminar-inactivo/${ id }/${ inactivo }`);

        if (!data.status) throw (data.msg) ? data.msg : 'Error al eliminar día inactivo';

        return data.configuracion;

    } catch (error) {

        if (error.response.data.msg) {
            throw new Error(error.response.data.msg);
        } else {
            throw new Error(error);
        }

    }

};